<template>
  <div class="mirrorImageWrapper" :style="{height: tabMinHeight + 'px'}" v-loading="pageLoading">
    <div class="mirrorLeft">
      <ul>
        <li @click="startNewChat"><i class="el-icon-connection mirrorIcon"></i> 创建新对话 <i class="el-icon-plus mirrorIcon"></i></li>
      </ul>
      <div class="mirrorTitle" v-show="aiChatList.length > 0">今天 </div>
      <ul v-show="aiChatList.length > 0">
        <li v-for="(chat,index) in aiChatList" :key="index" @click="chanageTodayChat(index)" :class="{'bgcolor': highlightChatBackground('today', index) }">
          <div v-show="!chat.dialogVisible">
            <span class="datetime">
              {{ new Date(chat.createTime).toLocaleString() }}
            </span>
            <i class="el-icon-connection mirrorIcon"></i>
            <span>{{chat.title}}</span>
            <div class="opera">
              <i class="el-icon-edit-outline mirrorIcon" @click="showEditToday(index)"></i>&nbsp;
              <i class="el-icon-delete mirrorIcon" @click="deleteTodatChat(index)"></i>
            </div>
            
          </div>
          <div v-show="chat.dialogVisible">
            <el-input v-model="todayTitle" ref="todayTitle" placeholder="请输入重命名"  class="mini-el-input" @keyup.enter.native="editTodatChat(index)">
            </el-input>
            <i slot="prefix" class="el-icon-check mirrorIcon" @click="editTodatChat(index)"></i>&nbsp;
            <i slot="prefix" class="el-icon-close mirrorIcon" @click="hideEditToday(index)"></i>
          </div>

        </li>
      </ul>
      <el-divider v-show="aiChatList.length > 0"></el-divider>
      <div class="mirrorTitle">过去</div>
      <ul>
        <li v-for="(chat,index2) in oldAiChatList" :key="index2" @click="chanageOldChat(index2)" :class="{'bgcolor': highlightChatBackground('previous7days', index2) }" >
          <div v-show="!chat.dialogVisible">
            <span class="datetime">
              {{ new Date(chat.createTime).toLocaleString() }}
            </span>
            <i class="el-icon-connection mirrorIcon"></i>
            <span>{{chat.title}}</span>
            <div class="opera">
              <i class="el-icon-edit-outline mirrorIcon" @click="showEditOld(index2)"></i>&nbsp;
              <i class="el-icon-delete mirrorIcon" @click="deleteOldChat(index2)"></i>
            </div>
            
          </div>
          <div v-show="chat.dialogVisible">
            <el-input v-model="oldTitle" ref="oldTitle" placeholder="请输入重命名" class="mini-el-input" @keyup.enter.native="editOldChat(index2)">
            </el-input>
            <i slot="prefix" class="el-icon-check mirrorIcon" @click="editOldChat(index2)"></i>&nbsp;
            <i slot="prefix" class="el-icon-close mirrorIcon" @click="hideEditOld(index2)"></i>
          </div>
        </li>
      </ul>
    </div>
    <div class="mirrorRight">
      <div class="mirrorRightConversion" ref="mirrorRightConversion">
        <!--
        <div class="mirrorRightTop">
          <el-select v-model="platformIndex" placeholder="请选择" @change="changePlatform" value-key="id" >
            <el-option
              v-for="(item, index) in aiPlatformList"
              :key="item.id"
              :label="item.title"
              :value="index">
            </el-option>
          </el-select>
          &nbsp;&nbsp;
          <el-select v-model="assistantIndex" placeholder="请选择" @change="changeAssistant" value-key="id">
            <el-option
              v-for="(item, index) in assistantList"
              :key="item.id"
              :label="item.title"
              :value="index">
            </el-option>
          </el-select>
        </div>
        -->
        <div class="mirrorRightCotent">
          <div class="mirrorRmark" v-if="aiConversationList.length == 0"><i class="el-icon-question mirrorIcon"></i>今天你有什么创意呢？</div>
          <ul v-else>
            <li v-for="(item,index) in  aiConversationList" :key="index">
              <div class="ask">
                <div class="sessionTitle"> <i class="el-icon-question mirrorIcon"></i>{{item.userName}}</div>
                <div v-for="(mess, index2) in item.message" :key="index2">
                  <div class="sessionImage" v-if="mess.type =='image'" >
                    <!-- <img class="askImage" :src="content.url" v-if="content.type =='image'" @load="setConversionImageWidth"> -->
                    <el-image
                      class="askImage"
                      v-for="(content, index3) in mess.contentList"
                      :key="index3"
                      fit="fit"
                      :src="content.url"
                      :preview-src-list="[content.url]">
                    </el-image>
                  </div>
                  <div v-else-if="mess.type =='file'">
                    <div class="file-container" v-for="content in mess.contentList">
                      <div class="file-link" @click="downloadFile" :title="content.fileName" :data-href="content.url">
                        <div class="file-info">
                          <div class="file-name">{{ content.fileName  }}</div>
                          <div class="file-size">{{ formatFileSize(content.fileSize) }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <p class="askText" v-for="content in mess.contentList" v-html="content.text.replace(/\n/g, '<br>')"></p>
                  </div>
                </div>
              </div>
              <div class="anwser">
                <div class="sessionTitle"> <i class="el-icon-reading mirrorIcon"></i> {{item.aiAssistantTitle}}</div>
                <div v-for="(mess, index3) in item.response" :key="index3">
                  <div v-for="content in mess.contentList">
                    <!-- <img :src="content.url" v-if="content.type =='image'" @load="setConversionImageWidth"> -->
                    <el-image
                      v-if="content.type =='image'"
                      fit="fit"
                      :src="content.url"
                      :preview-src-list="[content.url]">
                    </el-image>
                    <div v-else-if="content.type =='file'">文件名：{{content.fileName}}</div>
                    <div class="markdown" v-else v-html="markdownToHtml(content.text)"></div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <div class="sendMessageLoading" v-show="sendMessageLoading == true">
            <i class="el-icon-loading"></i>
          </div>
        </div>
      </div>
      <div class="mirrorRightInputArea">
        <!--
        <div class="uploadFile" v-show="uploadFileList.length > 0">
          <ul>
            <li v-for="(img,index) in uploadFileList" :key="index" @mouseover="img.hover = true" @mouseleave="img.hover = false">
              <img class="logoImg" :src="img.url" height="45" alt="logo">
              <i class="el-icon-close" @click="removeUploadFile(index)" v-show="img.hover"></i>
            </li>
          </ul>
        </div>
        -->
        <div class="file-list-container" v-show="uploadFileList.length > 0">
          <div
            v-for="(file, index) in uploadFileList"
            :key="file.fileId"
            class="file-item"
            @mouseenter="showDeleteIcon(index)"
            @mouseleave="hideDeleteIcon(index)">
            <div class="delete-icon" v-show="file.showDelete" @click="removeUploadFile(index)">
              <i class="el-icon-delete"></i>
            </div>
            <!-- <img v-if="isImage(file)" :src="file.url" class="file-image" @click="previewImage(file.url)"> -->
            <el-image class="file-image" v-if="file.type =='image'" fit="fit" :title="file.fileName" :src="file.url" :preview-src-list="[file.url]"></el-image>
            <div v-else class="file-link" :title="file.fileName" :data-href="file.url">
              <div class="file-info">
                <div class="file-name">{{ file.fileName }}</div>
                <div class="file-size">{{ formatFileSize(file.fileSize) }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="mirrorRightBottom">
          <el-tooltip ref="fileTooltip" :disabled="fileTooltipDisabled" effect="light" content="文件大小限制20MB" placement="top">
            <div class="mirrorRightBottomLeft" v-show="this.assistant && this.assistant.promptType !== 'text'" @focus="handleFileDialogComplete">
              <input type="file" ref="fileInput" style="display: none" @change="handleFileChange"
                :accept="(this.assistant && this.assistant.name === 'dall-e-3') ? this.imageAccept : this.fileAccept"
              >
              <i class="el-icon-upload" @click="openFileInput"></i>
            </div>
          </el-tooltip>
          <el-input type="textarea" :autosize="{ minRows: 1, maxRows: 4 }" v-model="message" class="mirror-el-input" :placeholder="inputTips" @keydown.native="handleKeydown">
            <!-- <i slot="prefix" class="el-icon-top" @click="sendMessage"></i> -->
          </el-input>
          <el-button type="primary" icon="el-icon-position" @click="sendMessage"></el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import urlUtil from '@/utils/urlUtil.js';
import MessageBox from '@/utils/MessageBox.js';
import BaseApp from "@/utils/baseApp.js";
import axios from "axios";
import { Marked } from "marked";
import { markedHighlight } from "marked-highlight";
import 'highlight.js/styles/github.css';
import hljs from 'highlight.js';
import * as DownloadUtils from '@/utils/DownloadUtils.js';

const marked = new Marked(
  markedHighlight({
    langPrefix: 'hljs language-',
    highlight(code, lang, info) {
      const language = hljs.getLanguage(lang) ? lang : 'markdown';
      return hljs.highlight(code, { language }).value;
    }
  })
);

const MimeTypeMap = {
  '.c': 'text/x-c',
  '.cs': 'text/x-csharp',
  '.cpp': 'text/x-c++',
  '.doc': 'application/msword',
  '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.html': 'text/html',
  '.java': 'text/x-java',
  '.json': 'application/json',
  '.md': 'text/markdown',
  '.pdf': 'application/pdf',
  '.php': 'text/x-php',
  '.pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  '.py': 'text/x-python',
  '.rb': 'text/x-ruby',
  '.tex': 'text/x-tex',
  '.txt': 'text/plain',
  '.css': 'text/css',
  '.js': 'text/javascript',
  '.sh': 'application/x-sh',
  '.ts': 'application/typescript',
  '.csv': 'application/csv',
  '.jpeg': 'image/jpeg',
  '.jpg': 'image/jpeg',
  '.gif': 'image/gif',
  '.png': 'image/png',
  '.tar': 'application/x-tar',
  '.xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  '.xml': 'application/xml', // 也可以是"text/xml"
  '.zip': 'application/zip'
};

export default {
  name:'DesignCopilot',
  props:{
    uiContext:{},
    tabHeight: 0,
  },
  computed: {
    tableMaxHeight: function() {
      return this.getMaxTableHeight();
    },
    inputTips: function () {
      if (this.assistant && this.assistant.name === 'amazon-consultant') {
        return "/asin ASIN网址"
      }
      return "发送消息给AI设计Copilot...";
    },
    platform: function () {
      return this.aiPlatformList[this.platformIndex];
    },
    assistant: function () {
      return this.assistantList[this.assistantIndex];
    },
    currChat: {
      get: function () {
        if (this.currChatPosition.type === 'today') {
          return this.aiChatList[this.currChatPosition.index];
        } else {
          return this.oldAiChatList[this.currChatPosition.index];
        }
      },
      set: function (newChat) {
        if (this.currChatPosition.type === 'today') {
          this.$set(this.aiChatList, this.currChatPosition.index, newChat);
        } else {
          this.$set(this.oldAiChatList, this.currChatPosition.index, newChat);
        }
      }
    }
  },
  data: function () {
    return{
      tabMinHeight: 0,
      trigger: 0,
      pageLoading: false,
      // platform: null,
      model: null,
      platformIndex: 0,
      aiPlatformList: [],
      aiModelList: [],
      //assistant: null,
      assistantIndex: 0,
      assistantList: [],
      message:"",
      aiApplicationId: 3,
      aiChatList:[],
      oldAiChatList:[],
      //currChat: null,
      aiConversationList:[],
      currChatPosition: {
        type: 'today',
        index: 0,
      },
      // attr1Index:null,
      // attr1Index2:null,
      todayTitle:"",
      oldTitle:"",
      sendMessageLoading: false,
      uploadFileList:[],
      contentList:[],
      content:{},
      syncCount: 0,
      syncCount2: 0,
      fileUploading: false,
      fileDownloading: false,
      fileTooltipDisabled: false,
      imageAccept: '.jpeg, .jpg, .png',
      fileAccept: `
        .pdf, .doc, .docx, .pptx, .xlsx, .csv, .txt,
        .jpeg, .jpg, .png, .gif,
        .zip, .tar,
        .html, .xml, .css, .js, .ts,
        .c, .cs, .cpp, .java, .py, .php, .rb, .sh,
        .json, .md, .tex
      `,
    }
  },
  created(){
    window.microApp && window.microApp.addDataListener(this.onWindowResize, true);
  },
  mounted(){
    this.changeTrigger();
    this.syncCount = 2;
    this.getAiPlatformList();
    this.getAiChatList();
  },
  methods:{
    showDeleteIcon(index) {
      // this.uploadFileList[index].showDelete = true;
      this.$set(this.uploadFileList[index], 'showDelete', true);
    },
    hideDeleteIcon(index) {
      // this.uploadFileList[index].showDelete = false;
      this.$set(this.uploadFileList[index], 'showDelete', false);
    },
    isImage(file) {
      const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
      const extension = file.fileName.split('.').pop().toLowerCase();
      return imageExtensions.includes(extension);
    },
    downloadFile(event) {
      if (this.fileDownloading) {
        this.$message.error('文件下载中，请稍等');
        return;
      }
      this.fileDownloading = true;

      const link = event.currentTarget;
      const href = link.getAttribute('data-href');
      const title = link.getAttribute('title');

      this.$axios.get('/chatbot/xaz/downloadFile', {
        responseType: 'blob',
        params: {
          url: href,
        }
      }).then(data => {
        DownloadUtils.download(data, title);
      }, err => {
        this.$message.error('下载文件失败');
      })
      .finally(() => {
        this.fileDownloading = false;
      });
    },
    formatFileSize(size) {
      const units = ['B', 'KB', 'MB', 'GB'];
      let unitIndex = 0;
      let adjustedSize = size;

      while (adjustedSize >= 1024 && unitIndex < units.length - 1) {
        adjustedSize /= 1024;
        unitIndex++;
      }

      return `${adjustedSize.toFixed(2)} ${units[unitIndex]}`;
    },
    addClickEventToAllLinks() {
      const markdownDivs = document.querySelectorAll('.markdown');
      markdownDivs.forEach(div => {
        const links = div.querySelectorAll('a');
        links.forEach(link => {
          link.addEventListener('click', this.handleLinkClick);
        });
      });
    },
    handleLinkClick(event) {
      const link = event.currentTarget;
      const href = link.getAttribute('href');
      const title = link.getAttribute('title');

      if (href) {
        const fileExtension = href.split('.').pop().toLowerCase();
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg'];

        event.preventDefault();
        if (imageExtensions.includes(fileExtension)) {
          window.open(href, '_blank'); // 在新标签页打开
        } else {
          this.$axios.get('/chatbot/xaz/downloadFile', {
            responseType: 'blob',
            params: {
              url: href,
            }
          }).then(data => {
            DownloadUtils.download(data, title);
          }, err => {
            this.$message.error('下载文件失败');
          });
        }
      }
    },
    markdownToHtml(text) {
      return marked.parse(text, {
        breaks: true,
        gfm: true,
      });
    },
    highlightChatBackground (type, index) {
      return this.currChatPosition.type === type && this.currChatPosition.index === index;
    },
    isToday(datetime) {
      const today = new Date();
      const date = new Date(datetime);
 
      return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
      );
    },
    onWindowResize(data){
      this.tabMinHeight = data.tabMinHeight;
      this.changeTrigger();
    },
    getMaxTableHeight: function() {
      var _this = this;
      if(_this.tabMinHeight <= 0) return 0;

      var childenList = _this.$children;
      var elementHeight = 0;
      var size = childenList.length;
      var className = "";
      for(var i = 0; i < size; i++) {
        className = childenList[i].$el.className;
        if("el-row" == className || "el-pagination" == className) {
          elementHeight = elementHeight + childenList[i].$el.clientHeight;
        }
      }
      return _this.tabMinHeight - elementHeight + _this.trigger;
    },
    changeTrigger: function() {
      this.trigger = this.trigger < 0? 0: -1;
    },
    //获取7天内会话
    getAiChatList(){
      this.$axios.post("/chatbot/xaz/getAiChatList", {}, {
        params: {
          aiApplicationId: this.aiApplicationId,
        }
      }).then((result)=>{
        if(result.flag){
          // this.aiChatList = result.aiChatList;
          this.aiChatList = [];
          this.oldAiChatList = [];
          // const todayChatList = [];
          if (result.aiChatList.length > 0) {
            //分成7天内和最新4条
            for (var i = 0; i < result.aiChatList.length;i++) {
              // this.aiChatList[i].dialogVisible = false;
              if (!this.isToday(result.aiChatList[i].createTime)) {
                this.oldAiChatList.push(result.aiChatList[i]);
              } else {
                this.aiChatList.push(result.aiChatList[i]);
              }
            }
            // this.aiChatList = todayChatList;
            if (this.aiChatList.length > 0) {
              // this.currChat = this.aiChatList[0];
              // this.attr1Index = 0;
              // this.attr1Index2 = null;
              this.currChatPosition = { type: 'today', index: 0 };
            } else {
              // this.currChat = this.oldAiChatList[0];
              // this.attr1Index = null;
              // this.attr1Index2 = 0;
              this.currChatPosition = { type: 'previous7days', index: 0 };
            }
            this.syncCount2 = 2;
            this.getAiAssistantList();
            this.getAiConversationList();
            //this.oldAiChatList = this.aiChatList.slice(0,4);
            this.setPlatform();
          }
          //console.log(this.aiChatList);
          else if (result.aiChatList.length === 0) {
            this.startNewChat();
          }
        }else{
          alert(result.msg);
        }
      })
    },
    //创建新会话
    startNewChat(){
      this.$axios.post("/chatbot/xaz/startNewChat", {}, {
        params: {
          aiApplicationId: this.aiApplicationId,
        }
      }).then((result)=>{
        if(result.flag){
          // this.currChat = result.chat;
          // this.currChat.dialogVisible = false;
          // this.aiChatList.unshift(this.currChat);
          // this.attr1Index = 0;
          // this.attr1Index2 = null;
          this.currChatPosition = { type: 'today', index: 0 };
          this.aiChatList.unshift(result.chat);
          this.aiConversationList = [];
          this.getAiAssistantList();
          this.setPlatform();
        }else{
          alert(result.msg);
        }
      })
    },
    setConversionImageWidth (event) {
      if (event.target.naturalWidth <= event.target.width) {
        event.target.style.width = event.target.naturalWidth + 'px';
      }
      this.$nextTick(() => {
        this.$refs.mirrorRightConversion.scrollTop = this.$refs.mirrorRightConversion.scrollHeight;
      });
    },
    //获取会话详情
    getAiConversationList(){
      if(this.currChat != null){
        this.$axios.post("/chatbot/xaz/getAiConversationList?aiChatId=" + this.currChat.id ).then((result)=>{
          if(result.flag){
            this.aiConversationList = result.aiConversationList;
            //message 和 response 转成对象
            for(var i = 0; i < this.aiConversationList.length;i++){
              if(this.aiConversationList[i].message !=null){
                var messageObj = JSON.parse(this.aiConversationList[i].message);
                this.aiConversationList[i].message = messageObj;
              }
              if(this.aiConversationList[i].response !=null){
                var responseObj = JSON.parse(this.aiConversationList[i].response);
                // for (const message of responseObj) {
                //   message.value = message.value.replaceAll("\n", "<br>");
                // }
                this.aiConversationList[i].response = responseObj;

              }
            }

            this.scrollConversionToBottom();
          }else{
            alert(result.msg);
          }
        })
      }
    },
    scrollConversionToBottom() {
      if (this.syncCount2 > 0) {
        this.syncCount2 -= 1;
        if (this.syncCount2 === 0) {
          this.$nextTick(() => {
            this.$refs.mirrorRightConversion.scrollTop = this.$refs.mirrorRightConversion.scrollHeight;
            this.addClickEventToAllLinks();
          });
        }
      }
    },
    getAiPlatformList(){
      this.$axios.post("/chatbot/xaz/getAiPlatformList").then((result)=>{
        if(result.flag){
          this.aiPlatformList = result.aiPlatformList;
          this.setPlatform();
        }else{
          alert(result.msg);
        }
      })
    },
    setPlatform() {
      if (this.syncCount > 0) {
        this.syncCount -= 1;
        if (this.syncCount === 0) {
          this.platformIndex = this.aiPlatformList.findIndex(x => x.id === this.currChat.aiPlatformId);
        }
      }
    },
    getAiAssistantList () {
      this.$axios.post("/chatbot/xaz/getAiAssistantList?aiApplicationId=" + this.aiApplicationId).then((result) => {
        if (result.flag) {
          this.assistantList = result.aiAssistantList;
          this.assistantIndex = this.assistantList.findIndex(x => x.id === this.currChat.aiAssistantId);
          this.scrollConversionToBottom();
        } else {
          this.$message.error(result.msg);
        }
      });
    },
    handleKeydown(event) {
      if (event.key === "Enter" && !event.shiftKey) {
        event.preventDefault(); // 阻止默认换行行为
        this.sendMessage();
      }
    },
    sendMessage(){
      if (this.sendMessageLoading) return;

      if (this.uploadFileList.length === 0 && !this.message) {
        return;
      }

      this.contentList = new Array();
      for(var i = 0;i < this.uploadFileList.length;i++){
        // var contentfile = {
        //   type:"image",
        //   url: this.uploadFileList[i].url,
        // }
        // this.contentList.push(contentfile);
        this.contentList.push(this.uploadFileList[i]);
      }
      if (this.message) {
        var content = {
          type:"text",
          text: this.message,
        }
        this.contentList.push(content);
      }
      this.sendMessageLoading = true;
      this.syncCount2 = 1;
      this.scrollConversionToBottom();
      var dataMap = {
        aiChatId: this.currChat.id,
        contentList: this.contentList
      };
      this.$axios.post('/chatbot/xaz/sendMessage', dataMap).then((result) => {
        if (result.flag) {
          //this.aiConversationList.push(result.conversation);
          this.syncCount2 = 1;
          this.getAiConversationList();
          this.sendMessageLoading = false;
          this.message = null;
          this.uploadFileList = [];
        } else {
          alert(result.msg);
          this.message = null;
          this.uploadFileList = [];
          this.sendMessageLoading = false;
        }
        this.fileUploading = false;
        this.$refs.fileInput.value = '';
      });
    },
    chanageTodayChat(index){
      this.currChatPosition = { type: 'today', index: index };
      // this.currChat = this.aiChatList[index];
      this.platformIndex = this.aiPlatformList.findIndex(x => x.id === this.currChat.aiPlatformId);
      this.syncCount2 = 2;
      this.getAiAssistantList();
      this.getAiConversationList();
      // this.attr1Index = index;
      // this.attr1Index2 = null;

    },
    chanageOldChat(index2){
      this.currChatPosition = { type: 'previous7days', index: index2 };
      // this.currChat = this.oldAiChatList[index2];
      this.platformIndex = this.aiPlatformList.findIndex(x => x.id === this.currChat.aiPlatformId);
      this.syncCount2 = 2;
      this.getAiAssistantList();
      this.getAiConversationList();
      // this.attr1Index2 = index2;
      // this.attr1Index = null;
    },
    changePlatform(){
      this.$axios.get('/chatbot/xaz/changeAiPlatform', { 
        params:{
          aiChatId: this.currChat.id,
          aiPlatformId: this.platform.id
        }
      }).then((result) => {
        if (result.flag) {
          this.currChat = result.chat;
          // this.currChat.dialogVisible = false;
          // this.platformIndex = this.aiPlatformList.findIndex(x => x.id === this.currChat.aiPlatformId);
          this.getAiAssistantList();
        } else {
          alert(result.msg);
        }
      });
    },
    changeAssistant () {
      this.$axios.get('/chatbot/xaz/changeAiAssistant?', {
        params: {
          aiChatId: this.currChat.id,
          aiAssistantId: this.assistant.id
        }
      })
      .then((result) => {
        if (result.flag) {
          this.currChat = result.chat;
          // this.currChat.dialogVisible = false;
          // //this.assistant = this.assistantList.findIndex(x => x.id === this.currChat.aiAssistantId);
          // if (this.attr1Index !== null) {
          //   this.$set(this.aiChatList, this.attr1Index, this.currChat);
          // }
          // if (this.attr1Index2 !== null) {
          //   this.$set(this.oldAiChatList, this.attr1Index2, this.currChat);
          // }
        } else {
          this.$message.error(result.msg);
        }
      });
    },
    showEditToday(index){
      this.aiChatList[index].dialogVisible = true;
      this.todayTitle = this.aiChatList[index].title;
      this.$nextTick(() => {
        this.$refs.todayTitle[index].focus();
      });
    },
    editTodatChat(index){
      if (!this.todayTitle) {
        return;
      }

      this.$axios.get('/chatbot/xaz/renameChat',{ 
        params:{
          aiChatId: this.aiChatList[index].id,
          title: this.todayTitle
        }
        }).then((result) => {
        if(result.flag == true) {
          this.aiChatList[index] = result.chat;
          this.$message.success('success');
          //this.aiChatList[index].dialogVisible = false;
          this.hideEditToday(index);
        }else {
          alert(result.msg);
        }
      });
    },
    hideEditToday(index){
      this.aiChatList[index].dialogVisible = false;
      this.todayTitle = "";
    },
    showEditOld(index2){
      this.oldAiChatList[index2].dialogVisible = true;
      this.oldTitle = this.oldAiChatList[index2].title;
      this.$nextTick(() => {
        this.$refs.oldTitle[index2].focus();
      });
    },
    editOldChat(index2){
      if (!this.oldTitle) {
        return;
      }

      this.$axios.get('/chatbot/xaz/renameChat', {
        params:{
          aiChatId: this.oldAiChatList[index2].id,
          title: this.oldTitle
        }
      }).then((result) => {
        if(result.flag == true) {
          this.oldAiChatList[index2] = result.chat;
          this.$message.success('success');
          //this.oldAiChatList[index2].dialogVisible = false;
          this.hideEditOld(index2);
        }else {
          alert(result.msg);
        }
      });
    },
    hideEditOld(index2){
      this.oldAiChatList[index2].dialogVisible = false;
      this.oldTitle = "";
    },
    deleteTodatChat(index){
      this.$confirm('This will delete Chat. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        var dataMap = {
          aiChatId: this.aiChatList[index].id
        };
        this.$axios.post('/chatbot/xaz/deleteChat?aiChatId='+this.aiChatList[index].id).then((result) => {
          if (result.flag) {
            this.syncCount = 2;
            this.getAiPlatformList();
            this.getAiChatList();
            this.$message.success('success');
          } else {
            alert(result.msg);
          }
        });
      }, () => {
      });
    },
    deleteOldChat(index2){
      this.$confirm('This will delete Chat. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        var dataMap = {
          aiChatId: this.oldAiChatList[index2].id
        };
        this.$axios.post('/chatbot/xaz/deleteChat?aiChatId='+this.oldAiChatList[index2].id).then((result) => {
          if (result.flag) {
            this.syncCount = 2;
            this.getAiPlatformList();
            this.getAiChatList();
            // this.attr1Index = null;
            // this.attr1Index2 = null;
            this.$message.success('success');
          } else {
            alert(result.msg);
          }
        });
      }, () => {
      });
    },
    removeUploadFile (index) {
      this.$confirm(`确认要删除文件${this.uploadFileList[index].fileName}?`, '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const fileId = this.uploadFileList[index].fileId;
        this.$axios.get('/chatbot/xaz/deleteUploadedFile?fileId=' + fileId).then(result => {
          if (result.flag) {
            this.uploadFileList.splice(index, 1);
          } else {
            this.$message.error('删除上传文件失败');
          }
        });
      }, () => {
      });
    },
    openFileInput() {
      if (this.fileUploading) {
        this.$message.error('文件上传中，请稍等');
        return;
      }
      //this.fileUploading = true;
      this.fileTooltipDisabled = true;
      this.$refs.fileInput.click();
    },
    handleFileDialogComplete () {
      if (this.fileTooltipDisabled) {
        setTimeout(() => {
          this.fileTooltipDisabled = false;
        }, 100);
      }
    },
    handleFileChange(event) {
      //this.fileTooltipDisabled = false;
      const uploadfile = event.target.files[0];
      var fileidx = uploadfile.name.lastIndexOf(".");
      var filetype = uploadfile.name.substr(fileidx);
      if (!MimeTypeMap[filetype]) {
        this.$message.error(
          "不支持的文件类型，请重新选择要上传的文件！"
        );
        this.fileUploading = false;
        this.$refs.fileInput.value = '';
        return;
      }
      const maxSize = 1024 * 1024 * 20; // 设置最大文件大小为20MB
      if (uploadfile.size > maxSize) {
        this.$message.error('文件大小不能超过20MB');
        this.fileUploading = false;
        this.$refs.fileInput.value = '';
        return;
      }

      if (this.assistant.name === 'dall-e-3' && this.uploadFileList.length == 1) {
        this.$message.error("Vision只能上传1张图片！");
        this.fileUploading = false;
        this.$refs.fileInput.value = '';
        return;
      }

      // if(this.uploadFileList.length > 4){
      //   this.$message.error(
      //     "图片不得超过5张！"
      //   );
      //   this.$refs.fileInput.value = '';
      //   return;
      // }

      if (this.assistant.name === 'dall-e-3') {
        var self = this;
        var reader = new FileReader();

        reader.onload = function () {
          var img = new Image;

          img.onload = function () {
            const maxSize = Math.max(img.width, img.height);
            if (maxSize > 2048) {
              self.$message.error("图片长边不能超过2048像素！");
              self.fileUploading = false;
              self.$refs.fileInput.value = '';
              return;
            }

            self.doUploadFile(uploadfile);
          };

          img.src = reader.result;
        };

        reader.readAsDataURL(uploadfile);
        return;
      }

      this.fileUploading = true;
      this.doUploadFile(uploadfile);
    },
    doUploadFile (uploadfile) {
      var formData = new FormData();
      formData.append("file", uploadfile);
      this.$axios.post('/chatbot/xaz/uploadFile', formData).then((result) => {
        if (result.flag) {
          // this.uploadFileList.push({ url: result.bizFileList[0].ahttpurl, hover: false });
          this.uploadFileList.push(result.uploadedFile);
          this.syncCount2 = 1;
          this.scrollConversionToBottom();
        } else {
          this.$message.error(result.msg);
        }
        this.fileUploading = false;
        this.$refs.fileInput.value = '';
      });
    },
  },
}
</script>
<style>
  .fixedButton {
    width: 100px;
  }
  .bgcolor {
      background-color: #e3e3e3;
  }   
</style>
