<template>
  <div class="main-container">
    <div class="main-top">
      <span>Product Sales Analytics</span>
      <el-button icon="el-icon-s-grid" class="customize-btn" @click="selectModeldialogVisible = !selectModeldialogVisible">Customize Modules</el-button>
    </div>
    <!-- Sales Overview-->
    <div class="overview-wrapper" v-if="isshowOverview">
      <div class="overview-title">Sales Overview</div>
      <div class="overview-main">
        <div class="overview-main-item">
          <div class="item-main-data">{{totalSalesVolume}}</div>
          <div class="item-main-cate">Total Sales</div>
        </div>
        <div class="overview-main-item">
          <div class="item-main-data">${{totalSalesPriceUsd | formatInteger}}</div>
          <div class="item-main-cate">Total Revenue</div>
        </div>
        <div class="overview-main-item">
          <div class="item-main-data">${{averagePriceUsd | formatDecimal}}</div>
          <div class="item-main-cate">Average Price</div>
        </div>
      </div>
    </div>
    <div class="chart-Wrapper" v-if="isshowChart">
      <div class="chart-top">
        <div class="chart-title">Daily Sales Volume</div>
        <div class="chart-selectdate">
          
          <el-date-picker
            v-model="timeFrame"
            type="daterange"
            range-separator="——"
            start-placeholder="Start"
            end-placeholder="End"
            @change="handleDateChange">
          </el-date-picker>
          &nbsp;
          <label for="timeRange">Date: </label>
          <el-select 
            v-model="selectDateValue" 
            @change="chanageDateValue" 
            size="small"
            class="filter-select "
          >
            <el-option
              v-for="item in selectDateList"
              :key="item.value"
              :label="item.title"
              :value="item.value">
            </el-option>
          </el-select>
          
          
        </div>
      </div>
      <div style="width: 95%; min-height: 400px; margin: 0px auto;padding: 20px 0px;">
        <div ref="chartContainer" class="chart-container" id="myChart"></div>
      </div>
      <div class="chart-tag">
        <el-tag
          v-for="(item, index) in analyticsList"
          :key="index"
          closable
          @close="removeAnalyticsProduct(index)" 
        >
          <span>{{ item.platformSku }}</span>
        </el-tag>
      </div>
    </div>

    <div class="compPageStyle" v-if="isshowTable">
      <!-- 搜索栏 -->
      <div class="search-bar">
        <div class="search-item">
          <el-select v-model="query.thirdAccount" placeholder="Shop" value-key="aid" filterable clearable @change="((val)=>{clear(val, 'query.thirdAccount')})">
            <el-option
              v-for="item in accountList"
              :key="item.aid"
              :label="item.aname + '(' + item.thirdPlatform.aname + ')'"
              :value="item">
            </el-option>
          </el-select>
          <el-select v-model="query.thirdPlatform" placeholder="Platform" value-key="aid" filterable clearable @change="((val)=>{clear(val, 'query.thirdPlatform')})">
            <el-option
              v-for="item in platformList"
              :key="item.aid"
              :label="item.aname"
              :value="item">
            </el-option>
          </el-select>
          <el-select v-model="query.site" placeholder="Site" value-key="aid" filterable clearable @change="((val)=>{clear(val, 'query.site')})">
            <el-option
              v-for="item in siteList"
              :key="item.aid"
              :label="item.aisocode"
              :value="item">
            </el-option>
          </el-select>
          <el-select v-model="query.astatus" placeholder="Status" value-key="aid" filterable clearable @change="((val)=>{clear(val, 'query.astatus')})">
            <el-option
              v-for="item in statusList"
              :key="item.aid"
              :label="item.aname"
              :value="item">
            </el-option>
          </el-select>

          <div style="margin-left: 30px;" class="search-wrapper">
            <el-select v-model="query.filterBy" @change="searchData" placeholder="Search type" class="search-el-select" filterable>
              <el-option
                v-for="(item, index) in filterByList"
                :key="index"
                :label="item.title"
                :value="item.value">
              </el-option>
            </el-select>
            <el-input placeholder="Search txt" v-model="query.search"  @input="searchData">
              <i slot="prefix" class="el-icon-search" @click="searchData()"></i>
            </el-input>
          </div>
          
        </div>

        <!-- <div class="search-chanage">
          <el-button  icon="el-icon-download" size="small" plain @click="exportExcel">Export</el-button>
        </div> -->
        <!-- <el-button @click="aiPublish()" :loading="btnLoading" class="opera-btn">AI Publish</el-button> -->
      </div>
      <!-- <el-row>
        <div class="buttonWrapper">
          <el-button @click="aiPublish()" :loading="btnLoading" class="opera-btn">AI Publish</el-button>
          <el-input v-model="query.aplatformSkuId" placeholder="Asin" @input="getDataList" class="opera-inp"></el-input>
        </div>
      </el-row> -->

      <div class="tableWrapper">
        
        <el-table 
          :data="dataList"  
          style="width: 100%" 
          @selection-change="handleSelectionChange"
          @sort-change="handleSortChange"
          v-loading="isLoading"
        >
          <el-table-column prop="merchandise.anumber" label="Sku" width="130"></el-table-column>
          <el-table-column prop="platformSku" label="Platform Sku Name" width="180">
            <template slot-scope="slotscope">
              <a v-if="slotscope.row.aproductUrl" :href="slotscope.row.aproductUrl" target="_blank" rel="noopener noreferrer"><span>{{slotscope.row.platformSku}}</span></a>
              <span v-else>{{slotscope.row.platformSku}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="thirdPlatform.aname" label="Platform" width="120"></el-table-column>
          <el-table-column prop="site.aisocode" label="Site" width="80"></el-table-column>
          <el-table-column prop="merchandise.aname" label="Sku Name." width="180"></el-table-column>
          <el-table-column prop="price" label="Price." width="180" sortable></el-table-column>
          <el-table-column prop="acurrency.aisocode" label="Currency." width="120"></el-table-column>
          <el-table-column prop="asevenDaySales" label="Sales Qty 7." width="130" sortable></el-table-column>
          <el-table-column prop="athirtyDaySales" label="Sales Qty 30." width="140" sortable></el-table-column>
          <el-table-column prop="aninetyDaySales" label="Sales Qty 90." width="140" sortable></el-table-column>
          <el-table-column
            label="Actions"
            width="100">
            <template slot-scope="scope">
              <el-button 
                @click="handleAnalyticsClick(scope.row,scope.$index)" 
                :icon="getIcon(scope.row, scope.$index)" 
                size="small">
              </el-button>
            </template>
          </el-table-column>
          <!-- Customize the loading/no data message -->
          <template v-slot:empty>
            <div v-if="isLoading" style="text-align: center; padding: 20px;">
              <el-spinner class="loading" />
              <span>Loading data...</span>
            </div>
            <div v-else style="text-align: center; padding: 20px;">
              <span>No data available</span>
            </div>
          </template>
        </el-table>

      </div>

      <div class="pageWrapper">
        <span class="page-select-total">{{multipleSelection.length}} of {{pageDomain.pageSize}} row(s) selected.</span>
        <el-pagination
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :current-page.sync="pageDomain.pageIndex"
          :page-size="pageDomain.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          layout="total,prev, pager, next, jumper,sizes"
          :total="pageDomain.recordCount"
        ></el-pagination>
      </div>
    </div>

    <!-- <div class="analy-btn-wrapper" v-if="isshowAnalysis">
      <el-button class="analy-btn" icon="el-icon-warning" @click="analysisGoodsSales()" :loading="analysisGoodsSalesLoading">Generate AI Analysis</el-button>
    </div> -->
    <div class="analy-content-wrapper" v-show="isshowAnalysisContent && isshowAnalysis">
      <p class="analy-content-title">Al-Powered Insights</p>
      <div v-for="(item,index) in chatbotMessageList" :key="index" class="analy-content-item">
        <div v-if="item.contentList">
          <p v-for="(content,index2) in item.contentList" :key="index2" >
            <i class="el-icon-document"></i><span>{{content.text}}</span>
          </p>
        </div>
      </div>
      <!-- sales_overview -->
      <div>
        <p class="analy-content-title">{{analysisResult}}</p>
      </div>
      <!-- sales_features -->
      <div>

      </div>
      <!-- key_recommendations -->
      <div>

      </div>
    </div>

    <!-- 选择模块弹框 -->
    <el-dialog :visible.sync="selectModeldialogVisible"  top="15vh" :append-to-body="true" class="select-model-wrapper" >
        <div>
          <h2>Customize Dashboard Modules</h2>
        </div>
        <div class="select-model-div" >
          <div class="select-model-icon"><i class="el-icon-menu" ></i></div>
          <div style="flex: 1">
            <h2>Sales Overview</h2>
            <p>Key metrics showing total sales, revenue and average price</p>
           </div>
           <div style="width: 100px;margin-top: 10px; ">
             <el-checkbox v-model="isshowOverview"></el-checkbox>
             <!-- <el-switch
                v-model="isshowOverview"
                active-color="#13ce66"
                inactive-color="#ff4949">
              </el-switch> -->
           </div>
        </div>
        <div class="select-model-div" >
          <div class="select-model-icon"><i class="el-icon-finished" ></i></div>
          <div style="flex: 1">
            <h2>Sales Growth Chart</h2>
            <p>Interactive chart showing daily sales volume over time</p>
           </div>
           <div style="width: 100px;margin-top: 10px; ">
             <el-checkbox v-model="isshowChart"></el-checkbox>
             <!-- <el-switch
                v-model="isshowChart"
                active-color="#13ce66"
                inactive-color="#ff4949">
              </el-switch> -->
           </div>
        </div>
        <div class="select-model-div" >
          <div class="select-model-icon"><i class="el-icon-coin" ></i></div>
          <div style="flex: 1">
            <h2>ASIN Comparison Table</h2>
            <p>Detailed table for comparing product performance</p>
           </div>
           <div style="width: 100px;margin-top: 10px; ">
             <el-checkbox v-model="isshowTable" disabled></el-checkbox>
             <!-- <el-switch
                v-model="isshowTable"
                active-color="#13ce66"
                inactive-color="#ff4949">
              </el-switch> -->
           </div>
        </div>
        <div class="select-model-div" >
          <div class="select-model-icon"><i class="el-icon-warning-outline"></i></div>
          <div style="flex: 1">
            <h2>Al Analysis Button</h2>
            <p>Generate Al-powered insights about your sales data</p>
           </div>
           <div style="width: 100px;margin-top: 10px; ">
             <el-checkbox v-model="isshowAnalysis"></el-checkbox>
             <!-- <el-switch
                v-model="isshowAnalysis"
                active-color="#13ce66"
                inactive-color="#ff4949">
              </el-switch> -->
           </div>
        </div>
        <div class="select-model-btn-wrapper">
          <el-button class="default-btn" @click="resetApplyChanges">Reset to Default</el-button>
          <el-button class="apply-btn" @click="applyChanges">Apply Changes</el-button>
        </div>
    </el-dialog>

  </div>
  
</template>
<script>

import Vue from 'vue';
import axios from 'axios';
import * as DownloadUtils from '@/utils/DownloadUtils.js';
import * as echarts from 'echarts';
import * as dayjs from 'dayjs'

export default {
  name:'AiProductAnalytics',
  props: ['aid'],
  components: {
    
  },
  data() {
    return{
      query:{
        filterBy: 'platformSkuId',
        search: null,
        sortBy: null,
        order: null,
      },
      multipleSelection:[],
      dataList:[],
      searchLoading: false,
      btnLoading: false,
      isLoading: true,
      pageDomain: {},
      accountList: [],
      siteList: [],
      statusList: [],
      platformList: [],
      pageSelectCount: 0,
      filterByList:[
        {
          title:'Sku.',
          value:"skuNo",
        },
        {
          title: 'Sku Name.',
          value: 'skuName',
        },
        {
          title:'Platform Sku ID.',
          value:"platformSkuId",
        },
        {
          title:'Platform Sku Name.',
          value:"platformSkuName",
        }
      ],
      chartInstance: null,
      chartOptions: null,
      dateArray:[],
      seriesArray:[],
      isshowAnalysisContent: false,
      selectModeldialogVisible: false,
      isshowOverview: true,
      isshowChart: true,
      isshowTable: true,
      isshowAnalysis: true,
      analyticsList: [],
      icons: {}, // Object to keep track of icons for each row
      averagePriceUsd: 0,
      dailySalesListMap: null,
      totalSalesPriceUsd: 0,
      totalSalesVolume: 0,
      selectDateValue: 30,
      selectDateList:[
        {
          title: "7 Days",
          value: 7
        },
        {
          title: "30 Days" ,
          value: 30
        },
        {
          title: "90 Days",
          value: 90
        },
      ],
      fromDate: null,
      toDate: null,
      timeFrame: [],
      intervalValue: null,
      analysisGoodsSalesLoading: false,
      chatbotMessageList:[],
      analysisResult:null,
    };

  },
  filters: {
    formatInteger(value) {
      return value ? Math.round(value).toLocaleString() : '0';
    },
    formatDecimal(value) {
      return value ? Number(value).toFixed(2) : '0.00';
    }
  },
  created(){
    this.queryInit();
  },
  mounted() {
    this.initChart();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize); // 移除事件监听
    this.chartInstance.dispose(); // 销毁实例
  },
  methods:{
    onResize() {
      this.chartInstance.resize(); // 监听窗口大小变化时调整图表大小
    },
    handleSizeChange: function(val) {
      this.pageDomain.pageSize = val;
      this.getDataList();
    },
    handleSelectionChange: function(val) {
      this.multipleSelection = val;
    },
    handleCurrentChange: function() {
      this.getDataList();
    },
    queryInit: function() {
      var  _this= this;
      _this.isLoading = true; // Start loading
      var dataMap = {
        query:_this.query
      };
      _this.$axios.post('/platformGoods/xaz/queryInit', dataMap
      ).then((result) => {
        if(result.flag == true) {
          _this.query = result.query;
          _this.dataList = result.dataList;
          _this.pageDomain = result.pageInfo;
          _this.accountList = result.accountList;
          _this.siteList = result.siteList;
          _this.statusList = result.statusList;
          _this.platformList = result.platformList;

          if(!_this.aid){
            if(_this.dataList){
            //默认第一行为数据分析参数，调用接口拿汇总数据
              _this.analyticsList.push(_this.dataList[0]);
              _this.getAnalyticsResult();
            }
          }else{
            // 查找 dataList 中是否存在该 aid
            const index = _this.dataList.findIndex(dataItem => dataItem.aid === _this.aid);
            _this.analyticsList.push(_this.dataList[index]);
            _this.getAnalyticsResult();
          }
          
          
          _this.isLoading = false;
        }
      });
    },

    clear:function(event, propertyStr) {
      var _this = this;
      var propertyArr = propertyStr.split(".");
      // if(propertyStr === 'aquery.platform'){
      //   if(event == '' || event.aname == null){
      //     _this.thirdAccountList = _this.thirdAccountListAll;
      //   }else{
      //     _this.thirdAccountList = _this.accountMap[event.aname];
      //   }
      // }
      if(event == '') {
        var property = _this;
        for(var i = 0; i < propertyArr.length; i++) {
          if(i == propertyArr.length - 1) {
            property[propertyArr[i]] = {};
            break;
          }
          property = property[propertyArr[i]];
        }
      }
      this.searchData();
    },

    getDataList: function() {
      var _this = this;
      
      var dataMap = {
        "query": _this.query,
        "pageInfo": _this.pageDomain
      };

      _this.searchLoading = true;

      _this.$axios.post('/platformGoods/xaz/selectList', dataMap
          ).then((result) => {
            if(result.flag == true) {
              _this.dataList = result.dataList;
              _this.pageDomain = result.pageInfo;
            }else {
              _this.$message({ showClose: true, message: result.msg, type: 'error'  });
            }

            _this.searchLoading = false;
          });

      setTimeout(function() {
        _this.searchLoading = false;
      }, 10 * 1000);
    },
    searchData: function() {
      this.pageDomain.pageIndex = 1;
      this.pageDomain.recordCount = 0;
      this.getDataList();
    },
    exportExcel() {
      this.$confirm('Do you want to export all records?', 'Export Confirmation', {
        confirmButtonText: 'Export',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        var dataMap = {
          "query": this.query,
        };
        this.$axios.post('/platformGoods/xaz/exportExcel', dataMap, {responseType:'blob'}).then(async result => {
          if (result.response.headers.flag === 'false') {
            const msg = await result.text();
            this.$message.error(msg);
            return;
          }

          DownloadUtils.download(result, 'dashboard.xlsx');
        })
        .finally(() =>{
        });
      }, () => {
      });
    },
    aiPublish: function() {
      var _this = this;

      if(_this.multipleSelection.length != 1) {
        this.$message.error("Please select one sku");
        return;
      }

      _this.btnLoading = true;
      var dataMap = {
        "selectedProductID": _this.multipleSelection[0].aid
      };
      
      _this.btnDisabled
      _this.$axios.post('/aiProduct/xaz/aiPublish', dataMap
      ).then((result) => {
        debugger;
        if(result.flag == true) {
          _this.getDataList();
        }else {
          _this.$message({ showClose: true, message: result.msg, type: 'error'  });
        }

        _this.btnLoading = false;
      });

      setTimeout(function() {
        _this.btnLoading = false;
      }, 20 * 1000);
    },
    handleSortChange(sort) {
      const { prop, order } = sort; 
      console.log('排序字段:', prop);
      console.log('排序顺序:', order);
      this.query.sortBy = prop;
      this.query.order = order;
      
      //重新渲染数据
      this.searchData();
    },
    dateFormat: function(row, column, cellValue) {
      if (cellValue != null) {
        var date = new Date(cellValue);
        return (
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate()
        );
      } else {
        return "";
      }
    },
    
    handleAnalyticsClick(row, index) {
      if (this.isAnalyticsExists(row)) {
        this.handleMinusAnalyticsClick(row);
      } else {
        if(this.analyticsList.length < 10){
          this.handleAddAnalyticsClick(row);
        }else{
          this.$message.error("If there are more than 10 pieces of data, please delete them");
        }
        
      }
      
      this.getAnalyticsResult();
    },
    handleAddAnalyticsClick(row){
      this.analyticsList.push(row);
    },
    handleMinusAnalyticsClick(row){
      // 获取当前 aid
      const aidToCheck = row.aid;
      // 查找 dataList 中是否存在该 aid
      const index = this.analyticsList.findIndex(dataItem => dataItem.aid === aidToCheck);
      this.analyticsList.splice(index, 1);
    },  
    isAnalyticsExists(row) {
      this.updateIcon(); //更新加减按钮
      // Assume each row and analyticsList have a unique id property
      return this.analyticsList.some(analytics => analytics.aid === row.aid);
    },
    updateIcon() {
      // Update the icon state for the clicked row
      //this.$set(this.icons, index, isPlus);
      // 遍历 analyticsList
      this.icons = {};
      this.analyticsList.forEach(analyticsItem => {
        // 获取当前 aid
        const aidToCheck = analyticsItem.aid;

        // 查找 dataList 中是否存在该 aid
        const index = this.dataList.findIndex(dataItem => dataItem.aid === aidToCheck);

        // 如果存在，使用 $set 更新 icons 数组
        if (index !== -1) {

          this.$set(this.icons, this.dataList[index].aid, true);
        }
      });
    },
    getIcon(row, index) {
      // Check if we have a specific icon set for this row
      if (this.icons[row.aid] !== undefined) {
        return this.icons[row.aid] ? 'el-icon-minus' : 'el-icon-plus';
      }
      // For all other rows that haven't been clicked, return plus
      return 'el-icon-plus'; // Default icon
    },
    chanageDateValue(val){
      this.intervalValue = val;
      this.timeFrame = []; //自定义时间范围置空
      this.getAnalyticsResult();//重新拿数据
    },
    handleDateChange() {
      if(this.timeFrame.length > 1){
        var startDate = null;
        var endDate = null;
        // 如果选择了日期范围，返回开始日期
        startDate = this.timeFrame[0] ? new Date(this.timeFrame[0]) : null;
        // 如果选择了日期范围，返回结束日期
        endDate = this.timeFrame[1] ? new Date(this.timeFrame[1]) : null;

        // 计算选择的时间范围
        if (startDate && endDate) {
          // 计算时间差，单位为毫秒
          const timeDiff = endDate - startDate;
          const oneYearInMillis = 365 * 24 * 60 * 60 * 1000; // 一年的毫秒数

          if (timeDiff > oneYearInMillis) {
            // 如果时间范围超过一年，提示用户
            this.$message.error('The selected date range cannot exceed one year!');
            // 重置时间范围
            this.timeFrame = [];
            
          }else{
            this.selectDateValue = null; // 选择天数为null
            this.intervalValue = 365;
            this.fromDate = startDate;
            this.toDate = endDate;

            //重新 渲染X轴
            this.generateDateArray(new Date(this.timeFrame[0]),new Date(this.timeFrame[1]));

            this.getAnalyticsResult(); //重新拿数据
          }
        }

      }else{
        this.$message.error("Please select the complete time range"); 
      }
      
      
    },
    async getAnalyticsResult(){
      if(this.selectDateValue){
        this.fromDate =  this.getDateMinusDays(this.selectDateValue);
        this.toDate =  this.getDateMinusDays(1);
        // 提取所有数组并合并
        var startDate = this.getDateMinusDays(this.selectDateValue);;
        var endDate = this.getDateMinusDays(1);
        this.generateDateArray(startDate,endDate);
      }else if(this.selectDateValue && this.fromDate && this.toDate){
        this.$message.error("Date is null, Please Slect Date");
      }
      
      try {
        // 发送请求
        var dataMp = {
            fromDate: this.fromDate,
            toDate: this.toDate,
            goodsList: this.analyticsList
        }
        const response = await this.$axios.post('/thirdorderDailySales/xaz/selectByRequest', dataMp);
        if (!response.flag) {
          this.$message.error(response.msg);
          return;
        }else{
          this.averagePriceUsd = response.averagePriceUsd;
          this.dailySalesListMap = response.dailySalesListMap;
          this.totalSalesPriceUsd = response.totalSalesPriceUsd;
          this.totalSalesVolume = response.totalSalesVolume;

          this.generateSeriesArray();

          this.updateIcon(); //更新加减按钮
          this.updateChart(); // 更新图表


        }
        
      } catch (error) {
        // 处理错误
        this.$message.error(error); 
      } finally {
        this.columnManagerOpen = false;
        this.savePageCustomizationLoading = false;
      }
    },
    initChart() {
      this.chartInstance = echarts.init(this.$refs.chartContainer);
      
      window.addEventListener('resize', () => {
        setTimeout(() => {
          this.chartInstance.resize()
        })
      })
    },
    updateChart() {
     //设置 图标数据
      this.chartInstance.setOption(this.getChartOption(),true); // 使用新的数据更新图表
    },
    // 获取图表配置项
    getChartOption () {
      var interval = 0;
      if(this.intervalValue == 7){
        interval = 0;
      }else if(this.intervalValue == 30){
        interval = 1;
      }else if(this.intervalValue == 90){
        interval = 7;
      }else{
        interval = 30;
      }
      return {
        title: {
            show: false,
            text: '', 
            left: 'left', 
            top: '5%', 
            textStyle: { 
                color: '#333', 
                fontSize: 18 
            }
        },
        legend: {
          show: true,
          data: this.seriesArray.map(i => i.name),
          orient: 'horizontal', // 水平布局
          top: '2%', // 设置 legend 的位置
          left: 'center',
        },
        tooltip: {
          trigger: 'axis',
          formatter: (params) => {
            // 解析 tooltip 的内容
            return this.createTooltipContent(params);
          }
        },
        
        yAxis: {
            name: 'Income',
            type: 'value',
            min: -5, 
            splitLine: {
              show: true,
              lineStyle: {
                color: 'rgba(0,219,255,0.4000)',
                type: 'dashed',
              },
            },
            axisLabel: {
              formatter: (value) => {
                return value; // 格式化Y轴刻度
              },
            },
            
        },
        xAxis: {
            type: 'category',
            data: this.dateArray,
            axisLabel: {
                show: true,
                interval: interval, 
                margin: 30, 
                textStyle: {
                    color: '#B0CEFC', 
                },
            },
        },
        series: this.seriesArray.map(item => ({
          name: item.name,
          type: item.type,
          areaStyle: item.areaStyle,
          emphasis: item.emphasis,
          data: item.data,
        }))
      }
    },
    generateSeriesArray(){
      this.seriesArray = [];
      // 遍历 Map 对象并生成新数组
      this.seriesArray = Object.entries(this.dailySalesListMap).map(([key, value]) => {
          return {
              id: key,
              name: value[0].productName, // 取第一个产品的名称
              type: 'line',
              areaStyle: {},
              emphasis: {
                focus: 'series'
              },
              data: value.map(i => i.salesQuantity),// salesQuantity组成新数组
              dataSales: value.map(i => i.totalSales),
              dataAvgPrice: value.map(i => i.avgPrice),
              dataSalesDate: value.map(i => i.salesDate)
          };
      });
      
    },
    createTooltipContent(params) {
      let tooltip = '';

      // 遍历所有参数并构建 tooltip 内容
      params.forEach(param => {
        const { seriesName, dataIndex } = param; // 获取系列名称和数据索引
        const currentData = this.seriesArray.find(item => item.name === seriesName);

        if (currentData) {
          const value = currentData.data[dataIndex];
          const sales = currentData.dataSales[dataIndex];
          const avgPrice = currentData.dataAvgPrice[dataIndex];
          const salesDate = dayjs(currentData.dataSalesDate[dataIndex]).format("YYYY-MM-DD");

          // 按需格式化 tooltip 内容
          tooltip += `
            <div>
              <strong>${seriesName}</strong><br/>
              Date: ${salesDate}<br/>
              Total Sales: ${value}<br/>
              Total Revenue: ${sales}<br/>
              Average Price: ${avgPrice}
            </div>
          `;
        }
      });

      return tooltip;
    },
    generateDateArray(startDate,endDate) {
      // 清空之前的数组
      this.dateArray = [];

      // 将输入的日期转换为 Date 对象

      // 检查日期有效性
      if (isNaN(startDate) || isNaN(endDate) || startDate > endDate) {
        return;
      }

      // 生成日期数组
      for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
        // 格式化日期为 "YYYY-MM-DD"
        const formattedDate = this.formatDate(date);
        this.dateArray.push(formattedDate);
      }
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },

    getDateMinusDays(days) {
      const today = new Date(); // 获取当前日期
      today.setDate(today.getDate() - days); // 当前日期减去指定天数
      return today; // 返回 YYYY-MM-DD 格式的日期
    },
    async analysisGoodsSales(){
        this.analysisGoodsSalesLoading = true;
        var idattr = this.analyticsList.map(i => i.aid);
        try {
        // 发送请求
        // var dataMp = {
        //     fromDate: this.formatDate(this.fromDate),
        //     toDate: this.formatDate(this.toDate),
        //     goodsIdList: idattr
        // }
        var dataMp ={
          "goodsIdList": [1],
          "fromDate": "2019-07-09",
          "toDate": "2019-07-11"
        }
        const response = await this.$axios.post('/productPublish/xaz/analysisGoodsSales', dataMp);
        console.log(response)
        if (!response.flag) {
          this.$message.error(response.msg);
          return;
        }else{
          //this.chatbotMessageList = response.chatbotMessageList;
          this.analysisResult = JSON.parse(response.analysisResult);
          console.log(this.analysisResult);
          this.isshowAnalysisContent = true;

        }
        
      } catch (error) {
        // 处理错误
        this.$message.error(error); 
      } finally {
        this.analysisGoodsSalesLoading = false;
      }
    },
    removeAnalyticsProduct(index){
      this.analyticsList.splice(index, 1); // 根据传入的index删除相应的类别
      this.getAnalyticsResult();//重新拿数据
    },
    resetApplyChanges(){
      this.isshowOverview = true;
      this.isshowChart = true;
      this.isshowTable = true;
      this.isshowAnalysis = true;
      this.selectModeldialogVisible = false;
    },
    applyChanges(){
      this.selectModeldialogVisible = false;
    },
    
  }
}
</script>

<style lang="less" scoped>
:root {
    --el-color-primary: #000; /* 主颜色 */
    --el-color-active: #000; /* 全局 active 颜色 */
    --el-button-hover-bg: #000; /* 修改按钮 hover 状态颜色 */
    --el-button-active-bg: #000; /* 修改按钮 active 状态颜色 */
}

.main-container{
  width: 98%;
  padding: 20px;
  overflow-y: auto;
  background-color: #f6f7fb;
  font-family: 'Sans-serif';
}

.main-top{
  padding: 30px 0px;
  span{
    font-size: 28px;
    font-weight: bold;
    color: #000;
  }
  .customize-btn,.customize-btn:hover{
    height: 40px;
    line-height: 40px;
    padding: 0px 20px;
    background-color: #f0f4ff;
    border:1px solid #4750bb;
    color: #4750bb;
    float: right;
    font-size: 16px;
    font-weight: 500;
  }
}

.overview-wrapper{
  width: 100%;
  margin: 0px auto;
  border: 1px solid #ebeef5;
  box-shadow: 0 0 10px 10px rgba(197, 195, 195, 0.2); /* 设置四周阴影 */
  border-radius: 8px;
  background-color: #ffffff;
  
}
.overview-wrapper .overview-title{
  padding: 20px 15px;
  font-size: 16px;
  font-weight: bold;
}
.overview-wrapper .overview-main{
    display: flex;
    justify-content: space-between;
    padding: 0px 20px 20px 20px;
}
.overview-wrapper .overview-main .overview-main-item{
  flex: 1; /* 每个盒子均分剩余空间 */
  margin-right: 20px;
  height: 140px;
  padding: 20px; /* 内边距 */
  background-color: #f8f9fb;
  border-radius: 2px;
}
.overview-wrapper .overview-main .overview-main-item:last-child{
    margin-right: 0; /* 最后一个盒子不需要右边距 */
}
.item-main-data{
  line-height: 50px;
  font-size: 32px;
  font-weight: bold;
}
.item-main-cate{
  line-height: 40px;
  font-size: 16px;
  color: #737476;
}
.item-main-pecen-green{
  line-height: 40px;
  font-size: 16px;
  font-weight: bold;
  color: green;
}
.item-main-pecen-red{
  line-height: 40px;
  font-size: 16px;
  font-weight: bold;
  color: red;
}

.chart-Wrapper{
  width: 100%;
  margin: 30px auto;
  border: 1px solid #ebeef5;
  box-shadow: 0 0 10px 10px rgba(197, 195, 195, 0.2); /* 设置四周阴影 */
  border-radius: 8px;
  background-color: #ffffff;
}
.chart-top{
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.chart-top .chart-title{
  font-size: 16px;
  font-weight: bold;
}
.chart-selectdate{
  /deep/ .el-input__inner{
      height: 35px;
      line-height: 35px;
  }
 .el-input__inner:focus {
    border-color: #000;
  }
  .el-range-editor.is-active{
    border-color: #000;
  } 
}
.chart-container {
  width: 100% !important;
  height: 400px !important; /* 设置图表容器高度 */
}
#myChart {
    width: 100%; /* 确保宽度是100% */
    height: 400px; /* 根据需求设置高度 */
}

.analy-btn-wrapper{
  width: 100%;
  margin: 30px auto;
  border: 1px solid #ebeef5;
  box-shadow: 0 0 10px 10px rgba(197, 195, 195, 0.2); /* 设置四周阴影 */
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  background-color: #ffffff;
  .analy-btn,.analy-btn:hover{
    height: 40px;
    line-height: 40px;
    padding: 0px 20px;
    background-color: #4254b8;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
  }
}

.analy-content-wrapper{
  width: 100%;
  margin: 30px auto;
  border: 1px solid #ebeef5;
  box-shadow: 0 0 10px 10px rgba(197, 195, 195, 0.2); /* 设置四周阴影 */
  border-radius: 8px;
  padding: 20px;
  background-color: #ffffff;
}
.analy-content-title{
  font-size: 18px;
  font-weight: bold;
}
.analy-content-item{
  padding: 20px;
  p{
    line-height: 30px;
    font-size: 14px;
    font-weight: 500;
    i{
      color: #4254b8;
      font-size: 20px;
      vertical-align: middle;
    }
  }
}

.compPageStyle{
  width: 100%;
  margin: 30px auto;
  box-shadow: 0 0 10px 10px rgba(197, 195, 195, 0.2); /* 设置四周阴影 */
  border-radius: 8px;
  padding: 20px;
  background-color: #ffffff;
}
.search-bar {
    display: flex;               /* 使用 flexbox 布局 */
    justify-content: space-between; /* 在主轴上平均分配两端的元素 */
    align-items: center;        /* 垂直居中对齐 */
    padding: 20px;              /* 添加内边距 */
    background-color: #fff;  /* 背景颜色可以根据需求调整 */
    margin: 20px 0px;
    border-radius: 8px;
}

.search-item {
    display: flex;              /* 使用 flexbox 布局 */
    gap: 0px;                  /* 选择项之间的间隔 */
}

/deep/ .search-item .el-select {
    margin: 0 10px 0px 0px;            /* 左右边距 */
    //background-color: #000;    /* 背景色为黑色 */
    //border-color: #000;
    //color: white;               /* 文本颜色为白色 */
    height: 35px;
    line-height: 35px;
    border-radius: 0;           /* 去除圆角 */
}

/deep/ .search-item .el-select .el-input__inner {
    height: 35px;
    line-height: 35px;
    //color: white !important;               /* 字体颜色为白色 */
    //background-color: #000 !important;     /* 背景色为黑色 */
    //border-radius: 0;                       /* 去除圆角 */

}
/deep/ .el-select .el-input.is-focus .el-input__inner {
    border-color: var(--el-color-primary); /* 设置焦点时的边框颜色 */
}
/deep/ .el-select-dropdown__item.hover, .el-select-dropdown__item.selected {
    background-color: var(--el-color-active); /* 选中项的背景颜色 */
    color: #000; /* 选中项的文字颜色 */
}

/deep/ .el-select-dropdown__item.selected {
    background-color: var(--el-color-primary); /* 修改选中项的背景颜色为主色 */
    color: #fff; /* 选中项文字颜色为白色 */
}
/deep/ .search-item .el-input {
    height: 35px;
    line-height: 35px;
    //border-radius: 0;           /* 去除圆角 */
}
/deep/ .el-input .el-input__inner{
    height: 35px;
    line-height: 35px;
    //border-radius: 0;           /* 去除圆角 */
}
/deep/ .el-input .el-input__inner:focus {
  border-color: #000;
}

.search-wrapper{
  margin-left: 30px;
}

.search-chanage {
    display: flex;              /* 使用 flexbox 布局 */
    align-items: center;        /* 垂直居中 */
    margin-left: auto;          /* 把这个 div 移到右边 */
}

.search-chanage i {
    margin-left: 15px;          /* 左右图标之间的空间 */
    cursor: pointer;            /* 鼠标悬停时显示手形光标 */
}


.opera-btn{
  padding: 0px 15px;
  height: 35px; /* 按钮高度 */
  text-align: center;
  font-weight: bold;
}
/deep/ .opera-inp{
  height: 35px !important;
  line-height: 35px !important;
}

.pageWrapper{
  position: relative;
  .page-select-total{
    width: 220px;
    display: block;
    position: absolute;
    left: 0px;
    top: 10px;
    z-index: 5;
    font-size: 13px;
    font-weight: 500;
  }
  /deep/ .el-select {
    width: 110px !important;
    height: 35px;
    line-height: 35px;
  }
  /deep/ .el-select .el-input{
    width: 110px !important;
    height: 35px;
    line-height: 35px;
  }
  /deep/ .el-select .el-input__inner {
      height: 35px;
      line-height: 35px;                 

  }
  /deep/  .el-input {
      width: 80px !important;
      height: 35px;
      line-height: 35px;
  }
  /deep/ .el-input .el-input__inner{
      height: 35px;
      line-height: 35px;
  }

/* pagination */
/deep/ .el-pagination .el-pager  {
  color: #000;
}

/deep/ .el-pagination {
  text-align: center;
  .el-pager {
    li:hover {
      color: #000;
    }
  }
  .btn-next:hover,
  .btn-prev:hover {
    color: #000;
    i:hover {
      color: #000;
    }
  }
  .el-pagination__sizes {
    .el-select {
      .el-input {
        .el-input__inner:hover {
          border-color: #000;
        }
      }
      .is-focus {
        .el-input__inner {
          border-color: #000;
        }
      }
      .el-input__inner:focus {
        border-color: #000;
      }
    }
  }
  .el-pagination__jump {
    .el-pagination__editor {
      .el-input__inner:focus {
        border-color: #000;
      }
    }
  }
  .btn-prev,.btn-next{
    background-color: #000;
    color: #fff;
  }
  .btn-prev:hover,.btn-next:hover{
    background-color: #000;
    color: #fff;
  }
}


}


.product-list-image{
  width: 40px;
  height: 40px;
  object-fit: contain;
  cursor: pointer;
  display: inline-block;
}

/deep/ .el-table th .el-checkbox {
  margin: 0; /* 清除任何原始上/下边距 */
  line-height: normal; /* 确保行高正确 */
  margin-left: -5px;
  padding-top: 5px;
}

.search-el-select{
  width: 160px;
  margin: 0 0px 0px 0px !important;
}
.skeleton {
  background: #f2f2f2;
  padding: 10px;
}
.skeleton-row {
  height: 30px;
  background: #e6e6e6;
  margin-bottom: 10px;
}


.select-model-wrapper{
  width: 40% !important;
  margin: 0px auto;
  /deep/.el-dialog {
      width: 100% !important;
  }
}
.select-model-div{
  display: flex;
  background-color: #f1f4fc;
  border: 1px solid #e3e4e5; 
  padding: 10px; 
  margin: 20px 50px;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
}

.select-model-div .select-model-icon{
  width: 35px;
  height: 35px;
  background-color: #f0f0f0; 
  text-align: center; 
  margin-top: 5px; 
  margin-right: 10px;

  i{
    font-size: 25px; 
    font-weight: bold;
    color: blue;
    margin-top:6px;
  }
}
.select-model-btn-wrapper{
  display: flex;
  justify-content: center;
  padding: 30px;

  .default-btn{
    width: 180px;
    height: 40px;
    line-height: 40px;
    background-color: #ffffff;
    border: 1px solid #e3e4e5;
    color: #111111;
  }
  .apply-btn{
    width: 180px;
    height: 40px;
    line-height: 40px;
    background-color: #4754b3;
    border: 1px solid #4754b3;
    color: #ffffff;
  }
}

.chart-tag{
  padding: 20px;
  .el-tag{
    margin-right: 20px;
  }
}
</style>
