<template>
  <div class="container">
    <!-- 图片上传区域 -->
    <div class="upload-section">
      <el-upload
        v-if="!uploadedImage"
        ref="imageUpload"
        class="upload-area"
        drag
        action=""
        :limit="1"
        :on-change="handleFileChange"
        :before-upload="beforeUpload"
        :auto-upload="false"
        :show-file-list="false"
        accept="image/jpeg,image/png"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">拖拽文件到这里，或点击上传</div>
      </el-upload>
      <el-image
        v-else
        :src="uploadedImage"
        class="preview-image"
        fit="contain"
        :preview-src-list="[uploadedImage]"
      ></el-image>

      <!-- Clear 和 Submit 按钮 -->
      <div class="action-buttons">
        <el-button type="primary" class="button" @click="clearImages">清除</el-button>
        <el-button type="primary" class="button" @click="submitImage" v-loading="loading">提交</el-button>
      </div>
    </div>

    <!-- 九宫格部分2 -->
    <div v-if="sceneData2.length" class="grid-section">
      <div class="grid">
        <!-- 循环生成9个 el-card -->
        <el-card
          v-for="(item, index) in sceneData2.slice(0, 9)"
          :key="index"
          class="grid-item"
        >
          <el-image
            :src="item.imageUrl && item.imageUrl.jpegUrl"
            class="grid-image"
            fit="contain"
            :preview-src-list="sceneData2.map(scene => scene.imageUrl && scene.imageUrl.jpegUrl)"
            :initial-index="index"
          ></el-image>

          <div class="grid-footer">
            <!-- 使用 div 替代 el-button，添加 hover 效果 -->
            <div
              class="view-details"
              @click="openDetailsDialog2(item)"
            >
              查看详情
            </div>
          </div>
        </el-card>
      </div>
    </div>

    <!-- 弹窗显示详细信息 -->
    <el-dialog
      :visible.sync="dialogVisible2"
      title="查看详情"
      width="500px"
      @close="closeDialog2"
    >
      <div class="dialog-content">
        <div class="dialog-row">
          <label class="label">类目:</label>
          <div class="value">{{ selectedItem2.categoryPath }}</div>
        </div>
        <div class="dialog-row">
          <label class="label">适用场景:</label>
          <div class="value">{{ selectedItem2.applicableScenario }}</div>
        </div>
        <div class="dialog-row">
          <label class="label">场景描述:</label>
          <div class="value">{{ selectedItem2.scenarioDescription }}</div>
        </div>
        <!-- 添加图片显示 -->
        <div class="dialog-row">
          <label class="label">图片:</label>
          <el-image
            :src="selectedItem2.imageUrl && selectedItem2.imageUrl.jpegUrl"
            class="dialog-image"
            fit="contain"
            :preview-src-list="[selectedItem2.imageUrl && selectedItem2.imageUrl.jpegUrl]"
          ></el-image>
        </div>
      </div>
    </el-dialog>

    <div v-if="sceneData.length" class="divider"></div>

    <!-- 九宫格部分 -->
    <div v-if="sceneData.length" class="grid-section">
      <div class="grid">
        <!-- 循环生成9个 el-card -->
        <el-card
          v-for="(item, index) in sceneData.slice(0, 9)"
          :key="index"
          class="grid-item"
        >
          <el-image
            :src="item.imageUrl && item.imageUrl.jpegUrl"
            class="grid-image"
            fit="contain"
            :preview-src-list="sceneData.map(scene => scene.imageUrl && scene.imageUrl.jpegUrl)"
            :initial-index="index"
          ></el-image>

          <div class="grid-footer">
            <!-- 使用 div 替代 el-button，添加 hover 效果 -->
            <div
              class="view-details"
              @click="openDetailsDialog(item)"
            >
              查看详情
            </div>
          </div>
        </el-card>
      </div>
    </div>

    <!-- 弹窗显示详细信息 -->
    <el-dialog
      :visible.sync="dialogVisible"
      title="查看详情"
      width="500px"
      @close="closeDialog"
    >
      <div class="dialog-content">
        <div class="dialog-row">
          <label class="label">类目:</label>
          <div class="value">{{ selectedItem.categoryPath }}</div>
        </div>
        <div class="dialog-row">
          <label class="label">适用场景:</label>
          <div class="value">{{ selectedItem.applicableScenario }}</div>
        </div>
        <div class="dialog-row">
          <label class="label">场景描述:</label>
          <div class="value">{{ selectedItem.scenarioDescription }}</div>
        </div>
        <!-- 添加图片显示 -->
        <div class="dialog-row">
          <label class="label">图片:</label>
          <el-image
            :src="selectedItem.imageUrl && selectedItem.imageUrl.jpegUrl"
            class="dialog-image"
            fit="contain"
            :preview-src-list="[selectedItem.imageUrl && selectedItem.imageUrl.jpegUrl]"
          ></el-image>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      uploadedFile: null,
      uploadedImage: null, // 用户上传的图片
      uploadedImageUrl: null,
      sceneData: [], // 九宫格数据
      dialogVisible: false, // 弹窗显示控制
      selectedItem: {}, // 存储选中的九宫格项的详细信息
      taskId: null,
      taskInterval: null,
      sceneData2: [], // 九宫格数据
      dialogVisible2: false, // 弹窗显示控制
      selectedItem2: {}, // 存储选中的九宫格项的详细信息
      taskId2: null,
      taskInterval2: null,
    };
  },
  destroyed() {
    this.cancelTaskInterval();
    this.cancelTaskInterval2();
  },
  methods: {
    beforeUpload(file) {
      const isImage = file.type === "image/png" || file.type === "image/jpeg";
      const isLt3M = file.size / 1024 / 1024 < 3; // 限制文件大小小于3MB
      if (!isImage) {
        this.$message.error("仅支持 JPG/PNG 格式的图片");
        return false;
      }
      if (!isLt3M) {
        this.$message.error("图片大小不能超过 3MB");
        return false;
      }
      return true;
    },
    handleFileChange(file, fileList) {
      if (fileList.length > 0) {
        if (!this.beforeUpload(file.raw)) {
          this.$refs.imageUpload.clearFiles();
          return;
        }
        this.uploadedFile = file;
        this.uploadedImage = URL.createObjectURL(file.raw);
      }
    },
    async submitImage() {
      if (!this.uploadedImage) {
        this.$message.error("请先上传图片");
        return;
      }

      if (!this.uploadedImageUrl) {
        try {
          this.loading = true;
          const uploadResponse = await this.uploadImage();

          if (!uploadResponse.flag) {
            this.$message.error(uploadResponse.msg);
            return;
          }

          this.uploadedImageUrl = uploadResponse.url;
          this.$message.success("图片上传成功");
        } catch (error) {
          console.log('uploadImage error:', error);
          this.$message.error("图片上传失败，请重试");
          return;
        } finally {
          this.loading = false;
        }
      }

      this.submitImage2();

      try {
        this.loading = true;
        const submitTaskResponse = await this.submitTask(this.uploadedImageUrl, 'midjourney');

        if (!submitTaskResponse.flag) {
          this.$message.error(submitTaskResponse.msg);
          return;
        }

        this.taskId = submitTaskResponse.taskId;
        this.$message.success("任务提交成功");
      } catch (error) {
        console.log('submitTask error:', error);
        this.$message.error("任务提交失败，请重试");
        return;
      } finally {
        this.loading = false;
      }

      this.loading = true;
      this.taskInterval = setInterval(async () => {
        this.loading = true;
        const queryTaskResponse = await this.queryTask(this.taskId);

        if (!queryTaskResponse.flag) {
          this.cancelTaskInterval();
          this.$message.error(queryTaskResponse.msg);
          this.loading = false;
          return;
        }

        this.sceneData = queryTaskResponse.resultList;
        if (queryTaskResponse.isCompleted) {
          this.cancelTaskInterval();
          this.$message.success("场景图生成成功");
        }
      }, 5000);
    },
    async submitImage2() {
      try {
        this.loading = true;
        // https://img.houseinbox.com/chair.jpg
        // http://139.9.194.236:2180/chair.jpg
        // https://i.ibb.co/D7GMfMJ/chair.jpg
        // const imageUrl = 'https://i.ibb.co/D7GMfMJ/chair.jpg';
        const submitTaskResponse = await this.submitTask(this.uploadedImageUrl, 'tongyi');

        if (!submitTaskResponse.flag) {
          this.$message.error(submitTaskResponse.msg);
          return;
        }

        this.taskId2 = submitTaskResponse.taskId;
        this.$message.success("任务提交成功");
      } catch (error) {
        console.log('submitTask2 error:', error);
        this.$message.error("任务提交失败，请重试");
        return;
      } finally {
        this.loading = false;
      }

      this.loading = true;
      this.taskInterval2 = setInterval(async () => {
        this.loading = true;
        const queryTaskResponse = await this.queryTask(this.taskId2);

        if (!queryTaskResponse.flag) {
          this.cancelTaskInterval2();
          this.$message.error(queryTaskResponse.msg);
          this.loading = false;
          return;
        }

        this.sceneData2 = queryTaskResponse.resultList;
        if (queryTaskResponse.isCompleted) {
          this.cancelTaskInterval2();
          this.$message.success("场景图生成成功");
        }
      }, 5000);
    },
    cancelTaskInterval() {
      if (this.taskInterval) {
        clearInterval(this.taskInterval);
        this.taskId = null;
        this.taskInterval = null;
        this.loading = false;
      }
    },
    cancelTaskInterval2() {
      if (this.taskInterval2) {
        clearInterval(this.taskInterval2);
        this.taskId2 = null;
        this.taskInterval2 = null;
        this.loading = false;
      }
    },
    clearImages() {
      this.uploadedFile = null;
      this.uploadedImage = null;
      this.uploadedImageUrl = null;
      this.sceneData = [];
      this.cancelTaskInterval();
      this.sceneData2 = [];
      this.cancelTaskInterval2();
      this.$message.success("图片已清除");
    },
    // 打开弹窗并显示选中的项的详情
    openDetailsDialog(item) {
      this.selectedItem = item;
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    // 打开弹窗并显示选中的项的详情
    openDetailsDialog2(item) {
      this.selectedItem2 = item;
      this.dialogVisible2 = true;
    },
    closeDialog2() {
      this.dialogVisible2 = false;
    },
    async uploadImage() {
      var formData = new FormData();
      formData.append("file", this.uploadedFile.raw);
      return this.$axios.post('/productScenario/xaz/uploadFile', formData);
    },
    async submitTask(imageUrl, type) {
      return this.$axios.get('/productScenario/xaz/submitTask', {
        params: {
          url: imageUrl,
          type: type,
        }
      });
    },
    async queryTask(taskId) {
      return this.$axios.get('/productScenario/xaz/queryTask', {
        params: {
          taskId: taskId,
        }
      });
    },
    async generateScenes(imageUrl) {
      return this.$axios.get('/productScenario/generateScenarioImageResults', {
        params: {
          url: imageUrl,
          type: 'midjourney',
        }
      });
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  text-align: center;
}
.upload-section {
  width: 300px;
  margin: auto;
  text-align: center;
}
.upload-area {
  margin-bottom: 10px;
}
.preview-image {
  width: 300px;
  height: 300px;
  object-fit: contain;
  margin-bottom: 10px;
}
.action-buttons {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.button {
  flex: 1;
  max-width: calc(50% - 10px);
}
.grid-section {
  margin-top: 20px;
}
.grid {
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 10px;
  justify-content: center;
}
.grid-item {
  text-align: center;
}
.grid-image {
  width: 300px;
  height: 300px;
  object-fit: contain;
  margin-bottom: 10px;
}
.grid-footer {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.view-details {
  color: #409eff;
  cursor: pointer;
  font-size: 14px;
  transition: color 0.3s ease;
}

.view-details:hover {
  color: #66b1ff;
}
.center-text {
  margin: 0 auto;
}
.dialog-footer {
  text-align: right;
}

.dialog-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dialog-row {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.label {
  display: inline-block;
  width: 90px; /* 固定标签宽度 */
  text-align: right; /* 右对齐标签部分 */
  margin-right: 10px;
  color: #000; /* 设置文字颜色为黑色 */
  font-weight: bold; /* 如果需要加粗文字 */
}

.value {
  flex: 1;
  text-align: left;
  word-wrap: break-word; /* 允许单词被换行 */
  word-break: break-word; /* 支持浏览器对于长单词的自动换行 */
  white-space: pre-wrap; /* 保留空白和换行符，允许自动换行 */
}

.dialog-image {
  width: 300px;
  height: 300px;
  object-fit: contain;
  margin-top: 10px;
}

/deep/ .el-upload-dragger {
  width: 300px;
}

.divider {
  height: 1px;
  background-color: #dcdcdc; /* 分隔线颜色 */
  margin: 16px 0; /* 间距可调整 */
}
</style>
