<template>
  <div class="sidebarWrapper">
    <div class="sidebarMenu">
      <div class="sidebarList"  @mouseenter="showSidebarName()" @mouseleave="hideMenu()">
<!--         <ul>
          <li @mouseenter="showSidebarName(index)" v-for="(item,index) in menulist" :key="index">
            <i :class="item.menuIcon && item.menuIcon.aname"></i>
            <span>{{item.atitle}}</span>
          </li>
        </ul> -->

        <!-- 二级滑出层 -->
        <div
          class="sidebarSlideLayerWrap"
          v-show="isshowsidebarSlideLayerWrap"
          @click="hideMenu()"
        >
          <div class="sidebarSlideLayer">
            <transition name="fade">
              <div class="sidebarName"  :style="{ width: '150px'}" v-show="isshowSidebarName">
                <ul>
                  <li v-for="(second,index2) in menulist" :key="index2">
                    <div class="firstcontent" @click="addAppTabs(second)">{{second.atitle}}</div>
                  </li>
                </ul>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      isshowSidebarName: false,
      isshowsidebarSlideLayerWrap: false,
      isShowMenu: false,
      menuChildren: [],
      menulist: [
        {
          'atitle': 'DashBoard',
          'aname': 'AiProductList',
          'components': 'AiProductList'
        },
        {
          'atitle': 'Create',
          'aname': 'ProductPublishUsa',
          'components': 'ProductPublishUsa'
        }
      ],
      showMenuDate:{},
      dataFilter: this.$store.state.dataFilter,
      dataFilterSelectVisible: false
    };
  },
  components: {

  },
  filters:{
  },
  created: function() {
    
  },
  mounted: function() {

  },
  computed: {

  },
  methods: {
    showSidebarName: function() {
      let _this = this;
      _this.isshowSidebarName = true;
      _this.isshowsidebarSlideLayerWrap = true;
    },
    hideSidebarName: function() {
      let _this = this;
      _this.isshowSidebarName = false;
      _this.isshowsidebarSlideLayerWrap = false;
    },
    showMenu: function(index) {
      let _this = this;
      _this.isShowMenu = true;
      _this.menuChildren = _this.menulist[index].children;
    },
    hideMenu: function() {
      let _this = this;
      _this.isshowSidebarName = false;
      _this.isShowMenu = false;
      _this.isshowsidebarSlideLayerWrap = false;
    },
    addAppTabs: function(menu) {
      var _this = this;
      _this.$emit("addBackTab", menu);

      _this.hideMenu();
    }
  }
};
</script>
