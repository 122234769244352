<template>
  <div class="compPageStyle">
    <!-- 搜索栏 -->
    <div class="search-bar">
      <div class="search-item">
        <el-select v-model="query.thirdAccount" placeholder="Shop" value-key="aid" filterable clearable @change="((val)=>{clear(val, 'query.thirdAccount')})">
          <el-option
            v-for="item in accountList"
            :key="item.aid"
            :label="item.aname + '(' + item.thirdPlatform.aname + ')'"
            :value="item">
          </el-option>
        </el-select>
        <el-select v-model="query.thirdPlatform" placeholder="Platform" value-key="aid" filterable clearable @change="((val)=>{clear(val, 'query.thirdPlatform')})">
          <el-option
            v-for="item in platformList"
            :key="item.aid"
            :label="item.aname"
            :value="item">
          </el-option>
        </el-select>
        <el-select v-model="query.site" placeholder="Site" value-key="aid" filterable clearable @change="((val)=>{clear(val, 'query.site')})">
          <el-option
            v-for="item in siteList"
            :key="item.aid"
            :label="item.aisocode"
            :value="item">
          </el-option>
        </el-select>
        <el-select v-model="query.astatus" placeholder="Status" value-key="aid" filterable clearable @change="((val)=>{clear(val, 'query.astatus')})">
          <el-option
            v-for="item in statusList"
            :key="item.aid"
            :label="item.aname"
            :value="item">
          </el-option>
        </el-select>

        <div style="margin-left: 30px;" class="search-wrapper">
          <el-select v-model="query.filterBy" @change="searchData" placeholder="Search type" class="search-el-select" filterable>
            <el-option
              v-for="(item, index) in filterByList"
              :key="index"
              :label="item.title"
              :value="item.value">
            </el-option>
          </el-select>
          <el-input placeholder="Search txt" v-model="query.search"  @input="searchData">
            <i slot="prefix" class="el-icon-search" @click="searchData()"></i>
          </el-input>
        </div>
        
      </div>

      <div class="search-chanage">
        <el-button  icon="el-icon-download" size="small" plain @click="exportExcel">Export</el-button>
      </div>
      <!-- <el-button @click="aiPublish()" :loading="btnLoading" class="opera-btn">AI Publish</el-button> -->
    </div>
    <!-- <el-row>
      <div class="buttonWrapper">
        <el-button @click="aiPublish()" :loading="btnLoading" class="opera-btn">AI Publish</el-button>
        <el-input v-model="query.aplatformSkuId" placeholder="Asin" @input="getDataList" class="opera-inp"></el-input>
      </div>
    </el-row> -->

    <div class="tableWrapper">
      <el-table 
        :data="dataList"  
        style="width: 100%" 
        @selection-change="handleSelectionChange"
        @sort-change="handleSortChange"
      >
        <el-table-column type="selection" width="55" fixed> </el-table-column>
        <el-table-column prop="merchandise.anumber" label="Sku." width="180"></el-table-column>
        <el-table-column prop="merchandise.aname" label="Sku Name." width="180"></el-table-column>
        <!-- <el-table-column prop="aifAi" label="AI." width="110"></el-table-column> -->
        <el-table-column prop="imageUrl" label="Main Image." width="120">
          <template slot-scope="slotscope">
            <!-- <img v-if="slotscope.row.imageUrl !=null && slotscope.row.imageUrl != ''" :src="slotscope.row.imageUrl" style="height: 40px" alt=""> -->
            <el-image
              v-if="slotscope.row.imageUrl !=null && slotscope.row.imageUrl != ''"
              :src="slotscope.row.imageUrl"
              class="product-list-image"
              fit="contain"
              :preview-src-list="[slotscope.row.imageUrl]"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="thirdPlatform.aname" label="Platform." width="100" ></el-table-column>
        <el-table-column prop="site.aisocode" label="Site." width="90" ></el-table-column>
        <el-table-column prop="aplatformSkuId1" label="Platform Sku ID." width="180">
          <template slot-scope="slotscope">
            <a v-if="slotscope.row.aproductUrl" :href="slotscope.row.aproductUrl" target="_blank" rel="noopener noreferrer"><span>{{slotscope.row.aplatformSkuId1}}</span></a>
            <span v-else>{{slotscope.row.aplatformSkuId1}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="platformSku" label="Platform Sku Name." width="160">
          <template slot-scope="slotscope">
            <a v-if="slotscope.row.aproductUrl" :href="slotscope.row.aproductUrl" target="_blank" rel="noopener noreferrer"><span>{{slotscope.row.platformSku}}</span></a>
            <span v-else>{{slotscope.row.platformSku}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="astatus.aname" label="Status." width="100"></el-table-column>
        <el-table-column prop="price" label="Price." width="180" sortable></el-table-column>
        <el-table-column prop="acurrency.aisocode" label="Currency." width="120"></el-table-column>
        <el-table-column prop="asevenDaySales" label="Sales Qty 7." width="130" sortable></el-table-column>
        <el-table-column prop="athirtyDaySales" label="Sales Qty 30." width="140" sortable></el-table-column>
        <el-table-column prop="aninetyDaySales" label="Sales Qty 90." width="140" sortable></el-table-column>
        <el-table-column prop="categoryBread" label="Category." width="120" >
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              width="300"
              trigger="hover"
              v-if="scope.row.categoryBread != undefined && scope.row.categoryBread !=''"
              :content="scope.row.categoryBread"
              
            >
              <span slot="reference">{{scope.row.categoryBread.substr(0,25)+'...'}}</span>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="atitle" label="Title." width="120" >
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              width="300"
              trigger="hover"
              v-if="scope.row.atitle != undefined && scope.row.atitle !=''"
              :content="scope.row.atitle"
            >
              <span slot="reference">{{scope.row.atitle.substr(0,25)+'...'}}</span>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="adescription" label="Description." width="120">
          <template slot-scope="scope">
            <el-popover
              placement="top-start"
              width="300"
              trigger="hover"
              v-if="scope.row.adescription != undefined && scope.row.adescription !=''"
              :content="scope.row.adescription"
            >
              <span slot="reference">{{scope.row.adescription.substr(0,25)+'...'}}</span>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="openDate" label="Create Time"  width="120"  :formatter="dateFormat"></el-table-column>
        <el-table-column prop="threeOrganization.aname" label="Organization." width="140" ></el-table-column>
      </el-table>

    </div>

    <div class="pageWrapper">
      <span class="page-select-total">{{multipleSelection.length}} of {{pageDomain.pageSize}} row(s) selected.</span>
      <el-pagination
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :current-page.sync="pageDomain.pageIndex"
        :page-size="pageDomain.pageSize"
        :page-sizes="[10, 20, 50, 100]"
        layout="total,prev, pager, next, jumper,sizes"
        :total="pageDomain.recordCount"
      ></el-pagination>
    </div>
  </div>
</template>
<script>

import Vue from 'vue';
import axios from 'axios';
import * as DownloadUtils from '@/utils/DownloadUtils.js';
export default {
  name:'AiProductList',
  data() {
    return{
      query:{
        filterBy: 'platformSkuId',
        search: null,
        sortBy: null,
        order: null,
      },
      multipleSelection:[],
      dataList:[],
      searchLoading: false,
      btnLoading: false,
      pageDomain: {},
      accountList: [],
      siteList: [],
      statusList: [],
      platformList: [],
      pageSelectCount: 0,
      filterByList:[
        {
          title:'Sku.',
          value:"skuNo",
        },
        {
          title: 'Sku Name.',
          value: 'skuName',
        },
        {
          title:'Platform Sku ID.',
          value:"platformSkuId",
        },
        {
          title:'Platform Sku Name.',
          value:"platformSkuName",
        }
      ]
    }
  },
  created(){
    this.queryInit();
  },
  methods:{
    handleSizeChange: function(val) {
      this.pageDomain.pageSize = val;
      this.getDataList();
    },
    handleSelectionChange: function(val) {
      this.multipleSelection = val;
    },
    handleCurrentChange: function() {
      this.getDataList();
    },
    queryInit: function() {
      var  _this= this;
      var dataMap = {
        query:_this.query
      };
      _this.$axios.post('/platformGoods/xaz/queryInit', dataMap
      ).then((result) => {
        if(result.flag == true) {
          console.log(result)
          _this.query = result.query;
          _this.dataList = result.dataList;
          _this.pageDomain = result.pageInfo;
          _this.accountList = result.accountList;
          _this.siteList = result.siteList;
          _this.statusList = result.statusList;
          _this.platformList = result.platformList;
        }
      });
    },

    clear:function(event, propertyStr) {
      var _this = this;
      var propertyArr = propertyStr.split(".");
      // if(propertyStr === 'aquery.platform'){
      //   if(event == '' || event.aname == null){
      //     _this.thirdAccountList = _this.thirdAccountListAll;
      //   }else{
      //     _this.thirdAccountList = _this.accountMap[event.aname];
      //   }
      // }
      if(event == '') {
        var property = _this;
        for(var i = 0; i < propertyArr.length; i++) {
          if(i == propertyArr.length - 1) {
            property[propertyArr[i]] = {};
            break;
          }
          property = property[propertyArr[i]];
        }
      }
      this.searchData();
    },

    getDataList: function() {
      var _this = this;
      
      var dataMap = {
        "query": _this.query,
        "pageInfo": _this.pageDomain
      };

      _this.searchLoading = true;

      _this.$axios.post('/platformGoods/xaz/selectList', dataMap
          ).then((result) => {
            if(result.flag == true) {
              _this.dataList = result.dataList;
              _this.pageDomain = result.pageInfo;
            }else {
              _this.$message({ showClose: true, message: result.msg, type: 'error'  });
            }

            _this.searchLoading = false;
          });

      setTimeout(function() {
        _this.searchLoading = false;
      }, 10 * 1000);
    },
    searchData: function() {
      this.pageDomain.pageIndex = 1;
      this.pageDomain.recordCount = 0;
      this.getDataList();
    },
    exportExcel() {
      this.$confirm('Do you want to export all records?', 'Export Confirmation', {
        confirmButtonText: 'Export',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        var dataMap = {
          "query": this.query,
        };
        this.$axios.post('/platformGoods/xaz/exportExcel', dataMap, {responseType:'blob'}).then(async result => {
          if (result.response.headers.flag === 'false') {
            const msg = await result.text();
            this.$message.error(msg);
            return;
          }

          DownloadUtils.download(result, 'dashboard.xlsx');
        })
        .finally(() =>{
        });
      }, () => {
      });
    },
    aiPublish: function() {
      var _this = this;

      if(_this.multipleSelection.length != 1) {
        this.$message.error("Please select one sku");
        return;
      }

      _this.btnLoading = true;
      var dataMap = {
        "selectedProductID": _this.multipleSelection[0].aid
      };
      
      _this.btnDisabled
      _this.$axios.post('/aiProduct/xaz/aiPublish', dataMap
      ).then((result) => {
        debugger;
        if(result.flag == true) {
          _this.getDataList();
        }else {
          _this.$message({ showClose: true, message: result.msg, type: 'error'  });
        }

        _this.btnLoading = false;
      });

      setTimeout(function() {
        _this.btnLoading = false;
      }, 20 * 1000);
    },
    handleSortChange(sort) {
      const { prop, order } = sort; 
      console.log('排序字段:', prop);
      console.log('排序顺序:', order);
      this.query.sortBy = prop;
      this.query.order = order;
      
      //重新渲染数据
      this.searchData();
    },
    dateFormat: function(row, column, cellValue) {
      if (cellValue != null) {
        var date = new Date(cellValue);
        return (
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate()
        );
      } else {
        return "";
      }
    },
  }
}
</script>

<style lang="less" scoped>
:root {
    --el-color-primary: #000; /* 主颜色 */
    --el-color-active: #000; /* 全局 active 颜色 */
    --el-button-hover-bg: #000; /* 修改按钮 hover 状态颜色 */
    --el-button-active-bg: #000; /* 修改按钮 active 状态颜色 */
}
.compPageStyle{
  width: 98%;
  margin: 0px auto;

}
.search-bar {
    display: flex;               /* 使用 flexbox 布局 */
    justify-content: space-between; /* 在主轴上平均分配两端的元素 */
    align-items: center;        /* 垂直居中对齐 */
    padding: 20px;              /* 添加内边距 */
    background-color: #fff;  /* 背景颜色可以根据需求调整 */
    margin: 20px 0px;
    border-radius: 8px;
}

.search-item {
    display: flex;              /* 使用 flexbox 布局 */
    gap: 0px;                  /* 选择项之间的间隔 */
}

/deep/ .search-item .el-select {
    margin: 0 10px 0px 0px;            /* 左右边距 */
    //background-color: #000;    /* 背景色为黑色 */
    //border-color: #000;
    //color: white;               /* 文本颜色为白色 */
    height: 35px;
    line-height: 35px;
    border-radius: 0;           /* 去除圆角 */
}

/deep/ .search-item .el-select .el-input__inner {
    height: 35px;
    line-height: 35px;
    //color: white !important;               /* 字体颜色为白色 */
    //background-color: #000 !important;     /* 背景色为黑色 */
    //border-radius: 0;                       /* 去除圆角 */

}
/deep/ .el-select .el-input.is-focus .el-input__inner {
    border-color: var(--el-color-primary); /* 设置焦点时的边框颜色 */
}
/deep/ .el-select-dropdown__item.hover, .el-select-dropdown__item.selected {
    background-color: var(--el-color-active); /* 选中项的背景颜色 */
    color: #000; /* 选中项的文字颜色 */
}

/deep/ .el-select-dropdown__item.selected {
    background-color: var(--el-color-primary); /* 修改选中项的背景颜色为主色 */
    color: #fff; /* 选中项文字颜色为白色 */
}
/deep/ .search-item .el-input {
    height: 35px;
    line-height: 35px;
    //border-radius: 0;           /* 去除圆角 */
}
/deep/ .el-input .el-input__inner{
    height: 35px;
    line-height: 35px;
    //border-radius: 0;           /* 去除圆角 */
}
/deep/ .el-input .el-input__inner:focus {
  border-color: #000;
}

.search-wrapper{
  margin-left: 30px;
}

.search-chanage {
    display: flex;              /* 使用 flexbox 布局 */
    align-items: center;        /* 垂直居中 */
    margin-left: auto;          /* 把这个 div 移到右边 */
}

.search-chanage i {
    margin-left: 15px;          /* 左右图标之间的空间 */
    cursor: pointer;            /* 鼠标悬停时显示手形光标 */
}


.opera-btn{
  padding: 0px 15px;
  height: 35px; /* 按钮高度 */
  text-align: center;
  font-weight: bold;
}
/deep/ .opera-inp{
  height: 35px !important;
  line-height: 35px !important;
}

.pageWrapper{
  position: relative;
  .page-select-total{
    width: 220px;
    display: block;
    position: absolute;
    left: 0px;
    top: 10px;
    z-index: 5;
    font-size: 13px;
    font-weight: 500;
  }
  /deep/ .el-select {
    width: 110px !important;
    height: 35px;
    line-height: 35px;
  }
  /deep/ .el-select .el-input{
    width: 110px !important;
    height: 35px;
    line-height: 35px;
  }
  /deep/ .el-select .el-input__inner {
      height: 35px;
      line-height: 35px;                 

  }
  /deep/  .el-input {
      width: 80px !important;
      height: 35px;
      line-height: 35px;
  }
  /deep/ .el-input .el-input__inner{
      height: 35px;
      line-height: 35px;
  }

/* pagination */
/deep/ .el-pagination .el-pager  {
  color: #000;
}

/deep/ .el-pagination {
  text-align: center;
  .el-pager {
    li:hover {
      color: #000;
    }
  }
  .btn-next:hover,
  .btn-prev:hover {
    color: #000;
    i:hover {
      color: #000;
    }
  }
  .el-pagination__sizes {
    .el-select {
      .el-input {
        .el-input__inner:hover {
          border-color: #000;
        }
      }
      .is-focus {
        .el-input__inner {
          border-color: #000;
        }
      }
      .el-input__inner:focus {
        border-color: #000;
      }
    }
  }
  .el-pagination__jump {
    .el-pagination__editor {
      .el-input__inner:focus {
        border-color: #000;
      }
    }
  }
  .btn-prev,.btn-next{
    background-color: #000;
    color: #fff;
  }
  .btn-prev:hover,.btn-next:hover{
    background-color: #000;
    color: #fff;
  }
}


}


.product-list-image{
  width: 40px;
  height: 40px;
  object-fit: contain;
  cursor: pointer;
  display: inline-block;
}

/deep/ .el-table th .el-checkbox {
  margin: 0; /* 清除任何原始上/下边距 */
  line-height: normal; /* 确保行高正确 */
  margin-left: -5px;
  padding-top: 5px;
}

.search-el-select{
  width: 160px;
  margin: 0 0px 0px 0px !important;
}

</style>
