import { render, staticRenderFns } from "./PublishHistoryList.vue?vue&type=template&id=32f5c04f&scoped=true"
import script from "./PublishHistoryList.vue?vue&type=script&lang=js"
export * from "./PublishHistoryList.vue?vue&type=script&lang=js"
import style0 from "./PublishHistoryList.vue?vue&type=style&index=0&id=32f5c04f&prod&lang=less&scoped=true&deep=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32f5c04f",
  null
  
)

export default component.exports