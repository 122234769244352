import axios from 'axios';
import urlUtil from '@/utils/urlUtil.js';
//import BaseApp from "@/utils/baseApp.js";
import { MessageBox } from 'element-ui';
import Cookies from 'js-cookie';

function createService(options) {

const service = axios.create({
  baseURL: options.baseURL || '',
  timeout: 600 * 1000,
  headers: {
  }
});

service.interceptors.request.use(
  (config) => {
    var context = {};
    // if(window.__MICRO_APP_ENVIRONMENT__) {
    //     context = window.microApp.getData();
    // }

    config.headers['X-forwarded-for'] = '127.0.0.1';
    /*
    config.headers['token'] = context.token;
    config.headers['userid'] = context.user == null? '': context.user.aid;
    config.headers['username'] = context.user == null? '': encodeURIComponent(context.user.aname);
    config.headers['usernumber'] = context.user == null? '': context.user.anumber;
    config.headers['warehouse'] = context.warehouse == null? '20190428000002': context.warehouse;
    config.headers['loginTime'] = context.loginTime == null? 0: context.loginTime;

    config.headers['serviceToken'] = context.token;
    */

    config.headers['token'] = Cookies.get('cook_serviceToken');
    config.headers['userid'] = Cookies.get('cook_userid');
    config.headers['username'] = Cookies.get('cook_userName');
    config.headers['usernumber'] = Cookies.get('cook_userNumber');
    config.headers['warehouse'] = context.warehouse == null? '20190428000002': context.warehouse;
    config.headers['loginTime'] = Cookies.get('cook_loginTime');

    config.headers['serviceToken'] = Cookies.get('cook_serviceToken');
    config.withCredentials = false;
    // 获取二级域名
    let level2domain = "";
    level2domain = urlUtil.getLevel2domain();
    config.headers['currentDomain'] = level2domain;
    
    config.url = options.urlPrefix + config.url

    const uid = Cookies.get("uid");
    if (uid) {
      config.params = {
        ...config.params,
        uid,
      };
    }

    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

service.interceptors.response.use(
  async (response) => {
    var requestData = response.data;
    if (requestData instanceof Blob && requestData.type === 'application/json') {
      const json = await requestData.text();
      requestData = JSON.parse(json);
    }
    //var baseApp = BaseApp.getBaseAppVue();
    if(requestData.flag == false) {
        if (requestData.code == 403 || requestData.code == 401) {
          MessageBox.confirm('Login has expired. Please click OK to log in again.', 'Tips', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }).then(() => {
            window.location = '/PublishLogin'; 
          }).catch(() => {
            
          });

          // 阻止后续业务代码执行，返回一个 pending 的 Promise
          return new Promise(() => {});
        }
        
    }

    function isObject(value) {
      var type = typeof value;
      return value != null && (type == 'object' || type == 'function');
    }

    if (isObject(requestData)) {
      Object.defineProperty(requestData, 'response', {
        value: response,
        enumerable: false,
      });
    }

    return requestData;
  },
  (err) => {
    return Promise.reject(err);
  }
);

return service;

}

export default createService;
