<template>
    <div  class="header">
      <div class="headerTopDom">
      </div>
    </div>
</template>

<script>

export default {
  name: 'Header',

  data: function() {
    return {
      
    }
  },
  components: {
  },
  computed: {
    
  },
  mounted: function(){
    
  },
  methods: {
    
  },
  
}
</script>
