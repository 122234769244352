<template>
  <div class="container">
    <!-- 第一块：ASIN输入框和按钮 -->
    <div class="input-section">
      <el-input v-model="asinUrl" placeholder="Enter Amazon ASIN URL" style="width: 300px;"></el-input>
      <el-button :loading="isLoading" type="primary" @click="fetchSimilarItems">确定</el-button>
    </div>

    <!-- 第二块：表格显示对比信息 -->
    <div class="table-section">
      <h2 class="text-center">竞品对比</h2>
      <el-table :data="tableData" border>
        <el-table-column prop="field" label="">
          <template slot="header">
            <div class="text-center">ASIN</div>
          </template>
          <template slot-scope="scope">
            <div class="text-center">{{ scope.row.field }}</div>
          </template>
        </el-table-column>
        <el-table-column v-for="(item, index) in similarItems" :key="item.asin">
          <template slot="header">
            <a :href="item.url" target="_blank">{{ item.asin }}</a>
          </template>
          <template slot-scope="scope">
            <div v-if="scope.row.field === '标题'">
              <el-popover trigger="hover" placement="top-start">
                <template #reference>
                  <div class="truncate-text">{{ item.title }}</div>
                </template>
                <div>{{ item.title }}</div>
              </el-popover>
            </div>

            <!--
            <div v-else-if="scope.row.field === 'Bullet Points'">
              <el-popover trigger="hover" placement="top-start">
                <template #reference>
                  <div class="truncate-text">{{ item.bulletPoints[0] }}</div>
                </template>
                <div v-html="item.bulletPointsTooltip"></div>
              </el-popover>
            </div>
            -->

            <div v-else-if="scope.row.field === '主图'" class="image-center">
              <el-image :src="item.mainPicutre" :preview-src-list="[item.mainPicutre]" fit="contain" class="responsive-image"></el-image>
            </div>

            <div v-else-if="scope.row.field === 'AI标题'">
              <el-popover trigger="hover" placement="top-start">
                <template #reference>
                  <div class="truncate-text">{{ item.amazonSimilarItem.gptGeneratedAmazonProductListing.title }}</div>
                </template>
                <div>{{ item.amazonSimilarItem.gptGeneratedAmazonProductListing.title }}</div>
              </el-popover>
            </div>

            <!--
            <div v-else-if="scope.row.field === 'Generated Bullet Points'">
              <el-popover trigger="hover" placement="top-start">
                <template #reference>
                  <div class="truncate-text">{{ item.generatedBulletPoints[0] }}</div>
                </template>
                <div v-html="item.generatedBulletPointsTooltip"></div>
              </el-popover>
            </div>
            -->

            <div v-else-if="scope.row.field === '场景图'" class="image-center">
              <el-image
                :src="item.sceneImageGrid[0].jpegUrl"
                :preview-src-list="[item.sceneImageGrid[0].pngUrl]"
                class="responsive-image"
              ></el-image>
            </div>

            <div v-else-if="scope.row.field === '白底图'" class="image-center">
              <el-image
                :src="item.whiteBgImageGrid[0].jpegUrl"
                :preview-src-list="[item.whiteBgImageGrid[0].pngUrl]"
                class="responsive-image"
              ></el-image>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AmazonSimilarItems',
  data() {
    return {
      asinUrl: 'https://www.amazon.com/dp/B0BFJ5G3KZ', // 输入的ASIN URL
      similarItems: [], // 后端返回的similarItems数据
      tableData: [
        { field: '标题' },
        // { field: 'Bullet Points' },
        { field: '主图' },
        { field: 'AI标题' },
        // { field: 'Generated Bullet Points' },
        { field: '场景图' },
        { field: '白底图' }
      ],
      isLoading: false // 按钮的加载状态
    };
  },
  mounted () {
    this.fetchSimilarItems();
  },
  methods: {
    async fetchSimilarItems() {
      if (!this.asinUrl) return;
      if (!this.asinUrl.trim()) return;
      if (this.isLoading) return; // 防止重复点击
      this.isLoading = true;
      try {
        const result = await this.$datacenter.get('/amazonSimilarItems/xaz/getSimilarItems', {
          params: {
            asinUrl: this.asinUrl.trim(),
          }
        });
        if (result.flag) {
          // 并行处理Scene Image和White Background Image
          await Promise.all(result.similarItems.map(async item => {
            // const [sceneImageGrid, whiteBgImageGrid] = await Promise.all([
            //   this.splitImageIntoGrid(item.amazonSimilarItem.sceneImagineResult.rawImageUrl),
            //   this.splitImageIntoGrid(item.amazonSimilarItem.whiteBackgroundImagineResult.rawImageUrl)
            // ]);

            const sceneImageGrid = item.amazonSimilarItem.sceneImageUrl.subImageUrls.slice(0, 1);
            const whiteBgImageGrid = item.amazonSimilarItem.whiteBackgroundImageUrl.subImageUrls.slice(0, 1);

            item.sceneImageGrid = sceneImageGrid;
            item.whiteBgImageGrid = whiteBgImageGrid;

            // 解析 Bullet Points 和 Generated Bullet Points
            item.bulletPoints = JSON.parse(item.sellingPoint);
            item.generatedBulletPoints = item.amazonSimilarItem.gptGeneratedAmazonProductListing.bulletPoints;

            // 创建 Popover 内容
            item.bulletPointsTooltip = item.bulletPoints.join('<br>');
            item.generatedBulletPointsTooltip = item.generatedBulletPoints.join('<br>');
          }));
          this.similarItems = result.similarItems;
        } else {
          this.$message.error(result.msg);
        }
      } catch (error) {
        console.log('fetchSimilarItems error:', error);
        this.$message.error('Failed to fetch similar items.');
      } finally {
        this.isLoading = false; // 请求结束后关闭loading效果
      }
    },

    // 将一张2x2的图片拆分为4张图片
    splitImageIntoGrid(imageUrl) {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.crossOrigin = "Anonymous"; // 处理跨域问题
        image.src = imageUrl;

        image.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          const { width, height } = image;
          const gridWidth = width / 2;
          const gridHeight = height / 2;

          // 创建第一个画布区域，只提取第1张图片
          canvas.width = gridWidth;
          canvas.height = gridHeight;
          ctx.drawImage(image, 0, 0, gridWidth, gridHeight, 0, 0, gridWidth, gridHeight);

          const firstImage = canvas.toDataURL('image/png');

          resolve([firstImage]); // 只返回第一张图片
        };

        image.onerror = () => {
          reject(new Error('Failed to load image.'));
        };
      });
    },
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.input-section {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.input-section .el-input {
  margin-right: 10px;
}

.table-section {
  width: 100%;
}

.text-center {
  text-align: center;
}

.image-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* 确保占满父元素高度，图片会自动居中 */
}

/deep/ .responsive-image img {
  width: 100%;
  height: auto;
  max-width: 200px;
  max-height: 200px;
  min-width: 100px;
  min-height: 100px;
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item {
  white-space: nowrap;
}

/deep/ .el-table td img, /deep/ .el-table th img {
  width: auto;
  height: auto;
}

/deep/ .el-table th.el-table__cell>.cell {
  user-select: text; /* 允许文本选中 */
}
</style>
