import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

import axios from 'axios'
import Cookies from 'js-cookie'

import userBoard from '../pages/Index.vue'
import mirrorImage from '../pages/mirrorImage.vue'
import AmazonConsultant from '../pages/AmazonConsultant.vue'
import AmazonSimilarItems from '../pages/AmazonSimilarItems.vue'
import DesignCopilot from '../pages/DesignCopilot.vue'
import ProductScenario from '../pages/ProductScenario.vue'
import ProductPublish from '../pages/ProductPublish.vue'
import ProductPublishTest from '../pages/ProductPublishTest.vue'
import ProductPublishTest2 from '../pages/ProductPublishTest2.vue'
import ProductPublishUsa from '../pages/ProductPublishUsa.vue'
import PublishIndex from '../pages/PublishIndex.vue'
import PublishLogin from '../pages/PublishLogin.vue';  // 引入登录页面组件
import PublishRegister from '../pages/PublishRegister.vue'; // 引入注册页面组件
import Verification from '../pages/Verification.vue';
import PublishDashboard from '../pages/PublishDashboard.vue';
import PublishDashboard2 from '../pages/PublishDashboard2.vue';
import UserProfile from '../pages/UserProfile.vue';
import PublishHistoryList from '../pages/PublishHistoryList.vue';
import PublishHistoryEdit from '../pages/PublishHistoryEdit.vue';
import ProductAnalytics  from '../pages/ProductAnalytics.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/userBoard',
    name: 'userBoard',
    component: userBoard
  },
  {
    path: '/',
    name: 'home',
    component: PublishIndex
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },

  {
    path: '/PublishIndex',
    name: 'PublishIndex',
    component: PublishIndex
  },
  {
    path: '/ProductPublishUsa',
    name: 'portal_ProductPublishUsa',
    component: ProductPublishUsa
  },
  {
    path: '/PublishLogin',
    name: 'PublishLogin',
    component: PublishLogin
  },
  {
    path: '/PublishRegister',
    name: 'PublishRegister',
    component: PublishRegister
  },
  {
    path: '/Verification',
    name: 'Verification',
    component: Verification
  },
  {
    path: '/PublishDashboard',
    name: 'PublishDashboard',
    component: PublishDashboard2
  },
  {
    path: '/PublishDashboard2',
    name: 'PublishDashboard2',
    component: PublishDashboard2
  },
  {
    path: '/UserProfile',
    name: 'UserProfile',
    component: UserProfile
  },
  {
    path: '/PublishHistoryList',
    name: 'PublishHistoryList',
    component: PublishHistoryList
  },
  {
    path: '/PublishHistoryEdit',
    name: 'PublishHistoryEdit',
    component: PublishHistoryEdit
  },
  {
    path: '/ProductAnalytics',
    name: 'ProductAnalytics',
    component: ProductAnalytics
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function getDomain(hostname) {
  // 判断是否是 IP 地址（IPv4 / IPv6）
  if (/^\d{1,3}(\.\d{1,3}){3}$/.test(hostname) || /^[a-fA-F0-9:]+$/.test(hostname)) {
    return hostname; // 直接返回 IP
  }

  // 提取主域名（去掉子域名）
  let parts = hostname.split('.');
  if (parts.length > 2) {
    return parts.slice(-2).join('.'); // 取最后两个部分作为主域名
  }

  return hostname; // 直接返回
}


router.beforeEach(async (to, from, next) => {
  let ctxComplete = Cookies.get('cook_ctxComplete');
  if (ctxComplete) {
    await axios.get('/ai/user/ssoLogin');

    let currentDomain = getDomain(location.hostname);
    Cookies.remove('cook_ctxComplete', { path: '/', domain: currentDomain });
  }

  let loginComplete = Cookies.get('cook_loginComplete');
  if (loginComplete && process.env.NODE_ENV === 'production') {
    let userId = Cookies.get('cook_userid');
    let random = Cookies.get('cook_random');

    let currentDomain = getDomain(location.hostname);
    Cookies.remove('cook_loginComplete', { path: '/', domain: currentDomain });

    let url = '//sso.' + currentDomain + '/authorize/sso/domainList?currentDomain=' + currentDomain;
    let result = await axios.get(url, { withCredentials: true });

    window.crossSiteCookieJsonp = function () {};

    let crossSiteList = result.data.crossSiteList;
    crossSiteList.forEach(crossSite => {
      let fullUrl = 'https://sso.' + crossSite + '/authorize/sso/crossSiteCookie';

      let params = {
        callbackQuery: 'crossSiteCookieJsonp',
        targetDomain: crossSite,
        userId: userId,
        random: random,
        ifKeepLogin: false
      };

      let queryString = Object.keys(params).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
      }).join('&');

      let s = document.createElement('script');
      s.src = fullUrl + '?' + queryString;
      document.body.appendChild(s);
    });
  }

  next();
})

export default router
