<template>
  <div class="container">
    <div class="container_self">
      <header class="pub_header">
          <div class="pub_logo">AI Publish</div>
          <nav class="index_menu">
              
          </nav>
          <div class="index_auth-buttons">
             
          </div>
      </header>
      <div class="login-container">
        <h1 class="title">Check you email</h1>
        
        <form class="login-form">
          <div class="signup-link">
            Enter the verification code we just sent to xx@example.com
          </div>
          <div class="form-group">
            <input type="text" placeholder="Code" class="input-field">
          </div>
          
          <el-button type="submit" class="btn-continue">Continue</el-button>
        </form>

        
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name:"Verification",
  data(){
    return{

    }
  },
  methods:{

  }
}
</script>
<style lang="less" scoped>
.container_self{
    background-color: #ffffff;
    width: 100%;                    /* 宽度100% */
    height: 100vh;                  /* 高度100% */
    display: flex;                  /* 使用 Flexbox */
    justify-content: center;        /* 水平居中 */
    align-items: center;            /* 垂直居中 */
    position: relative;
}
.pub_header {
    position: absolute;             /* 使绝对定位的元素相对于该元素定位 */
    top: 50px;                     /* 从顶部下移100px */
    left: 0px;
    z-index: 3;                     
    display: flex;                  /* 使用 Flexbox 布局 */
    justify-content: space-between;  /* 在三部分之间添加空间 */
    align-items: center;            /* 垂直居中对齐 */
    width: 100%;                    /* 头部宽度100% */
    
}

.pub_logo {
    flex: 1;                        /* 左侧logo占据部分 */
    text-align: center;
    color: #111111;
    font-size: 38px;
    font-weight: 800;
}

.index_menu {
    flex: 2;                        /* 中间菜单占据部分 */
    text-align: center;
    ul {
        list-style: none;              /* 去掉列表样式 */
        display: flex;                 /* 使用 Flexbox 来水平排列菜单项 */
        justify-content: center;       /* 居中菜单项 */
        padding: 0;                    /* 去掉内边距 */
        margin: 0;                     /* 去掉外边距 */

        li {
            padding: 0 15px;                /* 菜单项之间间距 */
            display: inline-block;
            width: 15%;
            color: #ffffff;
            font-size: 22px;
            font-weight: bold;
        }
    }

    
}

.index_auth-buttons {
    flex: 1;                        /* 右侧登录和注册按钮占据部分 */
    display: flex;                 /* 水平排列按钮 */
    justify-content: center;     
    text-align: center;

    .el-button {
        margin-left: 20px;             /* 按钮之间间距 */
        height: 40px;
        line-height: 40px;
        background-color: #ffffff;
        color: #111111;
        font-size: 20px;
        font-weight: bold;
    }
}



@primary-color: #2563eb;
@gray-color: #6b7280;
@border-color: #e5e7eb;

.login-container {
  max-width: 400px;
  margin: 20px auto;
  padding: 20px;
  text-align: center;

  .title {
    font-size: 26px;
    margin-bottom: 30px;
    color: #1f2937;
  }

  .login-form {
    .form-group {
      margin-bottom: 20px;

      .input-field {
        width: 100%;
        height: 40px;
        line-height: 40px;
        padding: 0px 10px;
        border: 1px solid @border-color;
        border-radius: 12px;
        font-size: 14px;
        &:focus {
          outline: none;
          border-color: @primary-color;
          box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
        }
      }
    }

    .btn-continue {
      width: 100%;
      height: 40px;
      line-height: 40px;
      padding: 0px 5px;
      background-color: @primary-color;
      color: white;
      border: none;
      border-radius: 12px;
      font-size: 18px;
      cursor: pointer;
      margin-top: 10px;

      &:hover {
        background-color: darken(@primary-color, 5%);
      }
    }
  }

  .signup-link {
    width: 100%;
    margin: 30px 0;
    color: @gray-color;
    font-size: 12px;

    .link {
      color: @primary-color;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .divider {
    display: flex;
    align-items: center;
    margin: 10px 0;

    &::before,
    &::after {
      content: '';
      flex: 1;
      border-bottom: 1px solid @border-color;
    }

    .divider-text {
      padding: 0 15px;
      color: @gray-color;
      font-size: 14px;
    }
  }

  .btn-google {
    width: 100%;
    height: 40px;
    line-height: 40px;
    background-color: white;
    border: 1px solid @border-color;
    border-radius: 12px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: @gray-color;
    .btn-google-img{
      width: 27px;
      height: 27px;
    }
    &:hover {
      background-color: #f9fafb;
    }
  }

  .footer-links {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    gap: 10px;
    color: @primary-color;

    .link {
     color: @primary-color;
      text-decoration: none;
      font-size: 12px;

      &:hover {
        text-decoration: underline;
      }
    }

    .separator {
      color: @border-color;
    }
  }
  .pass-title{
    line-height: 20px;
    font-size: 12px;
    margin-top: -15px;
  }
}



</style>