<template>
  <div class="container">
    <div class="container_slef">
        <router-link to="/PublishDashboard">
          <el-button icon="el-icon-back" class="back-to-dashboard-btn">Back to Dashboard</el-button>
        </router-link>
        <div class="left-panel">
            <!-- @click="setActive(1)" -->
            <div class="list-item"  :class="{'active':1 == activeIndex}">
                <i class="el-icon-circle-check" v-if="activeIndex > 1"></i>
                <div class="circle" v-else>1</div>
                Select Platform
            </div>
            <div class="list-item"  :class="{'active':2 == activeIndex}">
                <i class="el-icon-circle-check" v-if="activeIndex > 2"></i>
                <div class="circle" v-else>2</div>
                Input Product Link
            </div>
            <div class="list-item"  :class="{'active':3 == activeIndex}">
                <i class="el-icon-circle-check" v-if="activeIndex > 3"></i>
                <div class="circle" v-else>3</div>
                Edit Image
            </div>
            <div class="list-item"  :class="{'active':4 == activeIndex}">
                <i class="el-icon-circle-check" v-if="activeIndex > 4"></i>
                <div class="circle" v-else>4</div>
                View Categories
            </div>
            <div class="list-item"  :class="{'active':5 == activeIndex}">
                <i class="el-icon-circle-check" v-if="activeIndex > 5"></i>
                <div class="circle" v-else>5</div>
                Titles & Descriptions
            </div>
            <div class="list-item"  :class="{'active':6 == activeIndex}">
                <i class="el-icon-circle-check" v-if="activeIndex > 6"></i>
                <div class="circle" v-else>6</div>
                Images
            </div>
            <div class="list-item"  :class="{'active':7 == activeIndex}">
                <i class="el-icon-circle-check" v-if="activeIndex > 7"></i>
                <div class="circle" v-else>7</div>
                Information Edit
            </div>
            <div class="list-item"  :class="{'active':8 == activeIndex}">
                <i class="el-icon-circle-check" v-if="activeIndex > 8"></i>
                <div class="circle" v-else>8</div>
                Listing Information
            </div>
        </div>
        <div class="right-panel">
            <div class="right-main">
              <!-- 1选择平台 -->
              <div class="select-platfrom" v-if="activeIndex ==1">
                <h2>Select your platform</h2>
                <div class="button-list">
                  <ul>
                    <li v-for="(platform, index) in platformList" :key="index" >
                      <el-button  class="custom-button" @click="setSelectedPlatform(platform,index)"> 
                          <i class="el-icon-arrow-right"  v-if="platform.showFlag == false"></i>
                          <i class="el-icon-arrow-down"  v-else></i>  
                          {{ platform.title }}
                      </el-button>
                      <div class="custom-site-List"  v-show="platform.showFlag == true">
                        <i v-for="(item,index2) in platform.selectSiteList" :key="index2" @click="changeSiteSpan(index,index2);" :class="{'bgcolor':index2 == spanIndex}" >{{item}}</i>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- 2输入link-->
              <div class="link-wrapper" v-if="activeIndex ==2">
                <div class="link-img">
                  <el-image
                    v-if="previewImageUrl"
                    :src="previewImageUrl"
                    class="preview-image"
                    fit="contain"
                    :preview-src-list="[previewImageUrl]"
                  >
                  </el-image>
                </div>
                <h2>Enter product link</h2>
                <!-- 品牌 -->
                <div>
                  <div class="linkitemtitle">Brand Name <span class="required">*</span></div>
                  <div class="file-and-link">
                    <el-input
                      class="link-input"
                      placeholder="Enter brand name"
                      prefix-icon="el-icon-video-camera"
                      v-model="brandName">
                    </el-input>
                  </div>
                </div>
                <!-- 文件上传与链接 -->
                <div>
                  <div class="linkitemtitle">Product Image <span class="required">*</span></div>
                  <div class="file-and-link">
                    <!-- 隐藏的文件输入 -->
                    <input type="file" class="uploadFile" @change="handleFileChange" ref="fileInput" />
                    <button class="upload-button" @click="triggerFileInput">upload image</button>
                    <span class="upload-span"> or </span>
                    <el-input v-model="productLink" class="link-input" placeholder="https://" @change="chanageProductLink"></el-input>
                  </div>
                </div>
                <!--sku 物料-->
                <div>
                  <div class="linkitemtitle">Product SKU (Optional) 
                    <i v-if="checkSkuExistsFlag" class="el-icon-success successColor"></i> 
                    <span v-if="checkSkuExistsMsg" class="required">{{checkSkuExistsMsg}}</span>
                  </div>
                  <div class="file-and-link">
                    <el-input
                      class="link-input"
                      placeholder="Enter product SKU(13 digits)"
                      prefix-icon="el-icon-video-camera"
                      v-model="skuNumber" @change="checkSkuExists">
                    </el-input>
                  </div>
                </div>
                <el-button class="link-btn" :loading="handleLinkSubmitLoading" @click="handleLinkSubmit" :disabled="handleLinkSubmitDisable">Submit</el-button>
                <br>
                <el-button class="back-btn"  @click="activeIndex =1">Back</el-button>
                <br>
              </div>
              <!-- 3编辑图片画布-->
              <div class="canvas-wrapper" v-if="activeIndex ==3" >
                <div class="canvasSizeWrapper">
                  <div class="platfromSelect-title">Image Size:</div>
                  <!-- <el-radio-group v-model="canvasSizeIndex" size="small"  @change="chanageCanvasSize">
                    <el-radio-button
                      v-for="(item, index) in canvasSizeList"
                      :key="index"
                      :label="index"
                    >
                      {{ item.title}}
                    </el-radio-button>
                  </el-radio-group> -->
                  <div>
                    <span>W:</span>
                    <el-input class="mini-input" placeholder="width" v-model="exportImgWidth" type="number" :min="100" :max="5000" @change="chanageExportImgSize"></el-input><br>
                    <span>H:</span>
                    <el-input class="mini-input" placeholder="height" v-model="exportImgHeight" type="number" :min="100" :max="5000" @change="chanageExportImgSize"></el-input><br>
                  </div>
                </div>
                <div class="canvasMain">
                  <canvas id="fabricCanvas" :width="canvasWidth" :height="canvasHeight"  class="canvasDiv"></canvas>
                </div>
                <div class="clear"></div>
                
                <el-button class="next-btn" :loading="submitCanvasLoading" @click="submitCanvas">Next</el-button>

              </div>
              <!-- 4选择类目-->
              <div class="cate-wrapper" v-if="activeIndex ==4">
                <div class="link-img">
                  <el-image
                    :src="previewImageUrl"
                    class="preview-image"
                    fit="contain"
                    :preview-src-list="[previewImageUrl]"
                  >
                  </el-image>
                </div>
                <h2>Product Categories <i class="el-icon-edit-outline" @click="showEditCategory" v-if="!isCategoryEditing"></i> <i class="el-icon-check" v-else @click="hideEditCategory"></i></h2>
                <h3>Precise Categories </h3>
                <el-checkbox-group v-model="checkedCateList" class="cate-check" v-if="!isCategoryEditing">
                  <el-checkbox v-for="(item,index) in furnitureCategories" :label="item" :key="index"><span class="checkbox-label" @click.stop>{{ item }}</span></el-checkbox>
                </el-checkbox-group>
                <el-checkbox-group v-model="checkedCateList" class="cate-check" v-else>
                  <el-checkbox v-for="(item,index) in furnitureCategories" :label="item" :key="index">
                    <el-input  v-model="furnitureCategories[index]" class="create-large-el-input"></el-input>
                  </el-checkbox>
                </el-checkbox-group>
                <!-- <ul v-else>
                  <li v-for="(item,index) in furnitureCategories" :label="item" :key="index">
                    <el-input type="textarea" :rows="1" v-model="furnitureCategories[index]" class="large-textarea"></el-input>
                  </li>
                </ul> -->
                <h3>Creative Categories</h3>
                <el-checkbox-group v-model="checkedCateListNoFurniture" class="cate-check" v-if="!isCategoryEditing">
                  <el-checkbox v-for="(item,index) in nofurnitureCategories" :label="item" :key="index"><span class="checkbox-label" @click.stop>{{ item }}</span></el-checkbox>
                </el-checkbox-group>
                <el-checkbox-group v-model="checkedCateListNoFurniture" class="cate-check" v-else>
                  <el-checkbox v-for="(item,index) in nofurnitureCategories" :label="item" :key="index">
                    <el-input  v-model="nofurnitureCategories[index]" class="create-large-el-input"></el-input>
                  </el-checkbox>
                </el-checkbox-group>
                <!-- <ul v-else>
                  <li v-for="(item,index) in nofurnitureCategories" :label="item" :key="index">
                    <el-input type="textarea" :rows="1" v-model="nofurnitureCategories[index]" class="large-textarea"></el-input>
                  </li>
                </ul> -->
                <el-button class="cate-btn" :loading="handleReplaceCategoriesLoading" @click="handleReplaceCategories">Replace Unchecked Categories</el-button>
                <el-button class="next-btn" :loading="fetchContentDetailsLoading" @click="fetchContentDetails">Next</el-button>
              </div>
              <!-- 5生成标题和描述 -->
              <div class="description-wrapper" v-if="activeIndex ==5">
                <div class="link-img">
                  <el-image
                    :src="previewImageUrl"
                    class="preview-image"
                    fit="contain"
                    :preview-src-list="[previewImageUrl]"
                  >
                  </el-image>
                </div>
                <ul class="product-list">
                  <li class="product-item" v-for="(item,index) in dataList"  :key="index">
                    <p class="roduct-cate">{{item.categoryPath}}</p>
                    <p class="product-title">Title:{{item.title}}</p>
                    <div>Bullet Points:</div>
                    <ul class="bullet-list">
                      <li v-for="(point, index2) in item.bulletPoints" :key="index2" >
                        {{ point }}
                      </li>
                    </ul>
                    
                    <p class="product-desc">Description:<br>{{item.description}}</p>
                  </li>
                </ul>
                <el-button class="back-btn"  @click="activeIndex =4">Back</el-button>
                <el-button class="next-btn" :loading="preloadImagesLoading" @click="preloadImages('description_next_click')">Next</el-button>
                
              </div>
              <!--6 生成图片-->
              <div class="description-wrapper" v-if="activeIndex ==6">
                <div class="link-img">
                  <el-image
                    :src="previewImageUrl"
                    class="preview-image"
                    fit="contain"
                    :preview-src-list="[previewImageUrl]"
                  >
                  </el-image>
                </div>
                <ul class="product-list">
                  <li class="product-item" v-for="(item,index) in dataList"  :key="index">
                    <p class="roduct-cate">{{item.categoryPath}}</p>
                    <p class="product-title">Title:{{item.title}}</p>
                    <div>Bullet Points:</div>
                    <ul class="bullet-list">
                      <li v-for="(point, index2) in item.bulletPoints" :key="index2" >
                        {{ point }}
                      </li>
                    </ul>
                    <p class="product-desc">Description:<br>{{item.description}}</p>
                    <div class="clear"></div>
                    <div v-for="(url, index3) in item.imageUrlList" :key="index3"  class="product-image-div">
                      <el-image
                        :src="url.jpegUrl"
                        class="product-list-image"
                        fit="contain"
                        :preview-src-list="[url.jpegUrl]"
                      ></el-image>
                    </div>
                    <!-- <el-button @click="generateImageOne(item)" :loading="generateImageOneLoading">More</el-button> -->
                    <el-button @click="generateImageDialogOne(item)" :loading="generateImageOneLoading">More</el-button>
                  </li>
                </ul>
                
                
                <el-button class="next-btn"  @click="getExcelDataList('description_image_next_click')" :loading="getExcelDataListLoading">Next</el-button>
                <!-- <el-button class="next-btn" :loading="preloadImagesLoading" @click="preloadImages('description_generate_for_all_click')">Generate for All</el-button> -->
                <el-button class="next-btn"  @click="generateImageDialogAll">Generate for All</el-button>
                
              </div>
              <!-- 7Infomation Edit 输入sku 获取物料信息，编辑物料信息-->
              <div class="infoma-wrapper" v-if="activeIndex ==7">
                <div class="infoma-left">
                  <!-- <div class="infoma-left-top">
                    <el-select v-model="selectCateIndex"  @change="chanageSelectCate" class="infoma-left-top-select">
                      <el-option
                        v-for="(item,index) in catelist"
                        :key="index"
                        :label="item"
                        :value="index">
                      </el-option>
                    </el-select>
                  </div> -->
                  <div class="infoma-left-content">
                    <ul>
                      <li class="infoma-left-content-li" v-for="(item,index) in dataList" :key="index">
                          <h3>Product Details 
                            <i class="el-icon-edit-outline" @click="toggleEdit(index)" v-if="!isEditing(index)"></i> 
                            <i class="el-icon-check" v-else @click="toggleEdit(index)"></i>
                          </h3>
                          <div class ="ori-data" v-if="!isEditing(index)">
                            <div class="ori-data-cate">Category: {{item.categoryPath}}</div>
                            <br>
                            <div class="ori-data-title">Title: {{item.title}}</div>
                            <div class="ori-data-dec">Description: {{item.description}}</div>
                            <br>
                            <div>
                              Bullet Points:<br>
                              <ul class="bullet-list">
                                <li v-for="(point, index2) in item.bulletPoints" :key="index2" >
                                  {{ point }}
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class ="ori-data" v-else>
                            <div>Category: <br><el-input type="textarea" :rows="2" v-model="item.categoryPath" class="large-textarea"></el-input></div>
                            <div>Title: <br><el-input type="textarea" :rows="2" v-model="item.title" class="large-textarea"></el-input></div>
                            <div>Description: <br><el-input type="textarea" :rows="5" v-model="item.description" class="large-textarea"></el-input></div>
                            <div>
                              Bullet Points:<br>
                              <ul class="bullet-list">
                                <li v-for="(point, index2) in item.bulletPoints" :key="index2" >
                                  <el-input type="textarea" :rows="2" v-model="item.bulletPoints[index2]" class="large-textarea"></el-input>
                                </li>
                              </ul>
                            </div>
                          </div>
                      </li>
                    </ul>
                    
                  </div>
                </div>
                <div class="infoma-right">
                  <!-- <div class="infoma-right-top">
                    Sku:<el-input v-model="skuNumber"></el-input>
                    <el-button class="enh-btn" @click="getExcelDataList('infomation_edit_auto-fill_click')" :loading="getExcelDataListLoading">Auto-fill</el-button>
                  </div> -->
                  <div class="infoma-right-content">
                    <h3>Attribute Name 
                      <i class="el-icon-edit-outline" @click="editTable" v-if="ifEditExcelFlag == false"></i>
                      <i class="el-icon-check" v-else @click="editExcelDateList"></i>
                    </h3>
                    <!-- <el-table :data="[excelDataList]" stripe  ref="entry" style="width: 100%" :key="tableKey">
                      <template v-for="(item, index) in excelDataList">
                        <el-table-column
                          v-show="item.display"
                          :key="index" 
                          :label="item.title" 
                          :width="item.columnWidth"
                          class="vertical-column">
                          <template v-slot="scope">
                            <div v-if="ifEditExcelFlag == false">
                              <span>{{ scope.row.cellValue }} </span>
                            </div>
                            <div v-else>
                              <el-input v-model="scope.row.cellValue"></el-input>
                            </div>
                          </template>
                        </el-table-column>
                      </template>
                      
                    </el-table> -->
                    <ul class="infoma-right-ul">
                      <li class="infoma-right-ul-li">
                        <div class="infoma-right-ul-li-no">No.</div>
                        <div class="infoma-right-ul-li-name">Name.</div>
                        <div class="infoma-right-ul-li-value">value.</div>
                      </li>
                      <li v-for="(item,index) in excelDataList" :key="index" v-show="item.display" class="infoma-right-ul-li" >
                        <div class="infoma-right-ul-li-no">{{ getVisibleCount(index) }}</div>
                        <div class="infoma-right-ul-li-name">{{item.title}}:</div>
                        <div v-if="ifEditExcelFlag == false" class="infoma-right-ul-li-value">{{item.cellValue}}</div>
                        <div v-else class=""><el-input v-model="item.cellValue"></el-input></div>
                      </li>
                    </ul>
                  </div>
                </div>
                <el-button class="publish-btn" @click="handlePublish">Publish</el-button>
              </div>
              <!-- 8 保存下载 -->
              <div class="listen-wrapper" v-if="activeIndex ==8">
                <div class="link-img">
                  <el-image
                    :src="previewImageUrl"
                    class="preview-image"
                    fit="contain"
                    :preview-src-list="[previewImageUrl]"
                  >
                  </el-image>
                </div>
                <div class="listen-content">
                  <br>
                  <p>Your product listing have been<br>successfully published and saved!</p>
                  <br>
                  <el-button @click="saveAndDownloadZip" :loading="saveAndDownloadZipLoading" class="download-btn">Download File</el-button>
                </div>
                <el-button class="next-btn" @click="backAndClear">Done</el-button>

              </div>

            </div>
        </div>
    </div>
    <!-- 页面加载效果 -->
    <div class="loadingMaskNoBg" v-if="pageloading">
      <div class="progress-container">
        <el-progress type="circle" :percentage="progress" :stroke-width="8"></el-progress>
      </div>
    </div>
    <!-- 页面加载效果 -->

    <!-- 单个图片多角度 -->
    <el-dialog :visible.sync="editlDialogImageVisible" top="3vh" :append-to-body="true" @open="initCanvasDialog" @opened="initImageDialog">
      <div class="canvas-wrapper" >
        <div class="canvasSizeWrapper">
          <div class="platfromSelect-title">Image Size:</div>
          <div>
            <span>W:</span>
            <el-input class="mini-input" placeholder="width" v-model.number="exportImgWidth" type="number" :min="100" :max="5000" @change="chanageExportImgSizeDialog"></el-input><br>
            <span>H:</span>
            <el-input class="mini-input" placeholder="height" v-model.number="exportImgHeight" type="number" :min="100" :max="5000" @change="chanageExportImgSizeDialog"></el-input><br>
          </div>
        </div>
        <div class="canvasMain">
          <canvas id="fabricCanvas" :width="canvasWidth" :height="canvasHeight"  class="canvasDiv"></canvas>
        </div>
        <div class="clear"></div>
      </div>
      
      <!-- 自定义样式的按钮 -->
      <div class="file-and-link">
        <!-- 隐藏的文件输入 -->
        <input type="file" class="uploadFile" @change="handleFileChangeDialog" ref="fileInput" />
        <button class="upload-button" @click="triggerFileInput">upload image</button>
        <span class="upload-span"> or </span>
        <el-input v-model="productLink" class="link-input" placeholder="https://" @change="chanageProductLinkDialog"></el-input>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitCanvasDialog" :loading="submitCanvasDialogLoading">Generate</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
const BASE_URL = "http://192.168.1.14:8000";
import axios from 'axios';
import * as fabric from 'fabric';
import { Canvas, FabricImage } from 'fabric';
import * as DownloadUtils from '@/utils/DownloadUtils.js';
export default {
  
  data(){
    return{
      activeIndex: 1, // 记录当前激活的列表项
      pageloading: false,
      progress: 0,
      progressInterval:null,
      dataList: [],
      egDatalist:[],
      allData:{},
      count:0,
      tableKey: 0,
      platformList:[
        {
          "title":"Walmart",
          "value": "Walmart",
          "showFlag": false,
          "selectSiteList":["US","CA"]
        },
        {
          "title":"Walmart_DSV",
          "value": "Walmart DSV",
          "showFlag": false,
          "selectSiteList":["US"]
        },
        {
          "title":"Amazon",
          "value": "Amazon",
          "showFlag": false,
          "selectSiteList":["US","CA","MX","DE","FR","UK"]
        },
        {
          "title":"Amazon_Vendor",
          "value": "Amazon Vendor",
          "showFlag": false,
          "selectSiteList":["US","CA","DE","FR","UK"]
        },
        {
          "title":"Temu",
          "value": "Temu",
          "showFlag": false,
          "selectSiteList":["ES","IT","FR","DE"]
        }
      ],
      spanIndex: null,
      productLink:null,
      selectedPlatform: null,
      slectSite: null,
      selectCateIndex: null,
      selectCateEditData:{},
      ifEditExcelFlag: false,
      uploadedFile: null,
      uploadedImage: null, // 用户上传的图片
      uploadedImageUrl: null,//画布上传后链接
      previewImageUrl:null,//预览图片
      furnitureCategories:[],
      nofurnitureCategories: [],
      checkedCateList:[],
      checkedCateListNoFurniture:[],
      step4categories:[],
      titles:[],
      descriptions:[],
      imageLinks:[],
      handleLinkSubmitLoading: false,
      handleReplaceCategoriesLoading:false,
      fetchContentDetailsLoading: false,
      preloadImagesLoading: false,
      getExcelDataListLoading: false,
      canvas: null,
      downloadCanvasUrl: null,
      canvasWidth: 600,
      canvasHeight: 600,
      exportImgWidth: 2000,
      exportImgHeight: 2000,
      exportImgWidthDialog: 2000,
      exportImgHeightDialog: 2000,
      canvasSizeList:[
        {
        "title":"1:1 Square Chart",
        "width": 600,
        "height": 600
        },
        {
        "title":"3:4 Square Chart",
        "width": 450,
        "height": 600
        }
      ],
      canvasSizeIndex:0,
      submitCanvasLoading: false,
      editableIndices: [], // 用于存储处于编辑状态的索引
      excelDataList:[],
      saveAndDownloadZipLoading: false,
      skuNumber: '',
      generateImageOneLoading: false,
      editlDialogImageVisible: false,
      generateImageDialogFlag: null,
      generateImageDialogData: null,
      submitCanvasDialogLoading: false,
      isCategoryEditing: false,
      brandName: null,
      checkSkuExistsMsg: null,
      checkSkuExistsFlag: false,
      handleLinkSubmitDisable: false,
      errorMessage:null,
      originalImageUrl: null,//是用户上传原始那张图的url
      productImageWidth: null,//是用户在第一次抠图那里用宽和高
      productImageHeight: null,//是用户在第一次抠图那里用宽和高
      mainData:{
        productListingList:[]
      },
    }
  },
  methods: {
    
    setActive(index) {
        this.activeIndex = index; // 设置当前激活的索引
    },
    setSelectedPlatform(platform,index){
      this.selectedPlatform = platform.value; // 将选中的平台赋值给 selectedPlatform
      //埋点
      var logStr = "select_platfrom_"+ platform.value + "_click";
      this.addTrackingLog(logStr);
      //设置其他平台showFlag都为false
      for(var i = 0; i < this.platformList.length;i++){
        this.platformList[i].showFlag = false;
      }
      // 再显示选择平台的site
      this.platformList[index].showFlag = true; 
      
    },
    changeSiteSpan(index,index2){
        //选单个
        this.spanIndex = index2;
        var sites = this.platformList[index].selectSiteList;
        this.slectSite = sites[index2];
        this.activeIndex = 2; //跳至第二步     
    },
    //第7步，切换分类，编辑数据
    chanageSelectCate(){
      this.selectCateIndex;
      this.selectCateEditData;
    },
    chanageProductLink(){
      this.previewImageUrl = this.productLink;
      this.uploadedFile = null; //清空选择文件
    },
    checkSkuExists(){
      this.checkSkuExistsMsg = null;
      this.checkSkuExistsFlag = false;
      this.handleLinkSubmitDisable = true;
      if(!this.skuNumber){
        return;
      }
      this.$axios.post('/productPublish/xaz/checkSkuExists?skuNumber=' + this.skuNumber).then(async result => {
        
        if (!result.flag) {
          this.checkSkuExistsMsg = result.msg;
          this.handleLinkSubmitDisable = false;
          return;
        }else{
          this.checkSkuExistsFlag = true;
          this.handleLinkSubmitDisable = false;
        }
      })
    },
    // 触发隐藏的文件选择输入
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      // 获取用户选择的文件
      this.productLink = null ; //清空输入框链接
      //埋点
      this.addTrackingLog("input_product_link_upload_image_click");
      // 获取用户选择的文件
      const file = event.target.files[0];
      this.uploadedFile = file;
      if (file) {
        if (file.type.startsWith('image/')) {
          const reader = new FileReader();
          // 读取文件
          reader.onload = (e) => {
            // 创建一个新的 Image 对象，检查尺寸
            const img = new Image();
            img.onload = () => {
              // 检查图像尺寸
              if (img.width > 5000 || img.height > 5000) {
                this.previewImageUrl = ''; // 清除预览
                this.$message.error("The uploaded image size cannot exceed 5000*5000. Please re-upload.");
              } else {
                // 设置预览图像的 URL
                this.previewImageUrl = e.target.result;
              }
            };
            img.src = e.target.result; // 设置 Image 对象的源为读取的文件
          };
          // 开始读取文件
          reader.readAsDataURL(file);
        } else {
          // 选择的不是图像文件，提示失败信息
          this.previewImageUrl = ''; // 清除预览
          this.uploadedFile = null;
          this.$message.error("请选择一个有效的图像文件。");
        }
      }
    },
    //第二步提交url，下载图片到画布
    async handleLinkSubmit(){
      var _this = this;
      //校验必填
      _this.errorMessage = null;
      await _this.validateLinkForm();
      if(_this.errorMessage){
        _this.$message.error(_this.errorMessage); 
        return;
      }
      //埋点
      _this.addTrackingLog("input_product_link_submit_click");
      _this.handleLinkSubmitLoading = true;
      //输入框URL 模式
      if(_this.productLink != null && _this.productLink !=''){
          const urlPattern = /^(https?:\/\/.*\.(?:jpg|jpeg|png|gif|bmp|webp))$/i; // URL 正则表达式
          // 检查 URL 格式
          if (!urlPattern.test(this.productLink)) {
            _this.previewImageUrl = null; // 清除预览
            _this.handleLinkSubmitLoading = false;
            _this.$message.error('Please enter a valid image URL.'); 
            return;
            
          }
          this.originalImageUrl = this.productLink; //是用户上传原始那张图的url
          //先抠图
          const segmentResponse = await _this.segmentImage(_this.productLink);
          if (!segmentResponse.flag) {
            _this.$message.error(segmentResponse.msg);
            _this.handleLinkSubmitLoading = false;
            return;
          }

          //获取抠图后的png url 下载，渲染到画布
          var png = segmentResponse.imageUrl.pngUrl;
          if(png){
            const downloadResponse = await _this.downloadImageByUrl(png);
            _this.downloadCanvasUrl = URL.createObjectURL(downloadResponse);
            
            _this.$nextTick(() => {
              _this.initCanvas();
              _this.handleLinkSubmitLoading = false;
            })
            _this.activeIndex = 3; //跳至第3步,编辑画布
          }
      }else{//文件上传
          // 上传画布，获取图片链接
          if (_this.uploadedFile) {
              try {
                const uploadResponse = await _this.uploadImage();
                if (!uploadResponse.flag) {
                  _this.$message.error(uploadResponse.msg);
                  _this.handleLinkSubmitLoading = false;
                  return;
                }
                _this.uploadedImageUrl = uploadResponse.url;
                _this.previewImageUrl = uploadResponse.url;
                _this.originalImageUrl = uploadResponse.url; //是用户上传原始那张图的url
              } catch (error) {
                _this.$message.error(error);
                return;
              } 
          }
          //抠图
          const segmentResponse = await _this.segmentImage(_this.uploadedImageUrl);
          if (!segmentResponse.flag) {
            _this.$message.error(segmentResponse.msg);
            _this.handleLinkSubmitLoading = false;
            return;
          }

          //获取抠图后的png url 下载，渲染到画布
          var png = segmentResponse.imageUrl.pngUrl;
          if(png){
            const downloadResponse = await _this.downloadImageByUrl(png);
            _this.downloadCanvasUrl = URL.createObjectURL(downloadResponse);
            
            _this.$nextTick(() => {
              _this.initCanvas();
              _this.handleLinkSubmitLoading = false;
            })
            _this.activeIndex = 3; //跳至第3步,编辑画布
          }
      }
    },
    validateLinkForm() {
      // Reset error message
      this.errorMessage = '';
      // Check for required fields
      if (!this.brandName) {
        this.errorMessage = 'Please enter brand name';
        return;
      }
      if (!this.productLink && !this.uploadedFile) {
        this.errorMessage = 'Please select picture or enter link';
        return;
      }
    },
    //抠图
    async segmentImage(productLink) {
      return this.$axios.get('/productPublish/xaz/segmentImage', {
        params: {
          url: productLink,
          platform: this.selectedPlatform
        }
      });
    },
    //下载图
    async downloadImageByUrl(url) {
      return this.$axios.get('/productPublish/xaz/downloadByUrl', {
        params: {
          url: url,
        },
        responseType: 'blob' // 期望返回的类型是 Blob
      });
    },
    //上传图片
    async uploadImage() {
      var formData = new FormData();
      if(this.uploadedFile.raw){
        formData.append("file", this.uploadedFile.raw);
      }else{
        formData.append("file", this.uploadedFile);
      }
      formData.append("platform", this.selectedPlatform);
      return this.$axios.post('/productPublish/xaz/uploadFile', formData);
    },
    //导出图片尺寸改变
    chanageExportImgSize(){
      this.exportImgWidth = this.exportImgWidth || 2000;
      this.exportImgHeight = this.exportImgHeight || 2000;

      if(this.exportImgWidth && this.exportImgWidth > 5000){
        this.$message.error("“Exceeded the maximum image limit of 5000×5000"); 
        return;
      }
      if(this.exportImgHeight && this.exportImgHeight > 5000){
        this.$message.error("“Exceeded the maximum image limit of 5000×5000"); 
        return;
      }
      
      //保持画布高度600不变，宽度按照输入图片的尺寸缩放
      if (typeof this.exportImgWidth === 'string' && this.exportImgWidth.trim() !== '') {
        // 使用 parseInt 转换字符串为整数
        this.exportImgWidth = parseInt(this.exportImgWidth, 10);
      }
      if (typeof this.exportImgHeight === 'string' && this.exportImgHeight.trim() !== '') {
        // 使用 parseInt 转换字符串为整数
        this.exportImgHeight = parseInt(this.exportImgHeight, 10);
      }
      if (typeof this.canvasWidth === 'string' && this.canvasWidth.trim() !== '') {
        // 使用 parseInt 转换字符串为整数
        this.canvasWidth = parseInt(this.canvasWidth, 10);
      }
      if (typeof this.canvasHeight === 'string' && this.canvasHeight.trim() !== '') {
        // 使用 parseInt 转换字符串为整数
        this.canvasHeight = parseInt(this.canvasHeight, 10);
      }
      if(this.exportImgWidth > this.exportImgHeight){//如果宽度大于高度，则画布以600宽度为基准算画布高度
        var scaleX = 600/this.exportImgWidth;
        var coutY = this.exportImgHeight * scaleX;
        this.canvasHeight = coutY;
        this.canvasWidth = 600;
        
      }else{//如果宽度小于高度，则画布以600高度为基准算画布宽度
        var scaleY = 600/this.exportImgHeight;
        var coutX = this.exportImgWidth * scaleY;
        this.canvasWidth = coutX;
        this.canvasHeight = 600;
      }
      
      this.chanageCanvasSize();
    },
    //画布尺寸改变
    chanageCanvasSize(val){
      // this.canvasWidth = this.canvasSizeList[val].width;
      // this.canvasHeight = this.canvasSizeList[val].height;
      // 更新 Fabric.js canvas 尺寸
      this.canvas.setWidth(this.canvasWidth);
      this.canvas.setHeight(this.canvasHeight);

      // 先清除画布
      if (this.canvas !== null) {
          this.canvas.clear(); // 清空画布上的所有对象
          this.canvas.dispose();
      }
      this.canvas = null;
      this.initCanvas();
      console.log("执行了更新画布")
    },
    //canvas 图片编辑
    initCanvas() {
      if(this.canvas != null) return;
      this.$nextTick(() => {
        this.canvas = new fabric.Canvas('fabricCanvas', {
          targetFindTechnique: 'nearest',//创建Canvas时设置DPI
          perPixelTargetFind: true,// 创建Canvas时设置DPI
          enableRetinaScaling:false, // 或者 false，视具体情况而定
          width: this.canvasWidth,
          height: this.canvasHeight,
          
        });
        this.initImage(this.downloadCanvasUrl);
      })
    },
    initImage(dataUrl) {
      var _this = this;
      if (dataUrl) {
          fabric.FabricImage.fromURL(dataUrl).then((img) => {
            _this.canvas.add(img);

            //初始化原图缩放到画布大小的90%
            // 获取画布的宽度和高度
            var canvasWidth = _this.canvas.width;
            var canvasHeight = _this.canvas.height;

            // 计算缩放比例（90%）
            var scaleX = (canvasWidth * 0.9) / img.width;
            var scaleY = (canvasHeight * 0.9) / img.height;

            // 选择最小比例，以保持纵横比
            var scale = Math.min(scaleX, scaleY);
            // 缩放图像
            img.scale(scale);

            img.set({
              imageSmoothingEnabled: false,// 关闭图片插值，确保图片在拉宽时不会出现重影
              lockUniScaling: true, // 锁定宽高比，使其只能等比例缩放
              hasControls: true,    // 允许使用控制点
              hasBorders: true,
              left: (canvasWidth - img.width * scale) / 2,
              top: (canvasHeight - img.height * scale) / 2
            });
            // 监听缩放事件（可选，提升用户体验）
            _this.canvas.on('object:scaling', function(e) {
                var obj = e.target;
                obj.setControlsVisibility({
                    mt: false, // 不显示上中点
                    mb: false, // 不显示下中点
                    ml: false, // 不显示左中点
                    mr: false, // 不显示右中点
                    bl: true, // 显示左下点
                    br: true, // 显示右下点
                    tl: true, // 显示左上点
                    tr: true  // 显示右上点
                });
                // 同步 scaleX 和 scaleY 以保持宽高比
                obj.scaleY = obj.scaleX; 
            });
            _this.canvas.renderAll();
            
          }, { crossOrigin: 'anonymous' });// 处理跨域请求
      }

    },
    //提交画布，获取title 和 详情
    async submitCanvas(){
      var _this = this;
      //埋点
      _this.addTrackingLog("edit_image_next_click");
      // 获取用户选择的文件
      _this.submitCanvasLoading = true;
      //保存 用户在第一次抠图那里用宽和高
      _this.productImageWidth = this.exportImgWidth;
      _this.productImageHeight = this.exportImgHeight;
      // 获取画布的 Data URL
      // 计算目标宽高的比例
      if (typeof this.exportImgWidth === 'string' && this.exportImgWidth.trim() !== '') {
        // 使用 parseInt 转换字符串为整数
        this.exportImgWidth = parseInt(this.exportImgWidth, 10);
      }
      if (typeof this.exportImgHeight === 'string' && this.exportImgHeight.trim() !== '') {
        // 使用 parseInt 转换字符串为整数
        this.exportImgHeight = parseInt(this.exportImgHeight, 10);
      }
      if (typeof this.canvasWidth === 'string' && this.canvasWidth.trim() !== '') {
        // 使用 parseInt 转换字符串为整数
        this.canvasWidth = parseInt(this.canvasWidth, 10);
      }
      if (typeof this.canvasHeight === 'string' && this.canvasHeight.trim() !== '') {
        // 使用 parseInt 转换字符串为整数
        this.canvasHeight = parseInt(this.canvasHeight, 10);
      }
      const canvasAspect = _this.canvasWidth / _this.canvasHeight;
      const outputAspect = _this.exportImgWidth / _this.exportImgHeight;
      let scale;
      if (outputAspect > canvasAspect) {
          // 如果输出宽高比更宽，按宽度缩放
          scale = _this.exportImgWidth / _this.canvasWidth;
      } else {
          // 如果输出宽高比更高，按高度缩放
          scale = _this.exportImgHeight / _this.canvasHeight;
      }
      let backgroundColor = _this.canvas.backgroundColor;
      _this.canvas.backgroundColor = 'rgba(255, 255, 255, 1)';

      const dataUrl = _this.canvas.toDataURL({
        quality: 0.7,
        format: 'jpeg',
        multiplier: scale // 设置图像质量导出
      });
      _this.canvas.backgroundColor = backgroundColor;

      // 转换 Data URL 为 Blob
      const response = await fetch(dataUrl);
      if (!response.ok) throw new Error('Network response was not ok');
      const blob = await response.blob();
      // 创建 File 对象
      var file = new File([blob], 'canvas-image.jpg', { type: 'image/jpeg' });
      _this.uploadedFile = file;
      _this.uploadedImage = URL.createObjectURL(file);
      _this.previewImageUrl = URL.createObjectURL(file);
      
      
      // 上传画布，获取图片链接
      if (_this.uploadedFile) {
          try {
            const uploadResponse = await _this.uploadImage();
            if (!uploadResponse.flag) {
              _this.$message.error(uploadResponse.msg);
              return;
            }
            _this.uploadedImageUrl = uploadResponse.url;
            _this.previewImageUrl = uploadResponse.url;
            _this.$message.success("success");
          } catch (error) {
            _this.$message.error(error);
            return;
          } finally {
            _this.submitCanvasLoading = false;
          }
      }
      //请求接口 获取类目
      _this.generateCategories();
      
    },
    async generateCategories() {
      this.pageloading = true;
      this.progress = 0;
      //每2秒进度条增加5
      this.progressInterval = setInterval(async () => {
        this.progress += 5; // 增加进度
        if (this.progress >= 90) {
          this.progress = 90; // 防止超过100
        }
      }, 3500);
      try {
        // 发送 GET 请求
        var dataMp = {
            platform: this.selectedPlatform,
            site: this.slectSite,
            url: this.uploadedImageUrl,
            furnitureCount: 20,
            notFurnitureCount: 5,
            excludedFurnitureCategories: this.furnitureCategories,
            excludedNotFurnitureCategories : this.nofurnitureCategories
        }
        const response = await this.$axios.post('/productPublish/xaz/generateCategories', dataMp);
        if (!response.flag) {
          this.$message.error(response.msg);
          return;
        }
        // 处理成功的响应
        if (response.categories) {
          this.furnitureCategories = response.categories[0]; 
          this.nofurnitureCategories = response.categories[1]; 
          this.progress = 100;
          clearInterval(this.progressInterval); // 停止定时器
          this.activeIndex = 4; //跳至第4步
          
        }
      } catch (error) {
        // 处理错误
        this.$message.error(error); 
      } finally {
        this.submitCanvasLoading = false;
        this.pageloading = false;
        
      }
    },
    //类目刷新
    async handleReplaceCategories(){
      //埋点
      this.addTrackingLog("view_categories_replace_unchecked_click");
      //计算要更新的条数
      var furnitureCount = 5 - this.checkedCateList.length;
      var notFurnitureCount = 5 - this.checkedCateListNoFurniture.length;
      this.pageloading = true;
      this.handleReplaceCategoriesLoading = true;
      this.progress = 0;
      //每2秒进度条增加5
      this.progressInterval = setInterval(async () => {
        this.progress += 5; // 增加进度
        if (this.progress >= 90) {
          this.progress = 90; // 防止超过100
        }
      }, 3500);
      try {
        // 发送 GET 请求
        var dataMp = {
            platform: this.selectedPlatform,
            site: this.slectSite,
            url: this.uploadedImageUrl,
            furnitureCount: 0,//基础类不更新，改成0
            notFurnitureCount: notFurnitureCount,
            excludedFurnitureCategories: this.furnitureCategories,
            excludedNotFurnitureCategories : this.nofurnitureCategories
        }
        const response = await this.$axios.post('/productPublish/xaz/generateCategories', dataMp);
        if (!response.flag) {
          this.$message.error(response.msg);
          return;
        }
        // 处理成功的响应
        if (response.categories) {
          //返回新的分类追加到已选择的分类里
          const mergedArrayFurniture = this.checkedCateList.concat(response.categories[0]);
          const mergedArrayNofurniture = this.checkedCateListNoFurniture.concat(response.categories[1]);
          //this.furnitureCategories = mergedArrayFurniture; 
          this.nofurnitureCategories = mergedArrayNofurniture; 
          this.progress = 100;
          clearInterval(this.progressInterval); // 停止定时器
          this.activeIndex = 4; //跳至第4步
          
        }
      } catch (error) {
        // 处理错误
        this.$message.error(error); 
      } finally {
        
        this.handleReplaceCategoriesLoading = false;
        this.pageloading = false;
        
      }
    },
    //第5步获取title 和descrition
    async fetchContentDetails(){
      if(this.checkedCateList.length ==0 && this.checkedCateListNoFurniture.length ==0){
        this.$message.error("Please select at least one category！");
        return;
      }
      //埋点
      this.addTrackingLog("view_categories_next_click");
      this.fetchContentDetailsLoading = true;
      this.pageloading = true;
      this.progress = 0;
      //每2秒进度条增加5
      this.progressInterval = setInterval(async () => {
        this.progress += 5; // 增加进度
        if (this.progress >= 90) {
          this.progress = 90; // 防止超过100
        }
      }, 5000);
      
      // var combinedCateList = this.checkedCateList.concat(this.checkedCateListNoFurniture);
      var dataMap={
        "platform": this.selectedPlatform,
        "site": this.slectSite,
        "productImageUrl": this.uploadedImageUrl,
        "furnitureCategories": this.checkedCateList,
        "notFurnitureCategories": this.checkedCateListNoFurniture,
        "brandName": this.brandName,
        "skuNumber": this.skuNumber
      }
      this.$axios.post('/productPublish/xaz/generateContent', dataMap).then(async result => {
        if (!result.flag) {
          this.$message.error(result.msg);
          this.fetchContentDetailsLoading = false;
          this.pageloading = false;
          return;
        }
        this.dataList = result.resultList;
        this.progress = 100;
        clearInterval(this.progressInterval); // 停止定时器
        this.pageloading = false;
        this.fetchContentDetailsLoading = false;
        this.activeIndex = 5; //跳至第5步
        //创建对象
        this.createProductPublish();
      })
      
      
    },
    //第6步获取图片
    async preloadImages(logStr) {
        if(this.dataList.length ==0){
          this.$message.error("No Data！");
          return;
        }
        //埋点
        this.addTrackingLog(logStr);
        this.preloadImagesLoading = true;
        this.pageloading = true;
        this.progress = 0;
        this.count = 0; // Initialize count
        
        const promises = this.dataList.map((item) => this.generateImage(item));

        // Wait for all generateImage promises to resolve
        await Promise.all(promises);
        
        this.preloadImagesLoading = false;
        this.pageloading = false;
        this.activeIndex = 6; // Or any logic you want for completing the process
        
        this.updateProductPublish();
    },
    generateImageOne(data){
        //埋点
        this.addTrackingLog("description_more_click");
        this.generateImageOneLoading = true;
        this.progress = 0;
        this.count = 0; // Initialize count

        this.generateImage(data);
        
    },
    async generateImage(data) {
        const dataMap = {
            "category": data.categoryPath,
            "imageUrl": this.uploadedImageUrl,
            "n": 1,
            "platform":this.selectedPlatform
        };
        try {
            const result = await this.$axios.post('/productPublish/xaz/generateImage', dataMap);
            this.count++;
            console.log(" this.count", this.count);

            let percent = Math.ceil((this.count / this.dataList.length) * 100);
            this.progress = percent;

            if (!result.flag) {
                this.$message.error(result.msg);
                return; // Early exit on error
            }
            if(data.imageUrlList == null) {
              data.imageUrlList = result.imageUrlList;
            }else {
              data.imageUrlList.push(...result.imageUrlList);
              this.generateImageOneLoading = false;
            }
            
        } catch (error) {
            console.error("Error generating image:", error);
            this.$message.error('Failed to generate image');
        }
    },
    getExcelDataList(logStr){
        //埋点
        this.addTrackingLog(logStr);
        //更新对象
        this.updateProductPublish();
        this.getExcelDataListLoading = true;
        var dataMap={
          "platform": this.selectedPlatform,
          "site": this.slectSite,
          "excelDataList": this.excelDataList,
          "skuNumber": this.skuNumber
        }
        this.$axios.post('/productPublish/xaz/getExcelDataList', dataMap).then(async result => {
          
          if (!result.flag) {
            this.$message.error(result.msg);
            this.getExcelDataListLoading = false;
            return;
          }
          this.excelDataList = result.excelDataList;
          this.activeIndex = 7; //跳至第7步
          this.getExcelDataListLoading = false;
          
        })
    },
    // 切换编辑状态
    toggleEdit(index) {
      const idx = this.editableIndices.indexOf(index);
      if (idx > -1) {
        // 如果已存在，则移除该索引，表示不编辑
        this.editableIndices.splice(idx, 1);
      } else {
        // 否则，添加该索引，表示进入编辑状态
        this.editableIndices.push(index);
      }
    },
    // 检查该项是否在编辑状态
    isEditing(index) {
      return this.editableIndices.includes(index);
    },
    editExcelDateList(){
      //把ExcelDateList 循环塞进 dataList
      for(var i = 0; i < this.dataList.length;i++){
        this.dataList[i].excelDataList = this.excelDataList;
      }
      this.ifEditExcelFlag  = false;
    },
    getVisibleCount(index) {
      // 计数器，初始化为0
      let count = 0;
      // 遍历整个 excelDataList
      for (let i = 0; i <= index; i++) {
        // 检查当前项的 display 属性
        if (this.excelDataList[i].display) {
          count++;
        }
      }
      return count;
    },
    handlePublish(){
      //埋点
      this.addTrackingLog("infomation_publish_click");
      this.activeIndex = 8;
    },
    saveAndDownloadZip(){
        //埋点
        this.addTrackingLog("listing_infomation_download_file_click");
        // for(var i = 0; i < this.dataList.length;i++){
        //   this.dataList[i].excelDataList = this.excelDataList;
        // }
        this.saveAndDownloadZipLoading = true;
        var dataMap={
          "id": this.mainData.id,
          "status": this.mainData.status,
          "platform": this.selectedPlatform,
          "site": this.slectSite,
          "productImageUrl": this.uploadedImageUrl,
          "productListingList": this.dataList,
          "brandName": this.brandName,
          "skuNumber": this.skuNumber,
          "originalImageUrl" : this.originalImageUrl,
          "productImageWidth": this.productImageWidth,
          "productImageHeight": this.productImageHeight,
          "excelDataList": this.excelDataList
        }
        this.$axios.post('/productPublish/xaz/saveAndDownloadZip', dataMap,{responseType:'blob'}).then(async result => {
          
          if (result.response.headers.flag ==='false' ) {
            const msg = await result.text();
            this.$message.error(msg);
            return;
          }
          var date = new Date();
          var dateStr = this.formatDate(date);
          var fileName = this.selectedPlatform + "_" + this.slectSite + "_" + dateStr + ".zip"

          DownloadUtils.download(result,fileName);
        })
        .finally(() =>{
          this.saveAndDownloadZipLoading = false;
        });
    },
    createProductPublish(){
        var dataMap={
          "platform": this.selectedPlatform,
          "site": this.slectSite,
          "productImageUrl": this.uploadedImageUrl,
          "productListingList": this.dataList,
          "brandName": this.brandName,
          "skuNumber": this.skuNumber,
          "originalImageUrl" : this.originalImageUrl,
          "productImageWidth": this.productImageWidth,
          "productImageHeight": this.productImageHeight,
          "excelDataList": this.excelDataList,
          "productListingList": this.dataList,
        }
        this.$axios.post('/productPublish/xaz/createProductPublish', dataMap).then(async result => {
          
          if (!result.flag) {
            this.$message.error(msg);
            return;
          }else{
            this.mainData = result.productPublish;
            this.dataList = this.mainData.productListingList;

            this.$message.success("Success");
          }
          
        })
        .finally(() =>{
          
        });
    },
    updateProductPublish(){
      var dataMap={
          "id": this.mainData.id,
          "status": this.mainData.status,
          "platform": this.selectedPlatform,
          "site": this.slectSite,
          "productImageUrl": this.uploadedImageUrl,
          "productListingList": this.dataList,
          "brandName": this.brandName,
          "skuNumber": this.skuNumber,
          "originalImageUrl" : this.originalImageUrl,
          "productImageWidth": this.productImageWidth,
          "productImageHeight": this.productImageHeight,
          "excelDataList": this.excelDataList,
          "productListingList": this.dataList,
        }
        this.$axios.post('/productPublish/xaz/updateProductPublish', dataMap).then(async result => {
          
          if (!result.flag) {
            this.$message.error(msg);
            return;
          }else{
            this.mainData = result.productPublish;
            this.dataList = this.mainData.productListingList;

            this.$message.success("Success");
          }
          
        })
        .finally(() =>{
          
        });
    },
    editTable() {
      this.ifEditExcelFlag = true;
    },
    backAndClear() {
      //埋点
      this.addTrackingLog("listing_infomation_done_click");
      this.productLink = null;
      this.uploadedFile = null;
      this.uploadedImageUrl = null;
      this.previewImageUrl = null;
      this.furnitureCategories = [];
      this.checkedCateList = [];
      this.nofurnitureCategories = [];
      this.checkedCateListNoFurniture = [];
      this.dataList = [];
      this.excelDataList = [];
      // 先清除画布
      if (this.canvas !== null) {
          this.canvas.clear(); // 清空画布上的所有对象
          this.canvas.dispose();
      }
      this.canvas = null;
      this.editableIndices = [];
      this.ifEditExcelFlag = false;
      //设置其他平台showFlag都为false
      for(var i = 0; i < this.platformList.length;i++){
        this.platformList[i].showFlag = false;
      }
      this.selectedPlatform = null;
      this.selectedPlatformIndex = null;
      this.spanIndex = null;
      this.slectSite = null;
      this.skuNumber = null;
      this.isCategoryEditing = false;
      this.activeIndex = 1; //跳至第1步
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${year}${month}${day}_${hours}${minutes}${seconds}`;
    },
    //埋点
    addTrackingLog(eid){
      this.$axios.get('/productPublish/xaz/addTrackingLog', {
        params: {
          e: eid,
        }
      });
    },
    //图片多角度 单个
    generateImageDialogOne(data){
      // 先清除画布
      // if (this.canvas !== null) {
      //     this.canvas.clear(); // 清空画布上的所有对象
      //     //this.canvas.dispose();
      //     this.canvas = null;
      // }
      this.editlDialogImageVisible = true;
      this.generateImageDialogFlag = 1; //1 是单个
      this.generateImageDialogData = data;
      
    },
    //图片多角度 单个
    generateImageDialogAll(){
      // 先清除画布
      // if (this.canvas !== null) {
      //     this.canvas.clear(); // 清空画布上的所有对象
      //     //this.canvas.dispose();
      //     this.canvas = null;
      // }
      this.editlDialogImageVisible = true;
      this.generateImageDialogFlag = 2;
      
    },
    //canvas 图片编辑
    initCanvasDialog() {
      if(this.canvas != null){
        this.canvas.clear(); // 清空画布上的所有对象
      }
      this.$nextTick(() => {
        this.canvas = new fabric.Canvas('fabricCanvas', {
          targetFindTechnique: 'nearest',//创建Canvas时设置DPI
          perPixelTargetFind: true,// 创建Canvas时设置DPI
          enableRetinaScaling:false, // 或者 false，视具体情况而定
          width: this.canvasWidth,
          height: this.canvasHeight,
          
        });
      })
    },
    initImageDialog(){
      this.initImage(this.downloadCanvasUrl);
    },
    handleFileChangeDialog(event) {
      // 获取用户选择的文件
      this.productLink = null ; //清空输入框链接
      //埋点
      this.addTrackingLog("input_product_link_upload_image_click");
      // 获取用户选择的文件
      const file = event.target.files[0];
      this.uploadedFile = file;
      if (file) {
        if (file.type.startsWith('image/')) {
          const reader = new FileReader();
          // 读取文件
          reader.onload = (e) => {
            // 创建一个新的 Image 对象，检查尺寸
            const img = new Image();
            img.onload = () => {
              // 检查图像尺寸
              if (img.width > 5000 || img.height > 5000) {
                this.previewImageUrl = ''; // 清除预览
                this.$message.error("The uploaded image size cannot exceed 5000*5000. Please re-upload.");
              } else {
                // 设置预览图像的 URL
                this.previewImageUrl = e.target.result;
              }
            };
            img.src = e.target.result; // 设置 Image 对象的源为读取的文件
          };
          // 开始读取文件
          reader.readAsDataURL(file);
        } else {
          // 选择的不是图像文件，提示失败信息
          this.previewImageUrl = ''; // 清除预览
          this.uploadedFile = null;
          this.$message.error("请选择一个有效的图像文件。");
        }
      }

      //文件上传后渲染画布
      this.handleLinkSubmitDialog();
    },
    chanageProductLinkDialog(){
      this.previewImageUrl = this.productLink;
      this.uploadedFile = null; //清空选择文件
      //文件上传后渲染画布
      this.handleLinkSubmitDialog();
    },
    //第下载图片到画布
    async handleLinkSubmitDialog(){
      var _this = this;
      _this.pageloading = true;
      _this.progress = 0;
      //每2秒进度条增加5
      _this.progressInterval = setInterval(async () => {
        _this.progress += 5; // 增加进度
        if (_this.progress >= 90) {
          _this.progress = 90; // 防止超过100
        }
      }, 3500);
      _this.handleLinkSubmitLoading = true;
      //输入框URL 模式
      if(_this.productLink != null && _this.productLink !=''){
          //先抠图
          const segmentResponse = await _this.segmentImage(_this.productLink);
          if (!segmentResponse.flag) {
            _this.$message.error(segmentResponse.msg);
            _this.pageloading = false;
            clearInterval(_this.progressInterval); // 停止定时器
            return;
          }

          //获取抠图后的png url 下载，渲染到画布
          var png = segmentResponse.imageUrl.pngUrl;
          if(png){
            const downloadResponse = await _this.downloadImageByUrl(png);
            _this.downloadCanvasUrl = URL.createObjectURL(downloadResponse);
            
            _this.$nextTick(() => {
              _this.initCanvasDialog();
              _this.initImageDialog(_this.downloadCanvasUrl);
              _this.handleLinkSubmitLoading = false;
            })
            
          }
          
          _this.progress = 100;
          _this.pageloading = false;
          clearInterval(_this.progressInterval); // 停止定时器
      }else{//文件上传
          // 上传画布，获取图片链接
          if (_this.uploadedFile) {
              try {
                const uploadResponse = await _this.uploadImage();
                if (!uploadResponse.flag) {
                  _this.$message.error(uploadResponse.msg);
                  _this.pageloading = false;
                  clearInterval(_this.progressInterval); // 停止定时器
                  return;
                }
                _this.uploadedImageUrl = uploadResponse.url;
                _this.previewImageUrl = uploadResponse.url;
              } catch (error) {
                _this.$message.error(error);
                return;
              } 
          }
          //抠图
          const segmentResponse = await _this.segmentImage(_this.uploadedImageUrl);
          if (!segmentResponse.flag) {
            _this.$message.error(segmentResponse.msg);
            _this.pageloading = false;
            clearInterval(_this.progressInterval); // 停止定时器
            return;
          }

          //获取抠图后的png url 下载，渲染到画布
          var png = segmentResponse.imageUrl.pngUrl;
          if(png){
            const downloadResponse = await _this.downloadImageByUrl(png);
            _this.downloadCanvasUrl = URL.createObjectURL(downloadResponse);
            
            _this.$nextTick(() => {
              _this.initCanvasDialog();
              _this.initImageDialog(_this.downloadCanvasUrl);
              _this.handleLinkSubmitLoading = false;
            })
            
          }
          
          _this.progress = 100;
          _this.pageloading = false;
          clearInterval(_this.progressInterval); // 停止定时器
      }
    },

    //提交弹框画布，获取多角度图片
    async submitCanvasDialog(){
      var _this = this;
      // 获取用户选择的文件
      _this.submitCanvasDialogLoading = true;
      // 获取画布的 Data URL
      // 计算目标宽高的比例
      if (typeof this.exportImgWidth === 'string' && this.exportImgWidth.trim() !== '') {
        // 使用 parseInt 转换字符串为整数
        this.exportImgWidth = parseInt(this.exportImgWidth, 10);
      }
      if (typeof this.exportImgHeight === 'string' && this.exportImgHeight.trim() !== '') {
        // 使用 parseInt 转换字符串为整数
        this.exportImgHeight = parseInt(this.exportImgHeight, 10);
      }
      if (typeof this.canvasWidth === 'string' && this.canvasWidth.trim() !== '') {
        // 使用 parseInt 转换字符串为整数
        this.canvasWidth = parseInt(this.canvasWidth, 10);
      }
      if (typeof this.canvasHeight === 'string' && this.canvasHeight.trim() !== '') {
        // 使用 parseInt 转换字符串为整数
        this.canvasHeight = parseInt(this.canvasHeight, 10);
      }
      const canvasAspect = _this.canvasWidth / _this.canvasHeight;
      const outputAspect = _this.exportImgWidth / _this.exportImgHeight;
      let scale;
      if (outputAspect > canvasAspect) {
          // 如果输出宽高比更宽，按宽度缩放
          scale = _this.exportImgWidth / _this.canvasWidth;
      } else {
          // 如果输出宽高比更高，按高度缩放
          scale = _this.exportImgHeight / _this.canvasHeight;
      }
      let backgroundColor = _this.canvas.backgroundColor;
      _this.canvas.backgroundColor = 'rgba(255, 255, 255, 1)';
      const dataUrl = _this.canvas.toDataURL({
        quality: 0.7,
        format: 'jpeg',
        multiplier: scale // 设置图像质量导出
      });
      _this.canvas.backgroundColor = backgroundColor;
      
      // 转换 Data URL 为 Blob
      const response = await fetch(dataUrl);
      if (!response.ok) throw new Error('Network response was not ok');
      const blob = await response.blob();
      // 创建 File 对象
      var file = new File([blob], 'canvas-image.png', { type: 'image/png' });
      _this.uploadedFile = file;
      _this.uploadedImage = URL.createObjectURL(file);
      _this.previewImageUrl = URL.createObjectURL(file);
      
      // 上传画布，获取图片链接
      if (_this.uploadedFile) {
          try {
            const uploadResponse = await _this.uploadImage();
            if (!uploadResponse.flag) {
              _this.$message.error(uploadResponse.msg);
              return;
            }
            _this.uploadedImageUrl = uploadResponse.url;
            _this.previewImageUrl = uploadResponse.url;
            _this.$message.success("success");
          } catch (error) {
            _this.$message.error(error);
            return;
          } finally {
            _this.submitCanvasDialogLoading = false;
          }
      }

      //请求图片接口 获取图片
      if(_this.generateImageDialogFlag ==1){
        //埋点
        _this.addTrackingLog("description_more_click");
        _this.generateImageOneLoading = true;
        _this.pageloading = true;
        _this.progress = 0;
        _this.count = 0; // Initialize count
        //埋点
        //_this.addTrackingLog("description_more_click");
        await _this.generateImageDialog(_this.generateImageDialogData);
        _this.pageloading = false;
      }else{
        //全部分类 获取图片
        _this.preloadImagesDialog('description_generate_for_all_click');
      }
      _this.editlDialogImageVisible = false;
      
    },
    async generateImageDialog(data) {
        const dataMap = {
            "category": data.categoryPath,
            "imageUrl": this.uploadedImageUrl,
            "n": 1,
            "platform":this.selectedPlatform
        };
        try {
            const result = await this.$axios.post('/productPublish/xaz/generateImage', dataMap);
            this.count++;
            // console.log(" this.count", this.count);

            let percent = Math.ceil((this.count / this.dataList.length) * 100);
            this.progress = percent;

            if (!result.flag) {
                this.$message.error(result.msg);
                return; // Early exit on error
            }
            if(data.imageUrlList == null) {
              data.imageUrlList = result.imageUrlList;
            }else {
              data.imageUrlList.push(...result.imageUrlList);
              this.generateImageOneLoading = false;
            }
            
            this.updateProductPublish();
        } catch (error) {
            console.error("Error generating image:", error);
            this.$message.error('Failed to generate image');
        }
    },
    async preloadImagesDialog(logStr) {
        if(this.dataList.length ==0){
          this.$message.error("No Data！");
          return;
        }
        //埋点
        this.addTrackingLog(logStr);
        this.preloadImagesLoading = true;
        this.pageloading = true;
        this.progress = 0;
        this.count = 0; // Initialize count
        
        const promises = this.dataList.map((item) => this.generateImage(item));

        // Wait for all generateImage promises to resolve
        await Promise.all(promises);
        
        this.preloadImagesLoading = false;
        this.pageloading = false;

        this.updateProductPublish();
    },
    chanageExportImgSizeDialog(){
      if(this.exportImgWidth === null ){
        this.$message.error("Please enter the width of the image"); 
        return;
      }
      if(this.exportImgHeight === null){
        this.$message.error("Please enter the height of the image"); 
        return;
      }

      if(this.exportImgWidth && this.exportImgWidth > 5000){
        this.$message.error("Exceeded the maximum image limit of 5000×5000"); 
        return;
      }
      if(this.exportImgHeight && this.exportImgHeight > 5000){
        this.$message.error("Exceeded the maximum image limit of 5000×5000"); 
        return;
      }
      
      //保持画布高度600不变，宽度按照输入图片的尺寸缩放
      if (typeof this.exportImgWidth === 'string' && this.exportImgWidth.trim() !== '') {
        // 使用 parseInt 转换字符串为整数
        this.exportImgWidth = parseInt(this.exportImgWidth, 10);
      }
      if (typeof this.exportImgHeight === 'string' && this.exportImgHeight.trim() !== '') {
        // 使用 parseInt 转换字符串为整数
        this.exportImgHeight = parseInt(this.exportImgHeight, 10);
      }
      if (typeof this.canvasWidth === 'string' && this.canvasWidth.trim() !== '') {
        // 使用 parseInt 转换字符串为整数
        this.canvasWidth = parseInt(this.canvasWidth, 10);
      }
      if (typeof this.canvasHeight === 'string' && this.canvasHeight.trim() !== '') {
        // 使用 parseInt 转换字符串为整数
        this.canvasHeight = parseInt(this.canvasHeight, 10);
      }
      if(this.exportImgWidth > this.exportImgHeight){//如果宽度大于高度，则画布以600宽度为基准算画布高度
        var scaleX = 600/this.exportImgWidth;
        var coutY = this.exportImgHeight * scaleX;
        this.canvasHeight = coutY;
        this.canvasWidth = 600;
        
      }else{//如果宽度小于高度，则画布以600高度为基准算画布宽度
        var scaleY = 600/this.exportImgHeight;
        var coutX = this.exportImgWidth * scaleY;
        this.canvasWidth = coutX;
        this.canvasHeight = 600;
      }
      
      this.chanageCanvasSizeDialog();
    },
    //画布尺寸改变
    chanageCanvasSizeDialog(val){
      // this.canvasWidth = this.canvasSizeList[val].width;
      // this.canvasHeight = this.canvasSizeList[val].height;
      // 更新 Fabric.js canvas 尺寸
      this.canvas.setWidth(this.canvasWidth);
      this.canvas.setHeight(this.canvasHeight);

      // 先清除画布
      if (this.canvas !== null) {
          this.canvas.clear(); // 清空画布上的所有对象
          this.canvas.dispose();
      }
      this.canvas = null;
      this.$nextTick(() => {
        this.initCanvasDialog();
        this.initImageDialog(this.downloadCanvasUrl);
        this.handleLinkSubmitLoading = false;
      })
    },
    showEditCategory(){
      this.isCategoryEditing  = true;
      //创意分类增加一行
      this.nofurnitureCategories.push("");
      this.furnitureCategories.push("");
    },
    hideEditCategory(){
      this.isCategoryEditing  = false;
      // 使用 filter 方法过滤掉 null 和空字符串
      this.furnitureCategories = this.furnitureCategories.filter(category => {
        return category !== null && category !== '';
      });
      // 使用 filter 方法过滤掉 null 和空字符串
      this.nofurnitureCategories = this.nofurnitureCategories.filter(category => {
        return category !== null && category !== '';
      });
    },
  }
}
</script>
<style scoped lang="less">
.large-textarea{
  width: 98%;
  margin: 10px auto;
}
.clear{
  clear: both;
}
.loadingMaskNoBg{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: rgba(51, 51, 51, 0.5);

  .progress-container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2002;
    opacity: 1;
    width: 200px; /* 调整圆形进度条的宽度 */
    height: 200px; /* 调整圆形进度条的高度 */

    .el-progress{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 200px;
      .el-progress-circle{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 200px;
      }
      // /deep/ .el-progress-circle__track {
      //   stroke: rgba(255, 255, 255, 0.303);
      // }

      /deep/ .el-progress__text {
        font-size: 20px !important;
        color: #FFF !important;
      }
    }
    
  }
}
.bullet-list {
  padding-left: 10px; /* 给列表项增加左边距 */
  margin-left: 15px;
}

.bullet-list li {
  text-align: left;
  list-style: disc; /* 使用实心点符号 */
  // margin-bottom: 5px; /* 每个列表项之间的间距 */
  line-height: 25px;

}

.bullet-list li::marker {
  //color: #409eff; /* 单独控制实心点的颜色 */
  font-size: 16px; /* 可以调整实心点的大小 */
}

/deep/ .el-scrollbar{
  width: 100% !important;
}
.container_slef {
    display: flex;
    padding: 0px 0px 0px 0px;
    width: 98%;
    height: 100vh;
    position: relative;
}
//返回按钮
.back-to-dashboard-btn{
    position: absolute;
    left: 40px;
    top: 40px;
    z-index: 9;
    height: 60px;
    line-height: 60px;
    background-color: #1e392a; /* 按钮背景颜色 */
    color: white; /* 按钮文字颜色 */
    font-size: 18px;
    font-weight: 700;
}

.left-panel {
    width: 280px; /* 固定宽度 */
    background-color: white;
    padding: 140px 20px 20px 20px;
    display: flex;
    flex-direction: column;
}


.list-item {
    display: flex;
    align-items: center;
    padding: 18px 20px;
    font-weight: 500;
    cursor: pointer;
    color: #9da3ae; /* 默认字体颜色 */
}

.list-item.active {
    color: #263fa9; /* 点击后的字体颜色 */
}

.circle {
    width: 24px; /* 圆框的宽度 */
    height: 24px; /* 圆框的高度 */
    border: 2px solid #9da3ae; /* 默认边框颜色 */
    border-radius: 50%; /* 圆形 */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px; /* 右边留一些间距 */
}

.list-item.active .circle {
    border-color: #263fa9; /* 点击后的圆框颜色 */
}
.el-icon-circle-check{
  font-size: 26px;
  margin-right: 10px; /* 右边留一些间距 */
}

.right-panel {
    flex: 1; /* 自适应宽度 */
    display: flex;
    justify-content: center;        /* 水平居中 */
    align-items: center;            /* 垂直居中 */
    height: 85%;
    margin: auto;
    background-color: white; 
    background-color: white;      /* 背景颜色 */
    border-left: 1px solid #e3e4e5;
    border-right: 1px solid #e3e4e5;
    border-bottom: 1px solid #e3e4e5;
    border-radius: 20px;          /* 圆角边框 */
    box-shadow: 
        -15px 0 10px rgba(233, 229, 229, 0.2),  /* 左侧阴影 */
        10px 0 10px rgba(233, 229, 229, 0.2),   /* 右侧阴影 */
        0 10px 10px rgba(233, 229, 229, 0.2);   /* 下方阴影 */
    /* 
    第一个阴影：h-offset: -10px，v-offset: 0，表示左侧阴影
    第二个阴影：h-offset: 10px，v-offset: 0，表示右侧阴影
    第三个阴影：h-offset: 0，v-offset: 10px，表示下方阴影
    */
}
.right-main{
  width: 92%;
  max-height: 100%;
  margin: 0px;
  text-align: center;
  overflow: auto;
  padding: 20px 0px;
}

/*第一步 */
.select-platfrom{
  padding: 30px;
  h2{
    font-size: 22px;
    font-weight: bold;
    color: #000;
    line-height: 50px;
  }
  .button-list {
      width: 100%; /* 使按钮列表宽度为100% */
  }
  .button-list-li{
    display: block;
    position: relative;
  }
  .custom-button{
      position: relative;
      background-color: black; /* 按钮背景颜色 */
      color: white; /* 按钮文字颜色 */
      width: 100%; /* 按钮宽度 */
      height: 35px; /* 按钮高度 */
      margin: 10px 0; /* 按钮之间的间距 */
      text-align: center;
      font-weight: bold;
      
  }
  /deep/ .custom-button:hover{
      background: #7f7f7f !important; /* 按钮背景颜色 */
      color: white; /* 按钮文字颜色 */
  }
  /* 使按钮图标靠右 */
  .custom-button .el-icon-arrow-right, .custom-button .el-icon-arrow-down {
      position: absolute;
      top: 15px;
      right: 15px; /* 图标与文字之间的间隔 */
  }

  .custom-site-List{
      width: 100%;
      min-height: 60px;
      clear: both;
      i {
        display: inline-block;
        height: 30px;
        line-height: 20px;
        padding: 5px 15px;
        font-size: 10px;
        color: #000000;
        border-radius: 5px;
        border: 1px solid #97979f;
        background-color: #f2f2f2;
        margin-right: 15px;
        margin-top: 15px;
        font-style: normal;
      }
      i:hover{
        cursor: pointer;
        border: 1px solid #e23442;
      }
      i:last-child {
        margin-right: 0;
      }
      .bgcolor {
          border: 1px solid #e23442;
          color: #e23442;
      }            
  }

  .next-btn{
    float: left;
    width: 80px;
    background-color: black; /* 按钮背景颜色 */
    color: white; /* 按钮文字颜色 */
    height: 35px; /* 按钮高度 */
    margin: 10px 0; /* 按钮之间的间距 */
    text-align: center;
  }

}

/*第二步 */
.link-wrapper{
  padding: 30px;
  .link-img{
    text-align: center;
    .preview-image {
      width: 200px;
      height: 200px;
      object-fit: contain;
      cursor: pointer;
    }
  }
  h2{
    font-size: 24px;
    font-weight: bold;
    color: #000;
    line-height: 70px;
  }
  
  .link-btn{
      background-color: black; /* 按钮背景颜色 */
      color: white; /* 按钮文字颜色 */
      width: 100%; /* 按钮宽度 */
      height: 35px; /* 按钮高度 */
      margin: 10px 0; /* 按钮之间的间距 */
      text-align: center;
  }
  /deep/ .link-btn:hover{
      background: #7f7f7f !important; /* 按钮背景颜色 */
      color: white; /* 按钮文字颜色 */
  }

  .next-btn{
    float: right;
    width: 80px;
    background-color: black; /* 按钮背景颜色 */
    color: white; /* 按钮文字颜色 */
    height: 35px; /* 按钮高度 */
    margin: 10px 0; /* 按钮之间的间距 */
    text-align: center;
  }
  .back-btn{
    float: left;
    width: 80px;
    background-color: black; /* 按钮背景颜色 */
    color: white; /* 按钮文字颜色 */
    height: 35px; /* 按钮高度 */
    margin: 10px 0; /* 按钮之间的间距 */
    text-align: center;
  }
   /deep/ .back-btn:hover{
      background: #7f7f7f !important; /* 按钮背景颜色 */
      color: white; /* 按钮文字颜色 */
  }

}
.file-and-link{
  display: flex;
  .upload-button {
    background-color: #409EFF; /* 按钮背景色 */
    color: white; /* 按钮文本颜色 */
    border: none; /* 去掉边框 */
    padding: 0px 20px; /* 内边距 */
    height: 35px;
    border-radius: 4px; /* 圆角 */
    cursor: pointer; /* 控制鼠标样式 */
    transition: background-color 0.3s; /* 背景色渐变效果 */
    width: 11%;
  }

  /* 鼠标悬浮效果 */
  .upload-button:hover {
    background-color: #66b1ff; /* 悬停时的颜色 */
  }
  .upload-span{
    width: 15px;
    display: inline-block;
    line-height: 35px;
    font-weight: bold;
    margin: 0px 8px;
  }
  .uploadFile {
    display: none; /* 隐藏原始文件输入 */
  }
  /deep/ .link-input{
    flex: 1;
    height: 35px;
    margin-bottom: 10px;
    .el-input__inner{
      height: 35px;
      background-color: #e9f0fd;
      border-color: black;
    }
  }
}
.linkitemtitle{
  line-height: 24px;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
}
/*第三步 */
.canvas-wrapper{
    padding: 10px;
    .canvasSizeWrapper{
      width: 20%;
      float: left;
      text-align: left;

      .mini-input{
        width: 120px;
        margin: 10px 15px;
      }
    }
    .canvasMain{
      width: 75%;
      float: left;

      .canvasDiv {
        border:1px solid #ccc;
        box-shadow: 0 0 10px 10px rgba(233, 229, 229, 0.2); /* 设置四周阴影 */
        background: url(../assets/22.jpg) repeat;
      }
    }
    .next-btn{
      float: right;
      width: 80px;
      background-color: black; /* 按钮背景颜色 */
      color: white; /* 按钮文字颜色 */
      height: 35px; /* 按钮高度 */
      margin: 10px 0; /* 按钮之间的间距 */
      text-align: center;
    }

    .back-btn{
      float: left;
      width: 80px;
      background-color: black; /* 按钮背景颜色 */
      color: white; /* 按钮文字颜色 */
      height: 35px; /* 按钮高度 */
      margin: 10px 0; /* 按钮之间的间距 */
      text-align: center;
    }
    /deep/ .back-btn:hover{
        background: #7f7f7f !important; /* 按钮背景颜色 */
        color: white; /* 按钮文字颜色 */
    }
}
/*第四步 */
.cate-wrapper{
  padding: 10px;
  .link-img{
    text-align: center;
    .preview-image {
      width: 200px;
      height: 200px;
      object-fit: contain;
      cursor: pointer;
    }
  }
  h2{
    font-size: 20px;
    font-weight: bold;
    color: #000;
    line-height: 50px;
  }
  .cate-check{
    text-align: left;
    user-select: text; /* 允许选择文本 */
   .el-checkbox{
      display: block;
      line-height: 40px;

      /deep/ .el-checkbox__label{
        font-size: 16px !important;
        font-weight: bold !important;
        color: black !important;
      }
      /deep/ .el-checkbox__input {
        width: 30px; /* 选择框的宽度 */
        height: 30px; /* 选择框的高度 */
        transform: scale(1.5); /* 调整选框大小 */
        vertical-align: middle;
        line-height: 30px;
      }
    }
  }
  /deep/ .cate-check .el-checkbox {
      cursor: text; /* 更改光标为文本选择模式 */
      display: flex;                 /* 确保checkbox和文本能并排显示 */
      align-items: center;           /* 垂直居中 */
      cursor: default;               /* 更改光标以防误触 */
  }
  .checkbox-label {
    user-select: all;              /* 选中时允许选择文本 */
    cursor: text;                  /* 鼠标指针变为文本选择光标 */ 
    margin-left: 8px;              /* 增加文本左侧间距，分离checkbox和文本 */
    text-decoration: none;
  }
  .cate-btn{
    width: 250px;
    background-color: black; /* 按钮背景颜色 */
    color: white; /* 按钮文字颜色 */
    height: 35px; /* 按钮高度 */
    margin: 10px 0; /* 按钮之间的间距 */
    text-align: center;
  }
  .next-btn{
    float: right;
    width: 80px;
    background-color: black; /* 按钮背景颜色 */
    color: white; /* 按钮文字颜色 */
    height: 35px; /* 按钮高度 */
    margin: 10px 0; /* 按钮之间的间距 */
    text-align: center;
  }

  .back-btn{
    float: left;
    width: 80px;
    background-color: black; /* 按钮背景颜色 */
    color: white; /* 按钮文字颜色 */
    height: 35px; /* 按钮高度 */
    margin: 10px 0; /* 按钮之间的间距 */
    text-align: center;
  }
   /deep/ .back-btn:hover{
      background: #7f7f7f !important; /* 按钮背景颜色 */
      color: white; /* 按钮文字颜色 */
  }

}

/*第五步 第六步*/
.description-wrapper{
  padding: 10px;
  .link-img{
    text-align: center;
    .preview-image {
      width: 200px;
      height: 200px;
      object-fit: contain;
      cursor: pointer;
    }
  }
  h2{
    font-size: 22px;
    font-weight: 500;
    color: #000;
    line-height: 70px;
  }
  .product-list{
    .product-item{
      margin: 10px;
      padding: 15px;
      background-color: #ffffff;
      border: 1px solid #e3e4e5;
      border-radius: 10px;
      text-align: left;
      .product-image{
        width: 200px;
        height: 200px;
        object-fit: contain;
        cursor: pointer;
      }
      .roduct-cate{
        text-align: left;
        font-size: 20px;
        font-weight: bold;
        color: #000;
        line-height: 30px;
      }
      .product-title{
        text-align: left;
        font-size: 16px;
        font-weight: bold;
        color: #000000;
        min-height: 35px;
        line-height: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .product-desc{
        margin-top: 10px;
        font-size: 16px;
        font-weight: bold;
        text-align: left;
        color: #000000;
        line-height: 25px;
      }
      .product-image-div{
        display: inline-block;
        width: 21%;
        margin: 20px;
      }
      .product-list-image{
        width: 180px;
        height: 180px;
        object-fit: contain;
        cursor: pointer;
        display: inline-block;
      }
    }
    
  }
  .back-btn{
    float: left;
    width: 80px;
    background-color: black; /* 按钮背景颜色 */
    color: white; /* 按钮文字颜色 */
    height: 35px; /* 按钮高度 */
    margin: 10px 0; /* 按钮之间的间距 */
    text-align: center;
  }
   /deep/ .back-btn:hover{
      background: #7f7f7f !important; /* 按钮背景颜色 */
      color: white; /* 按钮文字颜色 */
  }

  .getImg-btn{
    width: 350px;
    background-color: #a6bff6; /* 按钮背景颜色 */
    color: white; /* 按钮文字颜色 */
    height: 35px; /* 按钮高度 */
    margin: 10px 0; /* 按钮之间的间距 */
    text-align: center;
  }
  .next-btn{
    float: right;
    padding: 0px 15px;
    background-color: black; /* 按钮背景颜色 */
    color: white; /* 按钮文字颜色 */
    height: 35px; /* 按钮高度 */
    margin: 10px; /* 按钮之间的间距 */
    text-align: center;
  }
}

/*第七步*/
.infoma-wrapper{
  padding: 10px;
  display: flex;
  position: relative;
  h3{
    font-size: 20px;
    font-weight: bold;
    color: #000;
    line-height: 40px;
  }
  .infoma-left{
    flex: 1;
    padding: 10px;
    
    .infoma-left-top{
      width: 100%;
      height: 50px;

      .infoma-left-top-select{
        width: 100%;
        margin: 0px auto;
      }
    }
    .infoma-left-content{
      height: 630px;
      margin: 0px auto;
      text-align: left;
      overflow: auto;

      .infoma-left-content-li{
        display: block;
        border: 1px solid #f2f2f2;
        box-shadow: 0 0 5px 5px rgba(233, 229, 229, 0.2); /* 设置四周阴影 */
        border-radius: 10px;
        padding: 10px;
        margin-bottom: 10px;
        white-space: normal; /* 默认的换行行为，允许文本换行 */
        overflow-wrap: break-word; /* 避免长单词超出内容区域 */
        .ori-data-cate{
          text-align: left;
          font-size: 20px;
          font-weight: bold;
          color: #000;
          line-height: 30px;
        }
        .ori-data-title{
          text-align: left;
          font-size: 16px;
          font-weight: bold;
          color: #000000;
          min-height: 50px;
          line-height: 25px;
        }
        .ori-data-dec{
          margin-top: 15px;
          font-size: 16px;
          font-weight: bold;
          text-align: left;
          color: #000000;
          line-height: 25px;
        }
      }
    }
  }
  .infoma-right{
    width: 500px;
    height: 630px;
    overflow: auto;
    padding: 10px;
    
    .infoma-right-top{
      height: 50px;
      .enh-btn{
        width: 100px;
        background-color: black; /* 按钮背景颜色 */
        color: white; /* 按钮文字颜色 */
        height: 30px; /* 按钮高度 */
        margin: 10px; /* 按钮之间的间距 */
        text-align: center;
      }
      /deep/ .enh-btn:hover{
          background: #7f7f7f !important; /* 按钮背景颜色 */
          color: white; /* 按钮文字颜色 */
      }
    }
    .infoma-right-content{
      height: 530px;
      margin: 0px auto;
      overflow: auto;
      border: 1px solid #f2f2f2;
      box-shadow: 0 0 5px 5px rgba(233, 229, 229, 0.2); /* 设置四周阴影 */
      border-radius: 10px;
      text-align: left;
      padding: 10px;

      .infoma-right-ul{
        margin: 10px 0px;
        .infoma-right-ul-li{
          height: 40px;
          div{
            padding: 0px 5px;
            display: inline-block;
            font-size: 12px;
          }
          .infoma-right-ul-li-no{
            width: 5%;
            text-align: center;
          }
          .infoma-right-ul-li-name{
            width: 42%;
            text-align: left;
          }
          .infoma-right-ul-li-value{
            width: 53%;
            text-align: left;
          }
          .infoma-right-ul-li-input{
            text-align: right;
          }
        }
      }
    }
  }

  .publish-btn{
    position: absolute;
    right: 0px;
    bottom: -10px;
    z-index: 3;
    width: 100px;
    background-color: black; /* 按钮背景颜色 */
    color: white; /* 按钮文字颜色 */
    height: 35px; /* 按钮高度 */

  }
}

/*第八步*/
.listen-wrapper{
  padding: 10px;
  .link-img{
    text-align: center;
    .preview-image {
      width: 200px;
      height: 200px;
      object-fit: contain;
      cursor: pointer;
    }
  }
  .listen-content{
    width: 400px;
    height: 200px;
    margin: 0px auto;
    border: 1px solid #f2f2f2;
    box-shadow: 0 0 5px 5px rgba(233, 229, 229, 0.2); /* 设置四周阴影 */
    border-radius: 10px;
    text-align: center;
    padding: 15px;
    margin-top: 30px;

    .download-btn{
      width: 150px;
      background-color: black; /* 按钮背景颜色 */
      color: white; /* 按钮文字颜色 */
      height: 35px; /* 按钮高度 */
      margin: 10px 0; /* 按钮之间的间距 */
      text-align: center;
    }
  }
  .next-btn{
    float: right;
    width: 80px;
    background-color: black; /* 按钮背景颜色 */
    color: white; /* 按钮文字颜色 */
    height: 35px; /* 按钮高度 */
    margin: 10px 0; /* 按钮之间的间距 */
    text-align: center;
  }
}
.create-large-el-input{
  width: 740px;
  margin: 5px 0px;
}
.successColor{
  color: #67C23A;
}
</style>