export function download(data, fileName, options) {
  const link = document.createElement('a');

  const blob = new Blob([data], options);
  const href = window.URL.createObjectURL(blob);
  link.href = href;
  link.download = fileName;
  link.style.display = 'none';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(href);
}
