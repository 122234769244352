<template>
  <div class="container">
    <div class="container_self">
      <!-- <header class="pub_header">
          <div class="pub_logo">AI Publish</div>
          <nav class="index_menu">

          </nav>
          <div class="index_auth-buttons">

          </div>
      </header> -->
      <div class="dash-container">
        <div class="sidebar">
            <ul>
              <li class="sidebar-li">
                <el-button icon="el-icon-picture-outline" class="sidebar-dash-btn">Listing Manager</el-button>
              </li>
              <li class="sidebar-li">
                <router-link to="/ProductPublishUsa">
                  <el-button icon="el-icon-circle-plus-outline" class="sidebar-create-btn">Create</el-button>
                </router-link>
              </li>
              <li class="sidebar-li">
                <router-link to="/PublishHistoryList">
                  <el-button icon="el-icon-circle-plus-outline" class="sidebar-create-btn">History</el-button>
                </router-link>
              </li>
              <li class="sidebar-li">
                <router-link to="/ProductAnalytics">
                  <el-button icon="el-icon-circle-plus-outline" class="sidebar-create-btn">Analytics</el-button>
                </router-link>
              </li>
            </ul>



            <!-- 用户管理 -->
            <router-link to="/UserProfile">
              <el-button icon="el-icon-user-solid" class="userProfile-btn">User Profile</el-button>
            </router-link>

        </div>
        <div class="content">
            <aiProductList-comp></aiProductList-comp>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AiProductListComp2 from '@/components/AiProductListComp2'
export default {
  name:"PublishDashboard2",
  components: {
    'aiProductList-comp': AiProductListComp2
  },
  data(){
    return{
      siteList:["US","MX","FR","DE","UK","CA"],
      site: "US",
      platformList:["Amazon","Amazon Vendor","Walmart","Temu","Confo","MDM","BUT","XXXLutz"],
      platform:"Amazon",
    }
  },
  methods:{
    // 可在这里添加方法
  }
}
</script>

<style lang="less" scoped deep>
:root {
    --el-color-primary: #000; /* 主颜色 */
    --el-color-active: #000; /* 全局 active 颜色 */
    --el-button-hover-bg: #000; /* 修改按钮 hover 状态颜色 */
    --el-button-active-bg: #000; /* 修改按钮 active 状态颜色 */
}
.container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.container_self {
  background-color: #ffffff;
  width: 100%;
  height: 100vh;             /* 设置为 100vh，确保占满视口高度 */
  display: flex;
  flex-direction: column;
}

// 头部样式
.pub_header {
  width: 100%;
  height: 90px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.pub_logo {
  color: #111111;
  font-size: 32px;
  font-weight: 800;
}

.index_menu {
  ul {
    display: flex;
    gap: 40px;
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.index_auth-buttons {
  .el-button {
    margin-left: 16px;
    padding: 8px 16px;
  }
}

// 主内容区布局
.dash-container {
  flex: 1;
  display: flex;
  width: 100%;
  height: 100%; // 更改此处为 100% 以填充整个可用空间
  overflow: hidden; // 防止内容溢出
}

// 侧边栏
.sidebar {
  width: 220px;
  height: 100%; // 更改此处为 100% 以填充整个可用空间
  background: #f7f9fc;
  border-right: 1px solid #eee;
  padding: 16px;
  .sidebar-li{
    padding : 10px 0px;
  }
  .sidebar-dash-btn, .sidebar-create-btn, .userProfile-btn {
      display: block;
      width: 185px;
      height: 45px;
      line-height: 45px;
      font-size: 16px;
      font-weight: 700;
      text-align: left;
      padding: 0px 20px;
  }

  .sidebar-dash-btn {
      background-color: #1e392a; /* 按钮背景颜色 */
      color: white; /* 按钮文字颜色 */
  }

  .sidebar-create-btn {
      background-color: #ffffff; /* 按钮背景颜色 */
      color: #111111; /* 按钮文字颜色 */
  }

  .userProfile-btn {
      position: absolute;
      left: 16px;
      bottom: 30px;
      z-index: 9;
      background-color: #ffffff; /* 按钮背景颜色 */
      color: #111111; /* 按钮文字颜色 */
  }
}

// 自适应内容区
.content {
  flex: 1;
  // padding: 15px;
  overflow-y: auto; // 内容超出时显示滚动条
  .content_header{
    background-color: #000;
    margin: 0px 15px;
    padding: 30px 30px;
    color: #fff;
    border-radius: 8px;
  }


  .search-bar {
      display: flex;               /* 使用 flexbox 布局 */
      justify-content: space-between; /* 在主轴上平均分配两端的元素 */
      align-items: center;        /* 垂直居中对齐 */
      padding: 20px;              /* 添加内边距 */
      background-color: #fff;  /* 背景颜色可以根据需求调整 */
      margin-top: 20px;
      border-radius: 8px;
  }

  .search-item {
      display: flex;              /* 使用 flexbox 布局 */
      gap: 10px;                  /* 选择项之间的间隔 */
  }

  /deep/ .search-item el-select {
      margin: 0 10px;            /* 左右边距 */
      background-color: #000;    /* 背景色为黑色 */
      color: white;               /* 文本颜色为白色 */
      width: 150px !important;
  }

  /deep/ .search-item el-select .el-input__inner {
      color: white !important;               /* 字体颜色为白色 */
      background-color: #000 !important;     /* 背景色为黑色 */
  }

  // /deep/ .el-input {
  //     width: 300px;                /* 自适应宽度 */
  // }

  .el-input__icon {
      margin-left: 10px;         /* 在右边添加空间 */
  }

  .search-chanage {
      display: flex;              /* 使用 flexbox 布局 */
      align-items: center;        /* 垂直居中 */
      margin-left: auto;          /* 把这个 div 移到右边 */
  }

  .search-chanage i {
      margin-left: 15px;          /* 左右图标之间的空间 */
      cursor: pointer;            /* 鼠标悬停时显示手形光标 */
  }

  .content-card {
      margin-top: 20px;          /* 在 search-bar 下方添加间距 */
      display: flex;              /* 使用 flexbox 布局来安排数据项 */
      flex-wrap: wrap;           /* 允许换行 */
      gap: 20px;                  /* 数据项之间的间隔 */
  }

  .data-item {
      width: calc(25% - 15px);    /* 四个项在一行并保持间距 */
      box-sizing: border-box;      /* 确保对内边距和边框的计算 */
      display: flex;               /* 使用 flexbox 布局 */
      flex-direction: column;      /* 垂直排列数据项内容 */
  }

  .data-img {
      width: 100%;                /* 宽度为 data-item 的宽度 */
      height: 100%;               /* 高度待设置为自己的尺寸 */
      position: relative;         /* 定位 */
      margin-bottom: 20px;
  }

  .data-img .item-image {
      width: 100%;                /* 图片宽度为父元素宽度 */
      height: 100%;               /* 图片高度为父元素高度 */
      object-fit: cover;          /* 保持图片比例并裁剪 */
      border-radius: 8px;
  }

}

</style>
