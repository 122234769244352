<template>
  <div class="container">
    <div class="container_self">
      <header class="pub_header">
          <div class="pub_logo">AI Publish</div>
          <nav class="index_menu">
              
          </nav>
          <div class="index_auth-buttons">
             
          </div>
      </header>
      <div class="dash-container">
        <div class="sidebar">
            <ul>
              <li class="sidebar-li">
                <router-link to="/PublishDashboard">
                  <el-button icon="el-icon-picture-outline" class="sidebar-create-btn">Dashboard</el-button>
                </router-link>
              </li>
              <li class="sidebar-li">
                <router-link to="/ProductPublishUsa">
                  <el-button icon="el-icon-circle-plus-outline" class="sidebar-create-btn">Create</el-button>
                </router-link>
              </li>
              <li class="sidebar-li">
                <router-link to="/PublishHistoryList">
                  <el-button icon="el-icon-circle-plus-outline" class="sidebar-create-btn">History</el-button>
                </router-link>
              </li>
            </ul>

            <!-- 用户管理 -->
            <router-link to="/UserProfile">
              <el-button icon="el-icon-user-solid" class="userProfile-btn">User Profile</el-button>
            </router-link>
            
        </div>
        <div class="content">
            <div class="user-profile">
              <h3>User profile</h3>
              <p>Edit your profile and connected accounts</p>
              <div class="profile-title">Account details</div>
              <div class="profile-item">
                <label>User Name</label>
                <span> {{userProfile.username}}</span>
              </div>
              <div class="profile-item">
                <label>Email</label>
                <span> {{userProfile.email}}</span>
              </div>
              <div class="profile-item">
                <label>Password</label>
                <el-button @click=" editlPasswordVisible = true">Chanage your password</el-button>
              </div>
              
              <el-button @click="handleLogout" class="logout-btn" :loading="handleLogoutLoading">Log out</el-button>
            </div>
        </div>
      </div>
    </div>
    <!-- 修改密码 -->
    <el-dialog :visible.sync="editlPasswordVisible"  style="width: 50%; margin: 0px auto;text-align:center;">
      <h2>Change password</h2>
      <form class="login-form">
        <div class="form-group">
          <input type="password" placeholder="Current Password" class="input-field" v-model="changePasswordRequest.currentPassword" required>
        </div>
        <div class="pass-title">
          Set a password to create your account.
          <el-tooltip class="item" effect="light" placement="top-start">
              <div slot="content">The password must meet the following criteria:<br>
                                  1.Length: 8 - 64 characters<br>
                                  2.Lowercase letters (a-z)<br>
                                  3.Numbers (0-9)</div>
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
        </div>
        <div class="form-group">
          <input type="password" placeholder="New Password" class="input-field" v-model="changePasswordRequest.newPassword" required @input="validatePasswordInput">
        </div>
        <p v-if="passworderrorMessage" class="error-message">{{ passworderrorMessage }}</p>
        <div class="form-group">
          <input type="password" placeholder="Re-enter your password to confirm" class="input-field" v-model="confirmPassword" required @input="validateConfirmPassword">
        </div>
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
      </form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editlPasswordVisible = false">Cancel</el-button>
        <el-button type="primary" @click="editlPassword" :loading="editlPasswordLoading">Apply</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
export default {
  name:"PublishDashboard",
  data(){
    return{
      siteList:["US","MX","FR","DE","UK","CA"],
      site: "US",
      platformList:["Amazon","Amazon Vendor","Walmart","Temu","Confo","MDM","BUT","XXXLutz"],
      platform:"Amazon",
      userProfile:{},
      editlPasswordVisible: false,
      getEmailCaptchaLoading: false,
      confirmPassword: null,
      editlPasswordLoading: false,
      changePasswordRequest:{
        userName:'',
        currentPassword:null,
        newPassword: null,
      },
      errorMessage:'',
      usernameerrorMessage:'',
      emailerrorMessage:'',
      passworderrorMessage:'',
      handleLogoutLoading: false,
    }
  },
  created(){
    this.getUserProfile();
  },
  methods:{
    async getUserProfile(){
      const response = await this.$axios.get('/user/xaz/getUserProfile', {});
      if (response.flag) {
        this.userProfile = response.userProfile;
      }
    },
    //校验密码
    validatePasswordInput() {
      this.passworderrorMessage = ''; // 清空错误信息
      const lengthRegex = /^.{8,64}$/; // 校验 8 到 64 个字符
      if (!lengthRegex.test(this.changePasswordRequest.newPassword)) {
        this.passworderrorMessage = 'The password must be at least 8 characters long and no more than 64 characters.';
        return;
      }
      const lowerCaseRegex = /[a-z]/; // 检查是否包含小写字母
      if (!lowerCaseRegex.test(this.changePasswordRequest.newPassword)) {
        this.passworderrorMessage = 'The password must contain lowercase letters.';
        return;
      }
      const digitRegex = /\d/; // 检查是否包含数字
      if (!digitRegex.test(this.changePasswordRequest.newPassword)) {
        this.passworderrorMessage = 'The password must contain numbers.';
        return;
      }
    },
    validateConfirmPassword() {
      this.errorMessage = ''; // 清空错误信息
      if (this.changePasswordRequest.newPassword !== this.confirmPassword) {
        this.errorMessage = 'Passwords do not match!';
      }
    },
    async editlPassword(){
      // 校验密码格式
      this.validatePasswordInput(this.changePasswordRequest.newPassword);
      if (this.passworderrorMessage) {
        this.$message.error(this.passworderrorMessage );
        return;
      }
      // 密码和确认密码匹配性校验
      if (this.changePasswordRequest.newPassword !== this.confirmPassword) {
        this.$message.error('  The confirmation password does not match the password.');
        return;
      }
      this.editlPasswordLoading = true;
      try {
        // 调用Java后端接口
        this.changePasswordRequest.userName = this.userProfile.userName;
        const response = await this.$axios.post('/user/xaz/changePassword',this.changePasswordRequest );
        if (response.flag) {
          this.$message.success("Chanage successful");
          this.editlPasswordLoading = false;
          this.editlPasswordVisible = false;
        }else{
          this.$message.error(response.msg);
          this.editlPasswordLoading = false;
        } 

      } catch (error) {
        this.$message.error(error);
      }
    },
    async handleLogout(){
      //校验用户名
      this.handleLogoutLoading = true;
      try {
        // 调用Java后端接口
        const response = await this.$axios.post('/user/xaz/logout',{});
        if (response.flag) {
          
          // 清空 Vuex
          this.$store.commit('clearUser', null);
          this.handleLogoutLoading = false;
          this.$router.push('/PublishIndex'); // 跳转到 PublishIndex
          console.log(this.$store.state.user);
          
        } else{
          this.$message.error(response.msg);
          this.handleLogoutLoading = false;
        }

      } catch (error) {
        this.$message.error(error);
      }
    }
  }
}
</script>

<style lang="less" scoped deep>

.container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.container_self {
  background-color: #ffffff;
  width: 100%;                  
  height: 100vh;             /* 设置为 100vh，确保占满视口高度 */
  display: flex;             
  flex-direction: column;
}

// 头部样式
.pub_header {
  width: 100%;
  height: 90px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.pub_logo {
  color: #111111;
  font-size: 32px;
  font-weight: 800;
}

.index_menu {
  ul {
    display: flex;
    gap: 40px;
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.index_auth-buttons {
  .el-button {
    margin-left: 16px;
    padding: 8px 16px;
  }
}

// 主内容区布局
.dash-container {
  flex: 1;
  display: flex;
  width: 100%;
  height: 100%; // 更改此处为 100% 以填充整个可用空间
  overflow: hidden; // 防止内容溢出
}

// 侧边栏
.sidebar {
  width: 220px;
  height: 100%; // 更改此处为 100% 以填充整个可用空间
  background: #f7f9fc;
  border-right: 1px solid #eee;
  padding: 16px;
  .sidebar-li{
    padding : 10px 0px;
  }
  .sidebar-dash-btn, .sidebar-create-btn, .userProfile-btn {
      display: block;
      width: 185px;
      height: 45px;
      line-height: 45px;
      font-size: 16px;
      font-weight: 700;
      text-align: left;
      padding: 0px 20px;
  }

  .sidebar-dash-btn {
      background-color: #1e392a; /* 按钮背景颜色 */
      color: white; /* 按钮文字颜色 */
  }

  .sidebar-create-btn {
      background-color: #ffffff; /* 按钮背景颜色 */
      color: #111111; /* 按钮文字颜色 */
  }

  .userProfile-btn {
      position: absolute;
      left: 16px;
      bottom: 30px;
      z-index: 9;
      background-color: #1e392a; /* 按钮背景颜色 */
      color: white; /* 按钮文字颜色 */
  }
}

// 自适应内容区
.content {
  flex: 1;
  padding: 24px;
  
  .user-profile {
      position: relative;
      width: 500px;                        /* 设置宽度为 400px */
      margin: 8% auto;
      background-color: white;             /* 背景颜色为白色 */
      padding: 20px;                       /* 内边距 */
  }

  h3 {
      text-align: center;                  /* 文本居中 */
      margin: 30px 0;                     /* 上下外边距 */
  }

  p {
      text-align: center;                  /* 文本居中 */
      margin: 30px 0;                     /* 上下外边距 */
  }

  .profile-title {
      text-align: left;                    /* 靠左对齐 */
      font-weight: bold;                   /* 加粗文本 */
      margin: 15px 0;                     /* 上下外边距 */
  }

  .profile-item {
      margin: 15px 0;                     /* 上下外边距 */
      padding: 10px;                      /* 上下左右内边距 */
      border-bottom: 1px solid #eee;             /* 下边框 */
      border-radius: 4px;                 /* 添加边角圆角 */
      display: flex;                       /* 使用 flexbox 布局 */
      justify-content: space-between;      /* 主轴上元素分散 */
      align-items: center;                 /* 垂直居中对齐 */
  }

  .profile-item label {
      flex: 0 0 auto;                      /* label 固定宽度，不扩展 */
      margin-right: 10px;                  /* label 和 span 之间的间距 */
  }

  .profile-item span {
      flex: 1;                             /* span 占据剩余空间 */
      text-align: right;                    /* 右对齐 */
  }
  
  .logout-btn{
    position: absolute;
    right: 30px;
    bottom: 0px;
    z-index: 6;
    background-color: #1e392a; /* 按钮背景颜色 */
    color: white; /* 按钮文字颜色 */
    font-size: 14px;
  }
  

}

@primary-color: #2563eb;
@gray-color: #6b7280;
@border-color: #e5e7eb;

//修改密码弹框
.login-form {
  width: 50%;
  margin: 50px auto;
  .form-group {
    margin-bottom: 20px;

    .input-field {
      width: 100%;
      height: 40px;
      line-height: 40px;
      padding: 0px 10px;
      border: 1px solid @border-color;
      border-radius: 12px;
      font-size: 14px;
      &:focus {
        outline: none;
        border-color: @primary-color;
        box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
      }
    }
    .code-field{
      width: 50%;
      height: 40px;
      line-height: 40px;
      float: left;
      padding: 0px 10px;
      border: 1px solid @border-color;
      border-radius: 12px;
      font-size: 14px;
      &:focus {
        outline: none;
        border-color: @primary-color;
        box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
      }
    }

    .btn-getcode {
      width: 40%;
      height: 40px;
      line-height: 40px;
      float: right;
      padding: 0px 5px;
      background-color: @primary-color;
      color: white;
      border: none;
      border-radius: 12px;
      font-size: 14px;
      cursor: pointer;

      &:hover {
        background-color: darken(@primary-color, 5%);
      }
    }

  }

  .btn-continue {
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding: 0px 5px;
    background-color: @primary-color;
    color: white;
    border: none;
    border-radius: 12px;
    font-size: 18px;
    cursor: pointer;
    margin-top: 10px;

    &:hover {
      background-color: darken(@primary-color, 5%);
    }
  }
}


</style>
