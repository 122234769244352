import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from '@/utils/request/index.js'
import './utils/public-path'

import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';
import plTable from 'pl-table'
import 'element-ui/lib/theme-chalk/index.css'
import './layouts/common.less'
import './layouts/icon/icon.css'
import './layouts/elementcover.less'
import './layouts/public.less'
import './layouts/default.css'
import './layouts/style.less'

Vue.config.productionTip = false

Vue.use(ElementUI, { locale });
Vue.use(plTable);

Vue.prototype.$axios = axios({ urlPrefix: '/ai' });
Vue.prototype.$base = axios({ urlPrefix: '/base', baseURL: process.env.NODE_ENV == 'production' ? 'https://baseapp.houseinbox.com' : '' });
Vue.prototype.$datacenter = axios({ urlPrefix: '/datacenter', baseURL: process.env.NODE_ENV == 'production' ? 'https://portal.doccenter.net' : '' });

let app = null;
window.mount = () => {
  app = new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}

// 卸载应用
window.unmount = () => {
  app.$destroy();
  app.$el.innerHTML = '';
  app = null;
}

if (!window.__MICRO_APP_ENVIRONMENT__) {
  window.mount()
}
