
<template>
  <div class="container" ref="main">
    <backheader-demo ref="header" id="header" @addBackTab="addBackTab"></backheader-demo>
    <div class="middle" id="middle" ref="middle">
      <div class="mainWrapper">
        <backsidebar-demo @addBackTab="addBackTab1"></backsidebar-demo>
        <div class="mainContent">
          <div class="main">
            <el-tabs class ="main-el-tabs" v-model="activeTabName" @tab-remove="closeBackTab"  type="border-card" style="overflow: auto" ref="tabs">
                <el-tab-pane v-for="item in backTabList" :key="item.name" :name="item.name" :label="item.label" :closable="item.closable">
                    <component :is="item.component" 
                               :uiContext="item.uiContext"
                               :tabHeight="tabHeight"
                               @addBackTab="addBackTab">
                    </component>
                </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BackHeaderDemo from '@/components/common/header'
import BackSidebarDemo from '@/components/common/sidebar'

export default {
  name: 'index',
  transition: 'page',
  components: {
    'backheader-demo': BackHeaderDemo,
    'backsidebar-demo': BackSidebarDemo,
  },

  data: function() {
    return {
      'message': 'test01@39f.net',
      tabHeight: 0,
      middleHeight: "",
      backTabList: [
      ],
      activeTabName: 'HelloWorld_',
    }
  },
  mounted(){
    let _this= this;
    _this.loadingMaskVisible = false;
    _this.getMiddleHeight();
    _this.getTabHeight();
    window.onresize = function temp() {
      _this.getMiddleHeight();
      _this.getTabHeight();
    }
  },
  watch: {
    middleHeight (val) {
      if (!this.timer) {
        this.middleHeight = val;
        this.timer = true;
        let _this = this;
        setTimeout(function () {
            _this.getMiddleHeight()
            _this.timer = false;
        }, 400)
      }
    }
  },
  methods: {
    getMiddleHeight: function() {
      //获取浏览器窗口高度
      let _this= this;
      _this.middleHeight=0;
      if (window.innerHeight)
          _this.middleHeight = window.innerHeight;
      else if ((document.body) && (document.body.clientHeight))
          _this.middleHeight = document.body.clientHeight;
      if (document.documentElement && document.documentElement.clientHeight)
          _this.middleHeight = document.documentElement.clientHeight;
          let middle= _this.$refs.middle;
          if (middle) {
            middle.style.height=_this.middleHeight-90+ "px";
          }
    },
    getTabHeight: function() {
        var _this = this;
        _this.tabHeight = _this.$refs.tabs.$el.clientHeight;
    },
    clearBackTab: function() {
      var _this = this;
      _this.activeTabName = 'HelloWorld_';
      _this.backTabList = state.backTabList.filter(f => f.name == 'HelloWorld_');
    },
    addBackTabPage: function(page) {
      var _this = this;
      var name = page.name;
      var key = page.pageKey;
      var label = page.label;
      var componentUrl = page.components;
      var uiContext = page.uiContext;
      var index = `${name}_${key}`;
      if (_this.backTabList.filter(f => f.name == index) == 0) {
          let component = resolve => require([`@/components/${componentUrl}.vue`], resolve);
          _this.backTabList.push({
              label: label,
              name: index,
              disabled: false,
              closable: true,
              component: component,
              uiContext: uiContext
          })
      }
      _this.activeTabName = index;
    },
    addBackTab: function(name, label, id, uiContext) {
      var _this = this;
      var page = Domain.getPageDomain(name, label, id, uiContext);
      _this.addBackTabPage(page);
    },

    addBackTab1: function(menu) {
      var _this = this;
      var name = menu.aname;
      var key = 0;
      var label = menu.atitle;
      var componentUrl = menu.components;
      var uiContext = menu.uiContext;
      var index = `${name}_${key}`;
      if (_this.backTabList.filter(f => f.name == index) == 0) {
          let component = resolve => require([`@/pages/${componentUrl}.vue`], resolve);
          _this.backTabList.push({
              label: label,
              name: index,
              disabled: false,
              closable: true,
              component: component,
              uiContext: uiContext
          })
      }
      _this.activeTabName = index;
    },
    closeBackTab: function(name) {
      var _this = this;
      let tab = _this.backTabList.filter(f => f.name == name)[0];
      let index = _this.backTabList.indexOf(tab);
      if(_this.backTabList.length == 1) {
        _this.activeTabName = null;
      }else if (index != _this.backTabList.length - 1) {
        _this.activeTabName = _this.backTabList[index + 1].name;
      } else {
        _this.activeTabName = _this.backTabList[index - 1].name;
      }
      _this.backTabList = _this.backTabList.filter(f => f.name != name);
    }
  }
}
</script>



