<template>
  <div class="container">
    <div class="container_self">
      <!-- <header class="pub_header">
          <div class="pub_logo">AI Publish</div>
          <nav class="index_menu">
          </nav>
          <div class="index_auth-buttons">
          </div>
      </header> -->
      <div class="dash-container">
        <div class="sidebar">
            <ul>
              <li class="sidebar-li">
                <router-link to="/PublishDashboard">
                  <el-button icon="el-icon-picture-outline" class="sidebar-create-btn">Listing Manager</el-button>
                </router-link>
              </li>
              <li class="sidebar-li">
                <router-link to="/ProductPublishUsa">
                  <el-button icon="el-icon-circle-plus-outline" class="sidebar-create-btn">Create</el-button>
                </router-link>
              </li>
              <li class="sidebar-li">
                <router-link to="/PublishHistoryList">
                  <el-button icon="el-icon-circle-plus-outline" class="sidebar-create-btn">History</el-button>
                </router-link>
              </li>
              <li class="sidebar-li">
                <router-link to="/ProductAnalytics">
                  <el-button icon="el-icon-circle-plus-outline" class="sidebar-dash-btn">Analytics</el-button>
                </router-link>
              </li>
            </ul>
            
            <!-- 用户管理 -->
            <router-link to="/UserProfile">
              <el-button icon="el-icon-user-solid" class="userProfile-btn">User Profile</el-button>
            </router-link>
            
        </div>

        <PproductAnalytics :aid = aid></PproductAnalytics>

      </div>
    </div>

  </div>
</template>

<script>
import AiProductAnalytics from '@/components/AiProductAnalytics'
export default {
  name:"ProductAnalytics",
  components: {
    'PproductAnalytics': AiProductAnalytics
  },
  computed: {
    aid() {
      return this.$route.query.aid; // 获取aid参数
    },
  },
}
</script>

<style lang="less" scoped deep>
:root {
    --el-color-primary: #000; /* 主颜色 */
    --el-color-active: #000; /* 全局 active 颜色 */
    --el-button-hover-bg: #000; /* 修改按钮 hover 状态颜色 */
    --el-button-active-bg: #000; /* 修改按钮 active 状态颜色 */
}
.container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.container_self {
  background-color: #ffffff;
  width: 100%;                  
  height: 100vh;             /* 设置为 100vh，确保占满视口高度 */
  display: flex;             
  flex-direction: column;
}

// 头部样式
.pub_header {
  width: 100%;
  height: 90px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.pub_logo {
  color: #111111;
  font-size: 32px;
  font-weight: 800;
}

.index_menu {
  ul {
    display: flex;
    gap: 40px;
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.index_auth-buttons {
  .el-button {
    margin-left: 16px;
    padding: 8px 16px;
  }
}

// 主内容区布局
.dash-container {
  flex: 1;
  display: flex;
  width: 100%;
  height: 100%; // 更改此处为 100% 以填充整个可用空间
  overflow: hidden; // 防止内容溢出
}

// 侧边栏
.sidebar {
  width: 220px;
  height: 100%; // 更改此处为 100% 以填充整个可用空间
  background: #f7f9fc;
  border-right: 1px solid #eee;
  padding: 16px;
  .sidebar-li{
    padding : 10px 0px;
  }
  .sidebar-dash-btn, .sidebar-create-btn, .userProfile-btn {
      display: block;
      width: 185px;
      height: 45px;
      line-height: 45px;
      font-size: 16px;
      font-weight: 700;
      text-align: left;
      padding: 0px 20px;
  }

  .sidebar-dash-btn {
      background-color: #1e392a; /* 按钮背景颜色 */
      color: white; /* 按钮文字颜色 */
  }

  .sidebar-create-btn {
      background-color: #ffffff; /* 按钮背景颜色 */
      color: #111111; /* 按钮文字颜色 */
  }

  .userProfile-btn {
      position: absolute;
      left: 16px;
      bottom: 30px;
      z-index: 9;
      background-color: #ffffff; /* 按钮背景颜色 */
      color: #111111; /* 按钮文字颜色 */
  }
}

</style>
