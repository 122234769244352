<template>
  <div class="container">
    <div class="vervionWrapper">
      <!-- <el-select v-model="version" placeholder="select version" @change="chanageVersion" class="mini-el-select">
        <el-option
          v-for="item in versionList"
          :key="item.value"
          :label="item.title"
          :value="item.value">
        </el-option>
      </el-select> -->
      <el-radio-group v-model="version" size="small"  @change="chanageVersion">
        <el-radio-button
          v-for="(item, index) in versionList"
          :key="index"
          :label="item.value"
        >
          {{ item.title }}
        </el-radio-button>
      </el-radio-group>
    </div>
    <div class="publishWrapper">
      <div class="publishTop">
        <el-button class="pub-btn" @click="clickImageUpload">上传图片</el-button> 
        <span> &nbsp;&nbsp; or </span>
        <el-input class="pub-input" v-model="uploadedImageUrl" placeholder="Please Input ImageUrl" @change="chanageImageInput"></el-input>
        <el-button class="sub-btn" :loading="loading" @click="submitUrl">提交url</el-button>
        <el-button class="pub-btn" @click="msgExpandVisible =true">信息增强</el-button>
      </div>
      
      <!-- <el-progress :percentage="progress" :stroke-width="15" v-if="ifshowprogress"></el-progress> -->

      <div class="preview-image-container" v-if="showUploadImage && isshowImageUpload == false">
        <el-image
          :src="previewImageUrl"
          class="preview-image"
          fit="contain"
          :preview-src-list="[previewImageUrl]"
        >
        </el-image>
      </div>
      <!-- 图片上传区域 -->
      <div class="upload-section" v-if="isshowImageUpload">
        <el-upload
          v-if="!uploadedImage"
          ref="imageUpload"
          class="upload-area"
          drag
          action=""
          :limit="1"
          :on-change="handleFileChange"
          :before-upload="beforeUpload"
          :auto-upload="false"
          :show-file-list="false"
          accept="image/jpeg,image/png"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">拖拽文件到这里，或点击上传</div>
        </el-upload>
        <el-image
          v-else
          :src="uploadedImage"
          class="preview-image"
          fit="contain"
          :preview-src-list="[uploadedImage]"
        ></el-image>
        <div class="action-buttons">
          <el-button type="primary" class="button" @click="clearImages">清除</el-button>
          <el-button type="primary" class="button" @click="editImage">编辑</el-button>
        </div>
      </div>
      <div class="publishMain">
        <div class="opreTop">
          <div class="platfromSelect">
            <!-- <el-select v-model="platform" placeholder="select platform" @change="chanagePlatfrom">
              <el-option
                v-for="item in platformList"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select> -->
            <span class="platfromSelect-title">平台:</span>
            <el-radio-group v-model="platform" size="small"  @change="chanagePlatfrom">
              <el-radio-button
                v-for="(item, index) in platformList"
                :key="index"
                :label="item"
              >
                {{ item }}
              </el-radio-button>
            </el-radio-group>
          </div>

          <!-- <el-radio-group v-model="lanageActive" size="small" :disabled ="isDisabled" @change="chanageRadia">
            <el-radio-button
              v-for="(language, index) in lanageList"
              :key="index"
              :label="index"
            >
              {{ language }}
            </el-radio-button>
          </el-radio-group> -->

          <!-- <el-radio-group v-model="siteActive" size="small" >
            <el-radio-button
              v-for="(site, index) in filteredSiteList"
              :key="index"
              :label="index"
              :disabled="isSiteDisabled(site)"
            >
              {{ site }}
            </el-radio-button>
          </el-radio-group> -->
          <el-radio-group v-model="siteActive" size="small" @change="changeSite">
            <el-radio-button
              v-for="(site, index) in siteList"
              :key="index"
              :label="index"
            >
              {{ site }}
            </el-radio-button>
          </el-radio-group>


          <el-button class="export-btn" :loading="exportLoading" @click="exportProductPubLish">Download</el-button>
        </div>
        <el-table
          :data="dataList"
          stripe
          style="width: 100%"
          @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="50"></el-table-column>
          <el-table-column
            prop="categoryPath"
            label="Category"
            width="200">
          </el-table-column>
          <el-table-column
            prop="title"
            label="Title"
            width="350">
          </el-table-column>
          <!-- <el-table-column
            prop="description"
            label="Description">
          </el-table-column> -->
          <el-table-column
            prop="bulletPoints"
            label="BulletPoints">
            <template slot-scope="scope">
              <ul class="bullet-list">
                <li v-for="(point, index) in scope.row.bulletPoints" :key="index">
                  {{ point }}
                </li>
              </ul>
            </template>
          </el-table-column>
          <el-table-column label="Image." width="180">
            <template slot-scope="scope">
              <!-- <el-image
                :src="scope.row.imageUrlList[0].jpegUrl"
                class="preview-image"
                fit="contain"
                :preview-src-list="[scope.row.imageUrlList[0].jpegUrl]"
              >
              </el-image> -->
              <el-image
                v-if="scope.row.imageUrlList && scope.row.imageUrlList.length > 0"
                :src="scope.row.imageUrlList[0].jpegUrl"
                class="preview-image"
                fit="contain"
                @click="showBigImg(scope.row.imageUrlList[0].jpegUrl)"
              >
              </el-image>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            label="Action"
            width="80">
            <template slot-scope="scope">
              &nbsp;
              <el-badge :value="scope.row.imageUrlList.length" class="item" v-if="scope.row.imageUrlList.length > 0">
                <el-button @click="openDetailsDialog(scope.row)" type="text" size="mini">Details</el-button>
              </el-badge>
              <el-button v-else @click="openDetailsDialog(scope.row)" type="text" size="mini">Details</el-button>
              &nbsp;
              <el-button @click="generateMoreImg(scope.row)" type="text" size="mini">Image</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      
    </div>

    <!-- 列表点击大图弹框 -->
    <el-dialog :visible.sync="isshowBigImg">
      <img :src="bigImageUrl" class="bigImage"  />
    </el-dialog>

    <!-- 详情弹框 -->
    <el-dialog
      :visible.sync="dialogVisible"
      title="Details"
      width="500px"
      @close="closeDialog"
      top="2vh"
    >
      <div class="edtail-dialog-content">
        <div class="edtail-dialog-row">
          <label class="label">Category:</label>
          <div class="value">{{ selectedItem.categoryPath }}</div>
        </div>
        <div class="edtail-dialog-row">
          <label class="label">Title:</label>
          <div class="value">{{ selectedItem.title }}</div>
        </div>
        <div class="edtail-dialog-row">
          <label class="label">Description:</label>
          <div class="value">{{ selectedItem.description }}</div>
        </div>
        <div class="edtail-dialog-row">
          <label class="label">Bullet Points:</label>
          <div class="value">
            <ul class="bullet-list">
              <li v-for="(point, index) in selectedItem.bulletPoints" :key="index" >
                {{ point }}
              </li>
            </ul>
          </div>
        </div>
        <div class="edtail-dialog-row">
          <label class="label">Scenario:</label>
          <div class="value">{{ selectedItem.scenario }}</div>
        </div>
        <!-- 添加schema显示 -->
        <div class="edtail-dialog-row" v-show="selectedItem.schemaValue" v-for="(item, index) in selectedItem.schemaValue" :key="index">
            <label class="label">{{item.title}}:</label>
            <div class="value">{{ item.value}}</div>
        </div>
        
        <!-- 添加图片显示 -->
        <div class="edtail-dialog-row">
          <label class="label">Image:</label>
          <el-col v-for="(url, index) in selectedItem.imageUrlList" :key="index" :span="6">
            <el-image
              :src="url.jpegUrl"
              class="dialog-image"
              fit="contain"
              @click="handlePreview(url)"
            ></el-image>
          </el-col>
        </div>
        <!-- Walmart的 us 站点  -->
        <div class="edtail-dialog-row" v-if="platform =='Walmart' && siteList[siteActive] == 'US'">
          <label class="label">More Infomation:</label>
          <div class="value">
            <ul>
              <li v-for="(item,index) in selectedItem.excelDataList" :key="index"  v-show="item.display" class="infoma-item">
                <label>{{item.title}}：</label><br>
                <el-input v-model="item.cellValue"></el-input>
              </li>
            </ul>
          </div>
        </div>
        <!-- 添加excel数据 -->
        <el-table :data="[selectedItem.excelDataList]" stripe style="width: 100%" v-else>
          <el-table-column
            v-for="(item, index) in selectedItem.excelDataList" 
            :key="index" 
            :label="item.title" 
            :width="item.columnWidth">
            <template>
              {{ item.cellValue }} <!-- 直接显示每列的 cellValue -->
            </template>
          </el-table-column>
        </el-table>
        
          
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false" >保存</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="detailImageVisible">
      <img :src="detailCurrentImage" class="detail-bigImage"  />
    </el-dialog>

    <!-- 信息增强 -->
    <el-dialog title="信息增强"  :visible.sync="msgExpandVisible" top="2vh">
      <div class="msg-dialog-content">
        <div  class="dialogFieldLine">
          <table>
              <tbody>
                <tr>
                  <td colspan="3">
                    <label class="dialog_fr10">13位sku:</label>
                    <el-input v-model="skuNumber" class="large-el-input"></el-input>
                  </td>
                </tr>
                <tr>
                  <td colspan="3">
                    <label class="dialog_fr10">平台商品详情页链接(如amazon、walmart):</label>
                    <el-input v-model="productDetailUrl" class="large-el-input"></el-input>
                    <el-button @click="getPlatformPageData">提交获取网页信息</el-button>
                  </td>
                </tr>
              </tbody>
          </table>
        </div>
        <div v-if="platformPageData">
          <div class="edtail-dialog-row" v-show="platformPageData.title">
            <label class="label">Title:</label>
            <div class="value">{{platformPageData.title}}</div>
          </div>
          <div class="edtail-dialog-row" v-show="platformPageData.description">
            <label class="label">Description:</label>
            <div class="value">{{ platformPageData.description }}</div>
          </div>
          <div class="edtail-dialog-row" v-show="platformPageData.bulletList && platformPageData.bulletList.length > 0">
            <label class="label">Bullet Points:</label>
            <div class="value">
              <ul class="bullet-list">
                <li v-for="(point, index) in platformPageData.bulletList" :key="index" >
                  {{ point }}
                </li>
              </ul>
            </div>
          </div>
          <div class="edtail-dialog-row" v-show="platformPageData.imageList && platformPageData.imageList.length > 0">
            <label class="label">Image:</label><br>
            <el-col v-for="(url, index) in platformPageData.imageList" :key="index" :span="6">
              <el-image
                :src="url"
                class="dialog-image"
                fit="contain"
                @click="handlePreviewMsgExpand(url)"
              ></el-image>
            </el-col>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="msgExpandVisible = false">取 消</el-button>
        <el-button type="primary" @click="msgExpandVisible = false" >确 定</el-button>
      </span>
    </el-dialog>
    <!-- 页面加载效果 -->
    <div class="loadingMaskNoBg" v-if="pageloading">
      <div class="progress-container">
        <el-progress type="circle" :percentage="progress" :stroke-width="8"></el-progress>
      </div>
    </div>

    <!-- 图片编辑 -->
    <el-dialog :visible.sync="editlImageVisible" top="3vh" :append-to-body="true" @open="initCanvas" @opened="initImage" :before-close="cancelEditImage">

      <div class="canvasWrapper">
        <div class="canvasSizeWrapper">
          <div class="platfromSelect-title">画布尺寸:</div>
          <el-radio-group v-model="canvasSizeIndex" size="small"  @change="chanageCanvasSize">
            <el-radio-button
              v-for="(item, index) in canvasSizeList"
              :key="index"
              :label="index"
            >
              {{ item.title}}
            </el-radio-button>
          </el-radio-group>
        </div>
        <div class="canvasMain">
          <canvas id="fabricCanvas" :width="canvasWidth" :height="canvasHeight" style="border:1px solid #ccc;" class="canvasDiv"></canvas>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelEditImage">取 消</el-button>
        <el-button type="primary" @click="exportCanvas" >生成</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>

import * as DownloadUtils from '@/utils/DownloadUtils.js';
import * as fabric from 'fabric';
import { Canvas, FabricImage } from 'fabric'
import stringUtil from '@/utils/stringUtil.js'

export default {
  
  data() {
    return {
      loading: false,
      uploadedFile: null,
      uploadedImage: null, // 用户上传的图片
      uploadedImageUrl: null,//输入框图片地址
      sceneData: [], // 九宫格数据
      dialogVisible: false, // 弹窗显示控制
      selectedItem: {}, // 存储选中的九宫格项的详细信息
      taskId: null,
      taskInterval: null,
      progressInterval:null,
      progress: 0,
      ifshowprogress: false,
      showUploadImage: false,
      previewImageUrl: null,
      isshowBigImg: false,
      bigImageUrl:"",
      dataList: [],
      multipleSelection:[],
      isshowImageUpload: false,
      cutDialogVisible: false,
      exportLoading: false,
      croppedImage:null,
      pageloading: false,
      lanageList:["English","Spanish","French","German","Italian"],
      lanageActive:0,
      siteList:["US","MX","FR","DE","UK","CA"],
      siteActive:0,
      isDisabled: true,
      egDatalist:[],
      allData:{},
      platformList:["Amazon","Amazon Vendor","Walmart","Temu","Confo","MDM","BUT","XXXLutz"],
      platform:"Amazon",
      disabledSites: {  // 根据平台禁用的站点
        "Amazon": ["FR","UK","DE","IT","ES"],
        "Amazon Vendor": ["MX","CA","FR","UK","DE","IT","ES"],
        "Walmart": ["MX","CA","FR","UK","DE","IT","ES"],
        "Temu": ["MX","CA","FR","UK","DE","IT","ES"],
        "Confo": ["US","MX","CA","UK","DE","IT","ES"],
        "MDM": ["US","MX","CA","FR","DE","IT","ES"],
        "BUT": ["US","MX","CA","UK","DE","IT","ES"],
      },
      detailImageVisible:false,
      cropperLoading: false,
      detailCurrentImage:null,
      msgExpandVisible: false,
      skuNumber: null,
      productDetailUrl: null,
      platformPageData:null,
      version:"basic",
      versionList:[
        {
        "title":"AI刊登基础版",
        "value": "basic"
        },
        {
        "title":"AI刊登增强版",
        "value": "creative"
        },
        {
          "title":"AI刊登USA版",
          "value": "AI_Usa"
        }
      ],
      imageEditorDialog: {
        dialogFormVisible: false,
        imageUrl: ""
      },
      editlImageVisible: false,
      canvas: null,
      canvasWidth: 600,
      canvasHeight: 600,
      canvasSizeList:[
        {
        "title":"方图1：1",
        "width": 600,
        "height": 600
        },
        {
        "title":"竖图3：4",
        "width": 450,
        "height": 600
        }
      ],
      canvasSizeIndex:0,
    };
  },
  computed: {
    filteredSiteList() {
      return this.siteList; // 该计算属性可以根据实际需要进行变化
    },
  },
  mounted(){
    // this.$nextTick(() => {
    //   this.initCanvas();
    // });
    
  },
  destroyed() {
    this.cancelTaskInterval();
  },
  methods: {
    // 打开弹窗并显示选中的项的详情
    openDetailsDialog(item) {
      this.selectedItem = item;
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    chanageVersion(val){
      if(this.version == 'AI_Usa'){
        var routeUrl = 'https://homycasa-frontend.vercel.app/create';
        window.open(routeUrl, '_blank');
      }
    },
    chanagePlatfrom(){
      this.allData = {};
      this.egDatalist = [];
      this.dataList = [];
      this.siteActive = 0; // 选择新平台时，重置选中的站点，默认第一个
      this.filterSiteByPlatfrom()//过滤可选站点
      this.cancelTaskInterval();
    },
    changeSite(){
      //重新请求接口
       this.submitImage();
    },
    filterSiteByPlatfrom(){
      if (!this.platform) return false; 
      if(this.platform =='Amazon'){
        this.siteList = ["US","MX","FR","DE","UK","CA"];
      }else if(this.platform =='Amazon Vendor'){
        this.siteList = ["US","MX","FR","DE","UK",];
      }else if(this.platform =='Walmart'){
        this.siteList = ["US"];
      }else if(this.platform =='Temu'){
        this.siteList = ["US"];
      }else if(this.platform =='Confo'){
        this.siteList = ["FR"];
      }else if(this.platform =='MDM'){
        this.siteList = ["FR"];
      }else if(this.platform =='BUT'){
        this.siteList = ["FR"];
      }else if(this.platform =='XXXLutz'){
        this.siteList = ["DE"];
      }
    },
    isSiteDisabled(site) {
      // 根据当前选择的平台判断站点是否禁用
      if (!this.platform) return false; // 如果未选择平台，则都不禁用
      return this.disabledSites[this.platform]?.includes(site);
    },
    chanageImageInput(){
      this.uploadedImage = null;
      this.isshowImageUpload = false;
    },
    //summit改为触发url下载功能,和展示画布编辑框
    submitUrl(){
      var _this = this;
      if(!_this.uploadedImageUrl){
        _this.$message.error("请输入图片Url!");
        return;
      }
      //用url 方式，需要清除上传文件的数据
      _this.uploadedFile = null;//清除图片上传文件
      _this.uploadedImage = null;//清除图片上传文件
      _this.isshowImageUpload = false;//隐藏上传文件模块
      _this.editImage() // 打开画布编辑框
    },
    async submitImage() {
      if(!this.platform){
        this.$message.error("请选择平台");
        return;
      }
      //如果有图片上传，那先处理图片
      if (this.uploadedImage) {
          try {
            this.loading = true;
            const uploadResponse = await this.uploadImage();

            if (!uploadResponse.flag) {
              this.$message.error(uploadResponse.msg);
              return;
            }

            this.uploadedImageUrl = uploadResponse.url;
            this.previewImageUrl = this.uploadedImageUrl;
            this.$message.success("图片上传成功");
          } catch (error) {
            console.log('uploadImage error:', error);
            this.$message.error("图片上传失败，请重试");
            return;
          } finally {
            this.loading = false;
          }
      }

      if (!this.uploadedImageUrl) {
        this.$message.error("请先上传图片或输入图片Url");
        return;
      }

      try {
        this.loading = true;
        const submitTaskResponse = await this.submitTask(this.uploadedImageUrl);

        if (!submitTaskResponse.flag) {
          this.$message.error(submitTaskResponse.msg);
          return;
        }

        this.taskId = submitTaskResponse.taskId;
        this.$message.success("任务提交成功");
      } catch (error) {
        console.log('submitTask error:', error);
        this.$message.error("任务提交失败，请重试");
        return;
      } finally {
        this.loading = false;
      }

      this.dataList = [];
      this.progress = 0;
      this.ifshowprogress = true;
      this.showUploadImage = true;
      this.previewImageUrl = this.uploadedImageUrl;
      this.loading = true;
      this.isDisabled = true;
      this.pageloading = true;

      this.taskInterval = setInterval(async () => {
        this.loading = true;
        const queryTaskResponse = await this.queryTask(this.taskId);

        if (!queryTaskResponse.flag) {
          this.cancelTaskInterval();
          this.$message.error(queryTaskResponse.msg);
          this.loading = false;
          this.pageloading = false;
          return;
        }

        this.egDatalist = queryTaskResponse.resultList;
        this.dataList = queryTaskResponse.resultList;


        if(this.dataList.length < 10){
          this.progress += 5; // 增加进度
          if (this.progress >= 90) {
            this.progress = 90; // 防止超过100
          }
        }else if(this.dataList.length ==10){
            this.progress = 100; // 防止超过100
        }
        
        if (queryTaskResponse.isCompleted) {
          this.cancelTaskInterval();
          this.$message.success("生成成功");
        }
      }, 5000);
    },
    cancelTaskInterval() {
      if (this.taskInterval) {
        clearInterval(this.taskInterval);
        this.taskId = null;
        this.taskInterval = null;
        this.loading = false;
        this.progress = 0;
        this.ifshowprogress = false;
        this.isDisabled = false;
        this.pageloading = false;
        this.isshowImageUpload = false;
      }
    },
    async submitTask(imageUrl, type) {
      return this.$axios.get('/productPublish/xaz/submitTask?platform='+this.platform, {
        params: {
          env: "test",
          url: imageUrl,
          skuNumber: this.skuNumber || null,
          version: this.version,
          site: this.siteActive !=null?this.siteList[this.siteActive]:null
        }
      });
    },
    async queryTask(taskId) {
      return this.$axios.get('/productPublish/xaz/queryTask', {
        params: {
          taskId: taskId,
        }
      });
    },
    exportProductPubLish(){
      if(this.multipleSelection == null || this.multipleSelection.length < 1){
        this.$message.error("请选择需要下载的数据");
        return;
      }
      this.exportLoading = true;
      this.$axios.post('/productPublish/xaz/downloadZip?skuNumber='+this.skuNumber, this.multipleSelection,{responseType:'blob'}).then(async result => {
        if (result.response.headers.flag ==='false' ) {
          const msg = await result.text();
          this.$message.error(msg);
          return;
        }
        DownloadUtils.download(result,'publish.zip');
      })
      .finally(() =>{
        this.exportLoading = false;
      });
    },
    showBigImg(url){
      this.bigImageUrl = url;
      this.isshowBigImg = true;
    },
    handleSelectionChange(obj){
        this.multipleSelection = obj;
    },
    clickImageUpload(){
      this.isshowImageUpload = true;
      this.showUploadImage = false;
      this.previewImageUrl = null;
      this.uploadedImageUrl = null;
    },
    beforeUpload(file) {
      const isImage = file.type === "image/png" || file.type === "image/jpeg";
      const isLt3M = file.size / 1024 / 1024 < 3; // 限制文件大小小于3MB
      if (!isImage) {
        this.$message.error("仅支持 JPG/PNG 格式的图片");
        return false;
      }
      if (!isLt3M) {
        this.$message.error("图片大小不能超过 3MB");
        return false;
      }
      return true;
    },
    handleFileChange(file, fileList) {
      if (fileList.length > 0) {
        if (!this.beforeUpload(file.raw)) {
          this.$refs.imageUpload.clearFiles();
          return;
        }
        this.uploadedFile = file;
        this.uploadedImage = URL.createObjectURL(file.raw);
        this.editImage() // 打开画布编辑框
      }
    },
    clearImages() {
      this.uploadedFile = null;
      this.uploadedImage = null;
      this.uploadedImageUrl = null;
      this.sceneData = [];
      this.cancelTaskInterval();
      this.isshowImageUpload = false;
      this.cancelEditImage();//清除画布
      this.$message.success("图片已清除");
    },
    async uploadImage() {
      var formData = new FormData();
      if(this.uploadedFile.raw){
        formData.append("file", this.uploadedFile.raw);
      }else{
        formData.append("file", this.uploadedFile);
      }
      
      return this.$axios.post('/productScenario/xaz/uploadFile', formData);
    },
    chanageRadia(){
      if(this.lanageActive == 0){
        this.dataList = this.egDatalist;
        return;
      }
      this.translateToLanguage();
    },
    translateToLanguage() {
      var lan = this.lanageList[this.lanageActive];
      this.pageloading = true;
      this.isDisabled = true;
      if(this.allData[lan]){
        this.dataList = this.allData[lan];
        return;
      }
      this.$axios.post('/productPublish/xaz/translateToLanguage?language='+ lan, this.egDatalist).then(async result => {
        if (!result.flag) {
          this.$message.error(result.msg);
          this.pageloading = false;
          this.isDisabled = false;
          return;
        }
        this.allData[lan] = result.langResultList;
        this.dataList = result.langResultList;
        this.pageloading = false;
        this.isDisabled = false;
      })

    },
    generateMoreImg(row){
      this.pageloading = true;
      this.progress = 0;
      //每2秒进度条增加5
      this.progressInterval = setInterval(async () => {
        this.progress += 10; // 增加进度
        if (this.progress >= 90) {
          this.progress = 90; // 防止超过100
        }
      }, 2000);
      var dataMap={
        "imageUrl": this.uploadedImageUrl,
        "prompt": row.scenario,
        "version": this.version,
        "n": 3,
        "category":row.categoryPath

      }
      this.$axios.post('/productPublish/xaz/generateImage', dataMap).then(async result => {
        if (!result.flag) {
          this.$message.error(result.msg);
          this.pageloading = false;
          this.isDisabled = false;
          this.pageloading = false;
          return;
        }
        for(var i = 0; i < result.imageUrlList.length;i++){
          row.imageUrlList.push(result.imageUrlList[i]);
        }
        this.progress = 100;
        clearInterval(this.progressInterval); // 停止定时器
        this.pageloading = false;
        this.$message.success("更多图片生成成功，请点击Details 查看");
        
      })
    },
    getPlatformPageData(){
      this.pageloading = true;
      if(this.productDetailUrl == null || this.productDetailUrl==''){
        this.$message.error("请填写平台商品详情页连接！");
        this.pageloading = false;
        return;
      }
      this.$axios.post('/productPublish/xaz/getPlatformPageData?url='+ this.productDetailUrl).then(async result => {
        if (!result.flag) {
          this.$message.error(result.msg);
          this.pageloading = false;
          return;
        }
        this.platformPageData = result.platformPageData;
        console.log(this.platformPageData);
        this.pageloading = false;
        this.$message.success("Success");
        
      })
    },

    handlePreview(url) {
      this.detailCurrentImage = url.jpegUrl;
      this.detailImageVisible = true;
    },
    handlePreviewMsgExpand(url) {
      this.detailCurrentImage = url;
      this.detailImageVisible = true;
    },

    //canvas 图片编辑
    initCanvas() {
      if(this.canvas != null) return;
      //var dpr = window.devicePixelRatio || 1;
      this.$nextTick(() => {
        this.canvas = new fabric.Canvas('fabricCanvas', {
          targetFindTechnique: 'nearest',//创建Canvas时设置DPI
          perPixelTargetFind: true,// 创建Canvas时设置DPI
          enableRetinaScaling:false, // 或者 false，视具体情况而定
          width: this.canvasWidth,
          height: this.canvasHeight,
          
        });
        
      })
    },

    async initImage() {
      var _this = this;
      if(_this.isshowImageUpload == false){//用url 方式
          if (_this.uploadedImageUrl) {
            // fabric.FabricImage.fromURL(_this.uploadedImageUrl,{crossOrigin: 'anonymous'}).then((img) => {
            //   _this.canvas.add(img);
            //   //初始化原图缩放到画布大小的90%
            //   // 获取画布的宽度和高度
            //   var canvasWidth = _this.canvas.width;
            //   var canvasHeight = _this.canvas.height;

            //   // 计算缩放比例（90%）
            //   var scaleX = (canvasWidth * 0.9) / img.width;
            //   var scaleY = (canvasHeight * 0.9) / img.height;

            //   // 选择最小比例，以保持纵横比
            //   var scale = Math.min(scaleX, scaleY);
            //   // 缩放图像
            //   img.scale(scale);

            //   img.set({
            //     imageSmoothingEnabled: false,// 关闭图片插值，确保图片在拉宽时不会出现重影
            //     lockUniScaling: true, // 锁定宽高比，使其只能等比例缩放
            //     hasControls: true,    // 允许使用控制点
            //     hasBorders: true,
            //     left: (canvasWidth - img.width * scale) / 2,
            //     top: (canvasHeight - img.height * scale) / 2,
            //     crossOrigin: 'anonymous' // 处理跨域请求
            //   });
            //   // 监听缩放事件（可选，提升用户体验）
            //   _this.canvas.on('object:scaling', function(e) {
            //       var obj = e.target;
            //       obj.setControlsVisibility({
            //           mt: false, // 不显示上中点
            //           mb: false, // 不显示下中点
            //           ml: false, // 不显示左中点
            //           mr: false, // 不显示右中点
            //           bl: true, // 显示左下点
            //           br: true, // 显示右下点
            //           tl: true, // 显示左上点
            //           tr: true  // 显示右上点
            //       });
            //       // 同步 scaleX 和 scaleY 以保持宽高比
            //       obj.scaleY = obj.scaleX; 
            //   });
            //   _this.canvas.renderAll();
              
            // }, { crossOrigin: 'anonymous' });// 处理跨域请求

            //解决跨域问题，把url 下载成文件
            try {
            // 使用 axios.get 发送 GET 请求
            const response = await this.$axios.get('/productPublish/xaz/downloadByUrl', {
              params: { url: this.uploadedImageUrl }, // 将 URL 作为查询参数发送
              responseType: 'blob' // 期望返回的类型是 Blob
            });
            const dataUrl = URL.createObjectURL(response);
            if (dataUrl) {
                  fabric.FabricImage.fromURL(dataUrl).then((img) => {
                    _this.canvas.add(img);

                    //初始化原图缩放到画布大小的90%
                    // 获取画布的宽度和高度
                    var canvasWidth = _this.canvas.width;
                    var canvasHeight = _this.canvas.height;

                    // 计算缩放比例（90%）
                    var scaleX = (canvasWidth * 0.9) / img.width;
                    var scaleY = (canvasHeight * 0.9) / img.height;

                    // 选择最小比例，以保持纵横比
                    var scale = Math.min(scaleX, scaleY);
                    // 缩放图像
                    img.scale(scale);

                    img.set({
                      imageSmoothingEnabled: false,// 关闭图片插值，确保图片在拉宽时不会出现重影
                      lockUniScaling: true, // 锁定宽高比，使其只能等比例缩放
                      hasControls: true,    // 允许使用控制点
                      hasBorders: true,
                      left: (canvasWidth - img.width * scale) / 2,
                      top: (canvasHeight - img.height * scale) / 2
                    });
                    // 监听缩放事件（可选，提升用户体验）
                    _this.canvas.on('object:scaling', function(e) {
                        var obj = e.target;
                        obj.setControlsVisibility({
                            mt: false, // 不显示上中点
                            mb: false, // 不显示下中点
                            ml: false, // 不显示左中点
                            mr: false, // 不显示右中点
                            bl: true, // 显示左下点
                            br: true, // 显示右下点
                            tl: true, // 显示左上点
                            tr: true  // 显示右上点
                        });
                        // 同步 scaleX 和 scaleY 以保持宽高比
                        obj.scaleY = obj.scaleX; 
                    });
                    _this.canvas.renderAll();
                    
                  }, { crossOrigin: 'anonymous' });// 处理跨域请求
                }
                
            
          } catch (error) {
            console.error('下载图片失败:', error);
          }
        }
         
      }else{//用文件上传方式
        var file
        if(this.uploadedFile.raw){
          file = this.uploadedFile.raw;
        }else{
          file = this.uploadedFile;
        }
        
        const reader = new FileReader();
        reader.onload = (e) => {
          const dataUrl = e.target.result;
          if (dataUrl) {
            fabric.FabricImage.fromURL(dataUrl).then((img) => {
              _this.canvas.add(img);

              //初始化原图缩放到画布大小的90%
              // 获取画布的宽度和高度
              var canvasWidth = _this.canvas.width;
              var canvasHeight = _this.canvas.height;

              // 计算缩放比例（90%）
              var scaleX = (canvasWidth * 0.9) / img.width;
              var scaleY = (canvasHeight * 0.9) / img.height;

              // 选择最小比例，以保持纵横比
              var scale = Math.min(scaleX, scaleY);
              // 缩放图像
              img.scale(scale);

              img.set({
                imageSmoothingEnabled: false,// 关闭图片插值，确保图片在拉宽时不会出现重影
                lockUniScaling: true, // 锁定宽高比，使其只能等比例缩放
                hasControls: true,    // 允许使用控制点
                hasBorders: true,
                left: (canvasWidth - img.width * scale) / 2,
                top: (canvasHeight - img.height * scale) / 2
              });
              // 监听缩放事件（可选，提升用户体验）
              _this.canvas.on('object:scaling', function(e) {
                  var obj = e.target;
                  obj.setControlsVisibility({
                      mt: false, // 不显示上中点
                      mb: false, // 不显示下中点
                      ml: false, // 不显示左中点
                      mr: false, // 不显示右中点
                      bl: true, // 显示左下点
                      br: true, // 显示右下点
                      tl: true, // 显示左上点
                      tr: true  // 显示右上点
                  });
                  // 同步 scaleX 和 scaleY 以保持宽高比
                  obj.scaleY = obj.scaleX; 
              });
              _this.canvas.renderAll();
              
            }, { crossOrigin: 'anonymous' });// 处理跨域请求
          }
        };
        reader.readAsDataURL(file);

      }
      
    },
    editImage(){
      var _this = this;
      _this.editlImageVisible = true;
    },
    cancelEditImage(){
      this.canvas.clear(); // 清空画布上的所有对象
      this.editlImageVisible = false;
    },
    chanageCanvasSize(val){
      this.canvasWidth = this.canvasSizeList[val].width;
      this.canvasHeight = this.canvasSizeList[val].height;
      // 更新 Fabric.js canvas 尺寸
      this.canvas.setWidth(this.canvasWidth);
      this.canvas.setHeight(this.canvasHeight);

      // 先清除画布
      this.canvas.clear(); // 清空画布上的所有对象
      this.initCanvas();
      this.initImage()
      
    },
    exportCanvas(){
      var _this = this;
      // const dataURL = this.canvas.toDataURL({
      //   width: this.canvas.width,
      //   height: this.canvas.height,
      //   left: 0,
      //   top: 0,
      //   format: 'png',
      // });
      // const link = document.createElement('a');
      // link.download = 'canvas.png';
      // link.href = dataURL;
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
      

      console.log(_this.canvas);
      // 获取画布的 Data URL
      const dataUrl = _this.canvas.toDataURL({
        format: 'png',
        multiplier: 2 // 以 2 倍图像质量导出
      });

      // 转换 Data URL 为 Blob
      fetch(dataUrl).then(res => res.blob()).then(blob => {
            // 创建 File 对象
            var file = new File([blob], 'canvas-image.png', { type: 'image/png' });
            console.log("exportCanvas",file); // 在控制台查看 File 对象
            console.log('after fetch uploadedImageUrl:', _this.uploadedImageUrl)
            _this.uploadedFile = file;
            _this.uploadedImage = URL.createObjectURL(file);
            
            _this.previewImageUrl = URL.createObjectURL(file);
            //请求接口
            _this.submitImage();
           
        }).catch(err => alert('Error converting to file: ',err));

      // 先清除画布
      _this.canvas.clear(); // 清空画布上的所有对象
      _this.editlImageVisible = false;//hide 弹框
      
    },
    
    
  },
};
</script>

<style scoped lang="less">

.view-details {
  color: #409eff;
  cursor: pointer;
  font-size: 14px;
  transition: color 0.3s ease;
}

.view-details:hover {
  color: #66b1ff;
}

.dialog-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dialog-row {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.label {
  display: inline-block;
  width: 220px; /* 固定标签宽度 */
  text-align: right; /* 右对齐标签部分 */
  margin-right: 10px;
  color: #000; /* 设置文字颜色为黑色 */
  font-weight: bold; /* 如果需要加粗文字 */
}

.value {
  flex: 1;
  text-align: left;
  word-wrap: break-word; /* 允许单词被换行 */
  word-break: break-word; /* 支持浏览器对于长单词的自动换行 */
  white-space: pre-wrap; /* 保留空白和换行符，允许自动换行 */
}

.bullet-list {
  padding-left: 10px; /* 给列表项增加左边距 */
  margin: 0; /* 去除默认的外边距 */
}

.bullet-list li {
  text-align: left;
  list-style: disc; /* 使用实心点符号 */
  // margin-bottom: 5px; /* 每个列表项之间的间距 */
}

.bullet-list li::marker {
  //color: #409eff; /* 单独控制实心点的颜色 */
  font-size: 16px; /* 可以调整实心点的大小 */
}

.edtail-dialog-content{
  width: 99%;
  height: 850px;
  overflow: auto;
}
.edtail-dialog-row{
  margin-bottom: 8px;
  clear: both;
  .label {
    display: inline-block;
    float: left;
    width: 15%; /* 固定标签宽度 */
    text-align: right; /* 右对齐标签部分 */
    margin-right: 10px;
    color: #000; /* 设置文字颜色为黑色 */
    font-weight: bold; /* 如果需要加粗文字 */
  }

  .value {
    width: 83%;
    display: inline-block;
    float: left;
    text-align: left;
    word-wrap: break-word; /* 允许单词被换行 */
    word-break: break-word; /* 支持浏览器对于长单词的自动换行 */
    white-space: pre-wrap; /* 保留空白和换行符，允许自动换行 */


    .infoma-item{
      display: inline-block;
      width: 33%; 
      margin: 10px 0px;
      text-align: left;
    }
  }
}
.msg-dialog-content{
  width: 99%;
  min-height: 700px;
  overflow: auto;
}

.dialog-image {
  width: 250px;
  height: 250px;
  object-fit: contain;
  margin-top: 10px;
}
.bigImage{
  display: block; 
  width: auto;  /* 使宽度自动 */
  height: auto; /* 使高度自动 */
  max-width: 100%; /* 防止图片超出容器 */
  margin: 0px auto;
}
.detail-bigImage{
  display: block; 
  width: auto;  /* 使宽度自动 */
  height: auto; /* 使高度自动 */
  max-width: 100%; /* 防止图片超出容器 */
  margin: 0px auto;
}
.preview-image-container {
  margin-top: 15px;
  display: flex;           /* 使用 flex 布局 */
  justify-content: center; /* 水平居中 */
  align-items: center;     /* 垂直居中 */
  width: 100%;             /* 父容器宽度 */
}

.preview-image {
  width: 100px;
  height: 100px;
  object-fit: contain;
  cursor: pointer;
}

.opreTop{
  height: 50px;
  line-height: 32px;
  .platfromSelect{
    display: inline-block;
    float: left;
    margin-right: 50px;
    .platfromSelect-title{
      display: inline-block;
      float: left;
    }
    /deep/.el-elect{
      width: 150px;
    }
  }
  .el-radio-group{
    float: left;
  }
  .export-btn{
    display: inline-block;
    float: right;

  }
}

.container {
  width: 100%;
  position: relative;
  .publishWrapper{
    width: 80%;
    margin: auto;
    padding: 20px;
    text-align: center;

    .publishTop{
      display: flex;
      margin-bottom: 15px;
      height: 55px;
      line-height: 55px;
      .pub-input{
        flex: 1;
        display: inline-block;
        margin-right: 10px;
        margin-left: 10px;
      }
      .pub-btn{
        display: inline-block;
        width: 120px;
        margin-top:10px;
      }
      .sub-btn{
        display: inline-block;
        width: 120px;
        //height: 40px;
        //line-height: 40px;
        margin-top:12px;
        background-color: #67c23a;
      }
      .sub-btn:hover{
        background: #85ce61 !important;
        color: #ffffff !important;
      }
    }
    .publishMain{
      widows: 100%;
      min-height: 650px;
      padding: 10px;
      margin-top: 15px;
      border: 1px solid #e3e4e5;
      border-radius: 10px;
      .first-li{
        height: 40px;
      }

    }

    .bigImsge{
      width: 800px;
      margin: 0px auto;

      img{
        object-fit: contain;
        width: 800px;
        height: 800px;
      }
    }
  }
  
  .vervionWrapper{
    position: absolute;
    top: 30px;
    left: 45px;
    z-index: 5;
    width: 120px;
    .el-radio-button {
        border: 1px solid #e3e4e5;
        border-radius: 4px;
        margin-bottom: 5px;
    }

  }
}

.table-li{
  display: list-item;
  text-align: left;
  border-bottom: none;
  list-style: disc; 
  padding-left: 5px; 
}
/deep/ .el-table {
  td,
  th {
    img{
      width: 100px;
      height: 100px;
    }
  }
}
.tableimg{
    width: 150px !important;
    height: 150px !important;
}


.cropperWrapper{
  width:80%;
  height: 720px;
  margin:0px auto;
}
/deep/ .el-image-viewer__canvas{
  img{
    width: 100%;
    height: 100%;
    max-height: none;
    max-width: none;
  }
}


.canvasWrapper{
    width: 60%;
    height: 620px;
    margin: 0px auto;

    .canvasSizeWrapper{
      width: 200px;
      float: left;
    }
    .canvasMain{
      width: 600px;
      float: right;
    }
}

.canvasDiv {
  background: url(../assets/22.jpg) repeat;
}
.loadingMaskNoBg{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: rgba(51, 51, 51, 0.5);

  .progress-container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2002;
    opacity: 1;
    width: 200px; /* 调整圆形进度条的宽度 */
    height: 200px; /* 调整圆形进度条的高度 */

    .el-progress{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 200px;
      .el-progress-circle{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 200px;
      }
      // /deep/ .el-progress-circle__track {
      //   stroke: rgba(255, 255, 255, 0.303);
      // }

      /deep/ .el-progress__text {
        font-size: 20px !important;
        color: #FFF !important;
      }
    }
    
  }
}

</style>
