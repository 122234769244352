<template>
  <div class="container">
    <div class="container_self">
      <header class="pub_header">
          <div class="pub_logo">AI Publish</div>
          <nav class="index_menu">
              
          </nav>
          <div class="index_auth-buttons">
             
          </div>
      </header>
      <div class="login-container">
        <h1 class="title">Create an Account</h1>
        
        <form class="login-form" @submit.prevent="handleRegister">
          <div class="pass-title">
            Set a Username to create your account.
            <el-tooltip class="item" effect="light" placement="top-start">
              <div slot="content">Username Rules:<br>
                                  1.Length: 5 - 30 characters<br>
                                  2. Allowed Characters:<br>
                                  Letters (A-Z, a-z)<br>
                                  Numbers (0-9)<br>
                                  3.Underscore (_) and dot (.)<br>
                                  4.Usernames must start with a letter.<br>
                                  5.Spaces and other special characters (e.g., !@#$%^&*) are not allowed.<br>
                                  6.Offensive, sensitive words or official organization names are prohibited.</div>
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </div>
          <div class="form-group">
            <input type="text" placeholder="Username" class="input-field" v-model="registerUser.username" required @input="validateUsernameInput" >
          </div>
          <p v-if="usernameerrorMessage" class="error-message">{{ usernameerrorMessage }}</p>
          <div class="form-group">
            <input type="email" placeholder="Your Email Address" class="input-field" v-model="registerUser.email" required @input="validateEmailInput">
          </div>
          <p v-if="emailerrorMessage" class="error-message">{{ emailerrorMessage }}</p>
          <div class="form-group">
            <input type="email" placeholder="Code" class="code-field" v-model="registerUser.captcha" required>
            <el-button class="btn-getcode" @click="getEmailCaptcha" :loading="getEmailCaptchaLoading">Get Code</el-button>
          </div>
          <div class="pass-title">
            Set a password to create your account.
            <el-tooltip class="item" effect="light" placement="top-start">
              <div slot="content">The password must meet the following criteria:<br>
                                  1.Length: 8 - 64 characters<br>
                                  2.Lowercase letters (a-z)<br>
                                  3.Numbers (0-9)</div>
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </div>
          <div class="form-group">
            <input :type="isPasswordVisible ? 'text' : 'password'" 
                placeholder="Password" class="input-field" 
                v-model="registerUser.password" required @input="validatePasswordInput" 
            />
            <div @click="togglePasswordVisibility" class="toggle-visibility">
              <img src="../assets/view-close.svg" v-if="isPasswordVisible">
              <img src="../assets/view-open.svg" v-else>
            </div>
          </div>
          <p v-if="passworderrorMessage" class="error-message">{{ passworderrorMessage }}</p>
          <div class="form-group">
            <input :type="isConfirmPasswordVisible ? 'text' : 'password'"  
                placeholder="Re-enter your password to confirm" class="input-field" 
                v-model="confirmPassword" required @input="validateConfirmPassword" 
            />
            <div @click="toggleConfirmPasswordVisibility" class="toggle-visibility">
              <img src="../assets/view-close.svg" v-if="isConfirmPasswordVisible">
              <img src="../assets/view-open.svg" v-else>
            </div>
          </div>
          
          <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
          <el-button type="submit" class="btn-continue" @click="handleRegister" :loading="handleRegisterLoading">Continue</el-button>
        </form>

        <div class="signup-link">
          Already have an account? <a href="/PublishLogin" class="link">Log In</a>
        </div>

        <!-- <div class="divider">
          <span class="divider-text">OR</span>
        </div>

        <div class="btn-google">
          <img src="../assets/google.svg" class="btn-google-img">
          Log in with Google
        </div>
        <div class="pass-title">
          By registering, you agree to our Terms of 
          Service and Privacy Policy.
        </div>
        <div class="footer-links">
          <a href="#" class="link">Terms of Service</a>
          <span class="separator">|</span>
          <a href="#" class="link">Privacy Policy</a>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name:"PublishRegister",
  data(){
    return{
      registerUser:{
        username: '',
        password: '',
        email:'',
        captcha:''
      },
      confirmPassword: null,
      errorMessage:'',
      usernameerrorMessage:'',
      emailerrorMessage:'',
      passworderrorMessage:'',
      getEmailCaptchaLoading: false,
      handleRegisterLoading: false,
      isPasswordVisible: false,
      isConfirmPasswordVisible: false,
    }
  },
  methods:{
    async getEmailCaptcha(){
        // 校验
        if (!this.registerUser.username) {
          this.$message.error('Please enter a username.');
          return;
        }
        // 校验用户名
        this.validateUsernameInput(this.registerUser.username);
        if (this.usernameerrorMessage) {
          this.$message.error(this.usernameerrorMessage );
          return;
        }
        if (!this.registerUser.email) {
          this.$message.error(' Please enter an email address.');
          return;
        }
        // 校验邮箱格式
        this.validateEmailInput(this.registerUser.email);
        if (this.emailerrorMessage) {
          this.$message.error(this.emailerrorMessage );
          return;
        }

        this.getEmailCaptchaLoading = true;
        // 调用Java后端接口
        var dataMap = {
          username: this.registerUser.username,
          email: this.registerUser.email
        }
        const response = await this.$axios.post('/captcha/send',dataMap );
        if (!response.flag) {
          this.$message.error(response.msg);
          this.getEmailCaptchaLoading = false;
        }else{
          this.$message.success("Send  successful! Please check your email for it.");
          this.getEmailCaptchaLoading = false;
        }
    },
    //校验用户名
    validateUsernameInput() {
      this.usernameerrorMessage = ''; // 清空错误信息
      const lengthRegex = /^.{5,30}$/;
      if (!lengthRegex.test(this.registerUser.username)) {
        this.usernameerrorMessage = 'The user name must be no less than 5 characters and no more than 30 characters.';
        return;
      }
      const numberRegex = /^\d/;
      if (numberRegex.test(this.registerUser.username)) {
        this.usernameerrorMessage = 'The user name must start with a letter.';
        return;
      }
      const allowedCharactersRegex = /^[A-Za-z0-9_.]+$/;
      if (!allowedCharactersRegex.test(this.registerUser.username)) {
        this.usernameerrorMessage = 'Spaces and special characters (e.g. , !@#$%^&) are not allowed in the user name.';
        return;
      }
    },
    // 邮箱正则表达式
    validateEmailInput() {
      this.emailerrorMessage = ''; // 清空错误信息
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(this.registerUser.email)) {
        this.emailerrorMessage = 'Please provide a valid email address.';
      }
    },
    //校验密码
    validatePasswordInput() {
      this.passworderrorMessage = ''; // 清空错误信息
      const lengthRegex = /^.{8,64}$/; // 校验 8 到 64 个字符
      if (!lengthRegex.test(this.registerUser.password)) {
        this.passworderrorMessage = 'The password must be at least 8 characters long and no more than 64 characters.';
        return;
      }
      const lowerCaseRegex = /[a-z]/; // 检查是否包含小写字母
      if (!lowerCaseRegex.test(this.registerUser.password)) {
        this.passworderrorMessage = 'The password must contain lowercase letters.';
        return;
      }
      const digitRegex = /\d/; // 检查是否包含数字
      if (!digitRegex.test(this.registerUser.password)) {
        this.passworderrorMessage = 'The password must contain numbers.';
        return;
      }
    },
    validateConfirmPassword() {
      this.errorMessage = ''; // 清空错误信息
      if (this.registerUser.password !== this.confirmPassword) {
        this.errorMessage = 'Passwords do not match!';
      }
    },
    async handleRegister() {
      this.errorMessage = ''; // 重置错误消息
      //校验用户名
      if (!this.registerUser.username) {
          this.errorMessage = 'Please enter a username.';
          this.$message.error(this.errorMessage );
          return;
      }
      // 校验用户名
      this.validateUsernameInput(this.registerUser.username);
      if (this.usernameerrorMessage) {
        this.$message.error(this.usernameerrorMessage );
        return;
      }
      //校验邮箱
      if (!this.registerUser.email) {
          this.errorMessage = 'Please enter an email address.';
          this.$message.error(this.errorMessage );
          return;
      }
      // 校验邮箱格式
      this.validateEmailInput(this.registerUser.email);
      if (this.emailerrorMessage) {
        this.$message.error(this.emailerrorMessage );
        return;
      }

      // 校验邮箱验证码
      if (!this.registerUser.captcha) {
        this.errorMessage = 'Please provide an email verification code.';
        this.$message.error(this.errorMessage );
        return;
      }

      // 校验密码格式
      this.validatePasswordInput(this.registerUser.password);
      if (this.passworderrorMessage) {
        this.$message.error(this.passworderrorMessage );
        return;
      }
      // 密码和确认密码匹配性校验
      if (this.registerUser.password !== this.confirmPassword) {
        this.errorMessage = '  The confirmation password does not match the password.';
        this.$message.error(this.errorMessage );
        return;
      }

      this.handleRegisterLoading = true;
      try {
        // 调用Java后端接口
        const response = await this.$axios.post('/user/register',this.registerUser );
        if (response.flag) {
          this.$message.success("Register successful");
          this.handleRegisterLoading = false;
          this.$router.push('/PublishLogin'); // 跳转到 PublishLogin
        }else{
          this.$message.error(response.msg);
          this.handleRegisterLoading = false;
        } 

      } catch (error) {
        this.$message.error(error);
      }
    },
    togglePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
    toggleConfirmPasswordVisibility() {
      this.isConfirmPasswordVisible = !this.isConfirmPasswordVisible;
    }
  }
}
</script>
<style lang="less" scoped>
.container_self{
    background-color: #ffffff;
    width: 100%;                    /* 宽度100% */
    height: 100vh;                  /* 高度100% */
    display: flex;                  /* 使用 Flexbox */
    justify-content: center;        /* 水平居中 */
    align-items: center;            /* 垂直居中 */
    position: relative;
}
.pub_header {
    position: absolute;             /* 使绝对定位的元素相对于该元素定位 */
    top: 50px;                     /* 从顶部下移100px */
    left: 0px;
    z-index: 3;                     
    display: flex;                  /* 使用 Flexbox 布局 */
    justify-content: space-between;  /* 在三部分之间添加空间 */
    align-items: center;            /* 垂直居中对齐 */
    width: 100%;                    /* 头部宽度100% */
    
}

.pub_logo {
    flex: 1;                        /* 左侧logo占据部分 */
    text-align: center;
    color: #111111;
    font-size: 38px;
    font-weight: 800;
}

.index_menu {
    flex: 2;                        /* 中间菜单占据部分 */
    text-align: center;
    ul {
        list-style: none;              /* 去掉列表样式 */
        display: flex;                 /* 使用 Flexbox 来水平排列菜单项 */
        justify-content: center;       /* 居中菜单项 */
        padding: 0;                    /* 去掉内边距 */
        margin: 0;                     /* 去掉外边距 */

        li {
            padding: 0 15px;                /* 菜单项之间间距 */
            display: inline-block;
            width: 15%;
            color: #ffffff;
            font-size: 22px;
            font-weight: bold;
        }
    }

    
}

.index_auth-buttons {
    flex: 1;                        /* 右侧登录和注册按钮占据部分 */
    display: flex;                 /* 水平排列按钮 */
    justify-content: center;     
    text-align: center;

    .el-button {
        margin-left: 20px;             /* 按钮之间间距 */
        height: 40px;
        line-height: 40px;
        background-color: #ffffff;
        color: #111111;
        font-size: 20px;
        font-weight: bold;
    }
}



@primary-color: #2563eb;
@gray-color: #6b7280;
@border-color: #e5e7eb;

.login-container {
  max-width: 400px;
  margin: 20px auto;
  padding: 20px;
  text-align: center;

  .title {
    font-size: 26px;
    margin-bottom: 20px;
    color: #1f2937;
  }

  .login-form {
    width: 280px;
    .form-group {
      margin-bottom: 20px;
      position: relative;
      .input-field {
        width: 100%;
        height: 40px;
        line-height: 40px;
        padding: 0px 10px;
        border: 1px solid @border-color;
        border-radius: 12px;
        font-size: 14px;
        &:focus {
          outline: none;
          border-color: @primary-color;
          box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
        }
      }
      .toggle-visibility {
        position: absolute;
        right: 10px; /* 按钮在输入框右边的距离 */
        top: 50%;
        transform: translateY(-50%);
        background: none;
        border: none;
        cursor: pointer;
        font-size: 18px; /* 调整图标图案大小 */
        color: #111111;
      }
      .toggle-visibility:hover{
        background: none;
      }
      .toggle-visibility img{
        width: 20px;
        height: 20px;
      }
      .code-field{
        width: 50%;
        height: 40px;
        line-height: 40px;
        float: left;
        padding: 0px 10px;
        border: 1px solid @border-color;
        border-radius: 12px;
        font-size: 14px;
        &:focus {
          outline: none;
          border-color: @primary-color;
          box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
        }
      }

      .btn-getcode {
        width: 40%;
        height: 40px;
        line-height: 40px;
        float: right;
        padding: 0px 5px;
        background-color: @primary-color;
        color: white;
        border: none;
        border-radius: 12px;
        font-size: 14px;
        cursor: pointer;

        &:hover {
          background-color: darken(@primary-color, 5%);
        }
      }

    }

    .btn-continue {
      width: 100%;
      height: 40px;
      line-height: 40px;
      padding: 0px 5px;
      background-color: @primary-color;
      color: white;
      border: none;
      border-radius: 12px;
      font-size: 18px;
      cursor: pointer;
      margin-top: 10px;

      &:hover {
        background-color: darken(@primary-color, 5%);
      }
    }
  }

  .signup-link {
    width: 100%;
    padding: 0px 25px;
    margin: 30px 0;
    color: @gray-color;
    font-size: 12px;

    .link {
      color: @primary-color;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .divider {
    display: flex;
    align-items: center;
    margin: 10px 0;

    &::before,
    &::after {
      content: '';
      flex: 1;
      border-bottom: 1px solid @border-color;
    }

    .divider-text {
      padding: 0 15px;
      color: @gray-color;
      font-size: 14px;
    }
  }

  .btn-google {
    width: 100%;
    height: 40px;
    line-height: 40px;
    background-color: white;
    border: 1px solid @border-color;
    border-radius: 12px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: @gray-color;
    .btn-google-img{
      width: 27px;
      height: 27px;
    }
    &:hover {
      background-color: #f9fafb;
    }
  }

  .footer-links {
    display: flex;
    justify-content: center;
    gap: 10px;
    color: @primary-color;

    .link {
     color: @primary-color;
      text-decoration: none;
      font-size: 12px;

      &:hover {
        text-decoration: underline;
      }
    }

    .separator {
      color: @border-color;
    }
  }
  .pass-title{
    clear: both;
    line-height: 30px;
    font-size: 12px;
  }
}

.error-message{
  color: red;
  font-size: 12px;
}

</style>