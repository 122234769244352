<template>
  <div class="container">
    <div class="publishWrapper">
      <div class="publishTop">
        <el-button class="pub-btn" @click="clickImageUpload">上传图片</el-button> 
        <span> &nbsp;&nbsp; or </span>
        <el-input class="pub-input" v-model="uploadedImageUrl" placeholder="Please Input ImageUrl" @change="chanageImageInput"></el-input>
        <el-button class="sub-btn" :loading="loading" @click="submitImage">Submit</el-button>
        <el-button class="pub-btn" @click="msgExpandVisible =true">信息增强</el-button>
      </div>
      
      <el-progress :percentage="progress" :stroke-width="15" v-if="ifshowprogress"></el-progress>

      <div class="preview-image-container" v-if="showUploadImage && isshowImageUpload == false">
        <el-image
          :src="previewImageUrl"
          class="preview-image"
          fit="contain"
          :preview-src-list="[previewImageUrl]"
        >
        </el-image>
      </div>
      <!-- 图片上传区域 -->
      <div class="upload-section" v-if="isshowImageUpload">
        <el-upload
          v-if="!uploadedImage"
          ref="imageUpload"
          class="upload-area"
          drag
          action=""
          :limit="1"
          :on-change="handleFileChange"
          :before-upload="beforeUpload"
          :auto-upload="false"
          :show-file-list="false"
          accept="image/jpeg,image/png"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">拖拽文件到这里，或点击上传</div>
        </el-upload>
        <el-image
          v-else
          :src="uploadedImage"
          class="preview-image"
          fit="contain"
          :preview-src-list="[uploadedImage]"
        ></el-image>
        <div class="action-buttons">
          <el-button type="primary" class="button" @click="clearImages">清除</el-button>
          <el-button type="primary" class="button" @click="showCutImage">编辑</el-button>
        </div>
      </div>
      <div class="publishMain">
        <div class="opreTop">
          <div class="platfromSelect">
            <!-- <el-select v-model="platform" placeholder="select platform" @change="chanagePlatfrom">
              <el-option
                v-for="item in platformList"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select> -->
            <span class="platfromSelect-title">平台:</span>
            <el-radio-group v-model="platform" size="small"  @change="chanagePlatfrom">
              <el-radio-button
                v-for="(item, index) in platformList"
                :key="index"
                :label="item"
              >
                {{ item }}
              </el-radio-button>
            </el-radio-group>
          </div>

          <el-radio-group v-model="lanageActive" size="small" :disabled ="isDisabled" @change="chanageRadia">
            <el-radio-button
              v-for="(language, index) in lanageList"
              :key="index"
              :label="index"
            >
              {{ language }}
            </el-radio-button>
          </el-radio-group>
          <el-button class="export-btn" :loading="exportLoading" @click="exportProductPubLish">Download</el-button>
        </div>
        <el-table
          :data="dataList"
          stripe
          style="width: 100%"
          @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="50"></el-table-column>
          <el-table-column
            prop="categoryPath"
            label="Category"
            width="200">
          </el-table-column>
          <el-table-column
            prop="title"
            label="Title"
            width="350">
          </el-table-column>
          <!-- <el-table-column
            prop="description"
            label="Description">
          </el-table-column> -->
          <el-table-column
            prop="bulletPoints"
            label="BulletPoints">
            <template slot-scope="scope">
              <ul class="bullet-list">
                <li v-for="(point, index) in scope.row.bulletPoints" :key="index">
                  {{ point }}
                </li>
              </ul>
            </template>
          </el-table-column>
          <el-table-column label="Image." width="180">
            <template slot-scope="scope">
              <!-- <el-image
                :src="scope.row.imageUrlList[0].jpegUrl"
                class="preview-image"
                fit="contain"
                :preview-src-list="[scope.row.imageUrlList[0].jpegUrl]"
              >
              </el-image> -->
              <el-image
                v-if="scope.row.imageUrlList && scope.row.imageUrlList.length > 0"
                :src="scope.row.imageUrlList[0].jpegUrl"
                class="preview-image"
                fit="contain"
                @click="showBigImg(scope.row.imageUrlList[0].jpegUrl)"
              >
              </el-image>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            label="Action"
            width="80">
            <template slot-scope="scope">
              &nbsp;
              <el-badge :value="scope.row.imageUrlList.length" class="item" v-if="scope.row.imageUrlList.length > 0">
                <el-button @click="openDetailsDialog(scope.row)" type="text" size="mini">Details</el-button>
              </el-badge>
              <el-button v-else @click="openDetailsDialog(scope.row)" type="text" size="mini">Details</el-button>
              &nbsp;
              <el-button @click="generateMoreImg(scope.row)" type="text" size="mini">Image</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      
    </div>

    <!-- 列表点击大图弹框 -->
    <el-dialog :visible.sync="isshowBigImg">
      <img :src="bigImageUrl" class="bigImage"  />
    </el-dialog>

    <!-- 详情弹框 -->
    <el-dialog
      :visible.sync="dialogVisible"
      title="Details"
      width="500px"
      @close="closeDialog"
      top="2vh"
    >
      <div class="edtail-dialog-content">
        <div class="edtail-dialog-row">
          <label class="label">Category:</label>
          <div class="value">{{ selectedItem.categoryPath }}</div>
        </div>
        <div class="edtail-dialog-row">
          <label class="label">Title:</label>
          <div class="value">{{ selectedItem.title }}</div>
        </div>
        <div class="edtail-dialog-row">
          <label class="label">Description:</label>
          <div class="value">{{ selectedItem.description }}</div>
        </div>
        <div class="edtail-dialog-row">
          <label class="label">Bullet Points:</label>
          <div class="value">
            <ul class="bullet-list">
              <li v-for="(point, index) in selectedItem.bulletPoints" :key="index" >
                {{ point }}
              </li>
            </ul>
          </div>
        </div>
        <div class="edtail-dialog-row">
          <label class="label">Scenario:</label>
          <div class="value">{{ selectedItem.scenario }}</div>
        </div>
        <!-- 添加schema显示 -->
        <div class="edtail-dialog-row" v-show="selectedItem.schemaValue" v-for="(item, index) in selectedItem.schemaValue" :key="index">
            <label class="label">{{item.title}}:</label>
            <div class="value">{{ item.value}}</div>
        </div>
        
        <!-- 添加图片显示 -->
        <div class="edtail-dialog-row">
          <label class="label">Image:</label>
          <el-col v-for="(url, index) in selectedItem.imageUrlList" :key="index" :span="6">
            <el-image
              :src="url.jpegUrl"
              class="dialog-image"
              fit="contain"
              @click="handlePreview(url)"
            ></el-image>
          </el-col>
        </div>
        <!-- 添加excel数据 -->

        <el-table :data="[selectedItem.excelDataList]" stripe style="width: 100%">
          <el-table-column
            v-for="(item, index) in selectedItem.excelDataList" 
            :key="index" 
            :label="item.title" 
            :width="item.columnWidth">
            <template>
              {{ item.cellValue }} <!-- 直接显示每列的 cellValue -->
            </template>
          </el-table-column>
        </el-table>

      </div>
    </el-dialog>
    <el-dialog :visible.sync="detailImageVisible">
      <img :src="detailCurrentImage" class="detail-bigImage"  />
    </el-dialog>

    <!-- 信息增强 -->
    <el-dialog title="信息增强"  :visible.sync="msgExpandVisible">
      <div  class="dialogFieldLine">
        <table>
            <tbody>
              <tr>
                 <td colspan="3">
                  <label class="dialog_fr10">13位sku:</label>
                  <el-input v-model="skuNumber" class="large-el-input"></el-input>
                </td>
              </tr>
              <tr>
                 <td colspan="3">
                  <label class="dialog_fr10">平台商品详情页链接(如amazon、walmart):</label>
                  <el-input v-model="productDetailUrl" class="large-el-input"></el-input>
                </td>
              </tr>
            </tbody>
        </table>
      </div>
      <br>
      <span slot="footer" class="dialog-footer">
        <el-button @click="msgExpandVisible = false">取 消</el-button>
        <el-button type="primary" @click="msgExpandVisible = false" >确 定</el-button>
      </span>
    </el-dialog>
    

    <!-- 图片剪裁 -->
    <!-- <el-dialog title="裁剪图片" :visible.sync="cutDialogVisible" top="2vh">
      <div>
        <div class="cropperWrapper" >
            <vueCropper
                  ref="cropper"
                  :img="cropperOption.img"
                  :outputSize="cropperOption.outputSize"
                  :outputType="cropperOption.outputType"
                  :info="cropperOption.info"
                  :canScale="cropperOption.canScale"
                  :autoCrop="cropperOption.autoCrop"
                  :autoCropWidth="cropperOption.autoCropWidth"
                  :autoCropHeight="cropperOption.autoCropHeight"
                  :fixed="cropperOption.fixed"
                  :fixedNumber="cropperOption.fixedNumber"
                  :full="cropperOption.full"
                  :fixedBox="cropperOption.fixedBox"
                  :canMove="cropperOption.canMove"
                  :canMoveBox="cropperOption.canMoveBox"
                  :original="cropperOption.original"
                  :centerBox="cropperOption.centerBox"
                  :height="cropperOption.height"
                  :infoTrue="cropperOption.infoTrue"
                  :maxImgSize="cropperOption.maxImgSize"
                  :enlarge="cropperOption.enlarge"
                  :mode="cropperOption.mode"
                  @realTime="realTime"
            ></vueCropper>
          </div>
          <div>
            <el-button size="mini" type="danger" plain icon="el-icon-zoom-in" @click="changeScale(1)">放大</el-button>
            <el-button size="mini" type="danger" plain icon="el-icon-zoom-out" @click="changeScale(-1)">缩小</el-button>
            <el-button size="mini" type="danger" plain @click="rotateLeft">↺ 左旋转</el-button>
            <el-button size="mini" type="danger" plain @click="rotateRight">↻ 右旋转</el-button>
          </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cutDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmCrop" :loading="cropperLoading">确 定</el-button>
      </span>
    </el-dialog> -->

    
    <!-- 页面加载效果 -->
    <div class="loadingMask" v-if="pageloading">
    </div>
  </div>
</template>

<script>

import * as DownloadUtils from '@/utils/DownloadUtils.js';
export default {
  data() {
    return {
      loading: false,
      uploadedFile: null,
      uploadedImage: null, // 用户上传的图片
      uploadedImageUrl: null,//输入框图片地址
      sceneData: [], // 九宫格数据
      dialogVisible: false, // 弹窗显示控制
      selectedItem: {}, // 存储选中的九宫格项的详细信息
      taskId: null,
      taskInterval: null,
      progress: 0,
      ifshowprogress: false,
      showUploadImage: false,
      previewImageUrl: null,
      isshowBigImg: false,
      bigImageUrl:"",
      dataList: [],
      multipleSelection:[],
      isshowImageUpload: false,
      cutDialogVisible: false,
      exportLoading: false,
      croppedImage:null,
      pageloading: false,
      lanageList:["English","Spanish","French","German","Italian"],
      lanageActive:0,
      isDisabled: true,
      egDatalist:[],
      allData:{},
      platformList:["Amazon","Amazon Vendor","Walmart"],
      platform:"Amazon",
      detailImageVisible:false,
      cropperLoading: false,
      detailCurrentImage:null,
      msgExpandVisible: false,
      skuNumber: null,
      productDetailUrl: null,
      excelDataListColumn:[],

      fileinfo:{},
      previews:{},
      cropperOption:{
          img: '',             //裁剪图片的地址
          outputSize: 1,       //裁剪生成图片的质量(可选0.1 - 1)
          outputType: 'png',  //裁剪生成图片的格式（jpeg || png || webp）
          info: true,          //图片大小信息
          canScale: true,      //图片是否允许滚轮缩放
          autoCrop: true,      //是否默认生成截图框
          autoCropWidth: 300,  //默认生成截图框宽度
          autoCropHeight: 300, //默认生成截图框高度
          fixed: false,         //是否开启截图框宽高固定比例
          fixedNumber: [1.53, 1], //截图框的宽高比例
          full: false,         //false按原比例裁切图片，不失真
          fixedBox: false,      //固定截图框大小，不允许改变
          canMove: false,      //上传图片是否可以移动
          canMoveBox: true,    //截图框能否拖动
          original: false,     //上传图片按照原始比例渲染
          centerBox: false,    //截图框是否被限制在图片里面
          height: true,        //是否按照设备的dpr 输出等比例图片
          infoTrue: false,     //true为展示真实输出图片宽高，false展示看到的截图框宽高
          maxImgSize: 3000,    //限制图片最大宽度和高度
          enlarge: 1,          //图片根据截图框输出比例倍数
          mode: '600px 600px'  //图片默认渲染方式
      },
      excelDataList: [
        { "title": "Product Type", "cellValue": "Type A" },  // 示例数据
        { "title": "Seller SKU", "cellValue": "SKU123" },
        { "title": "Brand Name", "cellValue": "Brand A" },
        { "title": "Update Delete", "cellValue": "Update" },
        { "title": "closure_type", "cellValue": "Type 1" },
        { "title": "Product Exemption Reason", "cellValue": "Reason A" },
        { "title": "Brand Name", "cellValue": "Brand A" },
        { "title": "Update Delete", "cellValue": "Update" },
        { "title": "closure_type", "cellValue": "Type 1" },
      ]

    };
  },
  computed: {
    imageStyle() {
      return {
        transform: `translate(${this.imagePosition.x}px, ${this.imagePosition.y}px) scale(${this.scale})`,
        cursor: this.isDragging ? 'grabbing' : 'grab',
      }
    },
    gridLines() {
      const lines = [];
      const canvasWidth = 800; // 画布宽度
      const canvasHeight = 600; // 画布高度
      const step = 50; // 坐标线间隔
      
      // 垂直线
      for (let x = step; x < canvasWidth; x += step) {
        lines.push({
          id: `v-${x}`,
          style: {
            position: 'absolute',
            left: `${x}px`,
            top: '0',
            height: '100%',
            borderLeft: '1px dashed rgba(0, 0, 0, 0.5)',
            pointerEvents: 'none'
          }
        });
      }

      // 水平线
      for (let y = step; y < canvasHeight; y += step) {
        lines.push({
          id: `h-${y}`,
          style: {
            position: 'absolute',
            top: `${y}px`,
            left: '0',
            width: '100%',
            borderTop: '1px dashed rgba(0, 0, 0, 0.5)',
            pointerEvents: 'none'
          }
        });
      }

      return lines;
    }
  },
  destroyed() {
    this.cancelTaskInterval();
  },
  methods: {
    // 打开弹窗并显示选中的项的详情
    openDetailsDialog(item) {
      this.selectedItem = item;
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    chanagePlatfrom(){
      this.allData = {};
      this.egDatalist = [];
      this.dataList = [];
      this.cancelTaskInterval();
    },
    chanageImageInput(){
      this.uploadedImage = null;
      this.isshowImageUpload = false;
    },
    async submitImage() {
      if(!this.platform){
        this.$message.error("请选择平台");
        return;
      }
      //如果有图片上传，那先处理图片
      if (this.uploadedImage) {
        if (!this.uploadedImageUrl) {
          try {
            this.loading = true;
            const uploadResponse = await this.uploadImage();

            if (!uploadResponse.flag) {
              this.$message.error(uploadResponse.msg);
              return;
            }

            this.uploadedImageUrl = uploadResponse.url;
            this.$message.success("图片上传成功");
          } catch (error) {
            console.log('uploadImage error:', error);
            this.$message.error("图片上传失败，请重试");
            return;
          } finally {
            this.loading = false;
          }
        }
      }

      if (!this.uploadedImageUrl) {
        this.$message.error("请先上传图片或输入图片Url");
        return;
      }

      try {
        this.loading = true;
        const submitTaskResponse = await this.submitTask(this.uploadedImageUrl);

        if (!submitTaskResponse.flag) {
          this.$message.error(submitTaskResponse.msg);
          return;
        }

        this.taskId = submitTaskResponse.taskId;
        this.$message.success("任务提交成功");
      } catch (error) {
        console.log('submitTask error:', error);
        this.$message.error("任务提交失败，请重试");
        return;
      } finally {
        this.loading = false;
      }

      this.dataList = [];
      this.progress = 0;
      this.ifshowprogress = true;
      this.showUploadImage = true;
      this.previewImageUrl = this.uploadedImageUrl;
      this.loading = true;
      this.isDisabled = true;
      this.pageloading = true;

      this.taskInterval = setInterval(async () => {
        this.loading = true;
        const queryTaskResponse = await this.queryTask(this.taskId);

        if (!queryTaskResponse.flag) {
          this.cancelTaskInterval();
          this.$message.error(queryTaskResponse.msg);
          this.loading = false;
          this.pageloading = false;
          return;
        }

        this.egDatalist = queryTaskResponse.resultList;
        this.dataList = queryTaskResponse.resultList;
        this.progress = this.dataList.length*10;
        
        if (queryTaskResponse.isCompleted) {
          this.cancelTaskInterval();
          this.$message.success("生成成功");
        }
      }, 5000);
    },
    cancelTaskInterval() {
      if (this.taskInterval) {
        clearInterval(this.taskInterval);
        this.taskId = null;
        this.taskInterval = null;
        this.loading = false;
        this.progress = 0;
        this.ifshowprogress = false;
        this.isDisabled = false;
        this.pageloading = false;
      }
    },
    async submitTask(imageUrl, type) {
      return this.$axios.get('/productPublish/xaz/submitTask?env=test2&platform='+this.platform, {
        params: {
          url: imageUrl,
          skuNumber: this.skuNumber || null
        }
      });
    },
    async queryTask(taskId) {
      return this.$axios.get('/productPublish/xaz/queryTask', {
        params: {
          taskId: taskId,
        }
      });
    },
    exportProductPubLish(){
      if(this.multipleSelection == null || this.multipleSelection.length < 1){
        this.$message.error("请选择需要下载的数据");
        return;
      }
      this.exportLoading = true;
      this.$axios.post('/productPublish/xaz/downloadZip?skuNumber='+this.skuNumber, this.multipleSelection,{responseType:'blob'}).then(async result => {
        if (result.response.headers.flag ==='false' ) {
          const msg = await result.text();
          this.$message.error(msg);
          return;
        }
        DownloadUtils.download(result,'publish.zip');
      })
      .finally(() =>{
        this.exportLoading = false;
      });
    },
    showBigImg(url){
      this.bigImageUrl = url;
      this.isshowBigImg = true;
    },
    handleSelectionChange(obj){
        this.multipleSelection = obj;
    },
    clickImageUpload(){
      this.isshowImageUpload = true;
      this.showUploadImage = false;
      this.previewImageUrl = null;
      this.uploadedImageUrl = null;
    },
    beforeUpload(file) {
      const isImage = file.type === "image/png" || file.type === "image/jpeg";
      const isLt3M = file.size / 1024 / 1024 < 3; // 限制文件大小小于3MB
      if (!isImage) {
        this.$message.error("仅支持 JPG/PNG 格式的图片");
        return false;
      }
      if (!isLt3M) {
        this.$message.error("图片大小不能超过 3MB");
        return false;
      }
      return true;
    },
    handleFileChange(file, fileList) {
      if (fileList.length > 0) {
        if (!this.beforeUpload(file.raw)) {
          this.$refs.imageUpload.clearFiles();
          return;
        }
        this.uploadedFile = file;
        this.uploadedImage = URL.createObjectURL(file.raw);
      }
    },
    clearImages() {
      this.uploadedFile = null;
      this.uploadedImage = null;
      this.uploadedImageUrl = null;
      this.sceneData = [];
      this.cancelTaskInterval();
      this.isshowImageUpload = false;
      this.$message.success("图片已清除");
    },
    async uploadImage() {
      var formData = new FormData();
      formData.append("file", this.uploadedFile.raw);
      return this.$axios.post('/productScenario/xaz/uploadFile', formData);
    },
    chanageRadia(){
      if(this.lanageActive == 0){
        this.dataList = this.egDatalist;
        return;
      }
      this.translateToLanguage();
    },
    translateToLanguage() {
      var lan = this.lanageList[this.lanageActive];
      this.pageloading = true;
      this.isDisabled = true;
      if(this.allData[lan]){
        this.dataList = this.allData[lan];
        return;
      }
      this.$axios.post('/productPublish/xaz/translateToLanguage?language='+ lan, this.egDatalist).then(async result => {
        if (!result.flag) {
          this.$message.error(result.msg);
          this.pageloading = false;
          this.isDisabled = false;
          return;
        }
        this.allData[lan] = result.langResultList;
        this.dataList = result.langResultList;
        this.pageloading = false;
        this.isDisabled = false;
      })

    },
    generateMoreImg(row){
      this.pageloading = true;
      var dataMap={
        "imageUrl": this.uploadedImageUrl,
        "prompt": row.scenario
      }
      this.$axios.post('/productPublish/xaz/generateImage', dataMap).then(async result => {
        if (!result.flag) {
          this.$message.error(result.msg);
          this.pageloading = false;
          this.isDisabled = false;
          this.pageloading = false;
          return;
        }
        for(var i = 0; i < result.imageUrlList.length;i++){
          row.imageUrlList.push(result.imageUrlList[i]);
        }
        this.pageloading = false;
        this.$message.success("更多图片生成成功，请点击Details 查看");
        
      })
    },
    handlePreview(url) {
      this.detailCurrentImage = url.jpegUrl;
      this.detailImageVisible = true;
    },

    //裁剪
    showCutImage(){
      this.cropperOption.img = this.uploadedImage;
      this.cutDialogVisible = true;
    },
    //实时预览函数
    realTime (data) {
      this.previews = data;
    },
    //图片缩放
    changeScale (num) {
      num = num || 1
      this.$refs.cropper.changeScale(num)
    },
    //向左旋转
    rotateLeft () {
      this.$refs.cropper.rotateLeft()
    },
    //向右旋转
    rotateRight () {
      this.$refs.cropper.rotateRight()
    },
    //确认裁剪图片
    confirmCrop(){
        //获取截图的blob数据
        // this.$refs.cropper.getCropBlob(async (data) => {
        //   //拿到的是blob类型的数据，需要其他形式需要转换
        //   console.log("cropblob",data);
        //   //this.uploadedImage = URL.createObjectURL(cropFile.raw);
        // })

        // 获取裁剪后的图像 DATA URL
        // const croppedCanvas = this.$refs.cropper.getCroppedCanvas();
        // this.croppedImage = croppedCanvas.toDataURL('image/png'); // 或使用 'image/jpeg' 生成 JPEG 格式
        // this.uploadedImage = croppedImage;

        // 这里可以上传 croppedImage 到你的服务器
        // this.uploadCroppedImage(this.croppedImage);



        this.$refs.cropper.getCropData((data) => {
          let fileName = "bc" + this.fileinfo.uid + ".jpg";
          this.formImage = data;
          var blob = this.dataURLtoBlob(this.formImage);
          var file = this.blobToFile(blob, fileName);
          console.log("ctopperfile",file);
          
          this.uploadedImage = URL.createObjectURL(file);
          
          //上传图片
          this.uploadedFile = file;


          // let formData = new FormData();
          // formData.append("file", file);
          // formData.append("fileName", fileName);
          // formData.append("accessToken", "hggydg728778");
          // 文件对象
          // this.pageMethods.uploadApi.baseMethod(formData).then((data) => {
          //   if (data) {
          //     //filePre 域名  fileUrl 路径
          //     this.editoImg = true
          //     this.formImage = `${data.filePre}${data.fileUrl}`;
          //     this.form.backgroundImage = data.fileUrl;
          //     console.log("formSubmit",this.formImage,this.form.backgroundImage,this.editoImg)
          //   }
          // });
        });

        console.log(this.uploadedImage);
        this.cutDialogVisible = false; // 关闭弹框
    },

  },
};
</script>

<style scoped lang="less">

.view-details {
  color: #409eff;
  cursor: pointer;
  font-size: 14px;
  transition: color 0.3s ease;
}

.view-details:hover {
  color: #66b1ff;
}

.dialog-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dialog-row {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.label {
  display: inline-block;
  width: 220px; /* 固定标签宽度 */
  text-align: right; /* 右对齐标签部分 */
  margin-right: 10px;
  color: #000; /* 设置文字颜色为黑色 */
  font-weight: bold; /* 如果需要加粗文字 */
}

.value {
  flex: 1;
  text-align: left;
  word-wrap: break-word; /* 允许单词被换行 */
  word-break: break-word; /* 支持浏览器对于长单词的自动换行 */
  white-space: pre-wrap; /* 保留空白和换行符，允许自动换行 */
}

.bullet-list {
  padding-left: 10px; /* 给列表项增加左边距 */
  margin: 0; /* 去除默认的外边距 */
}

.bullet-list li {
  text-align: left;
  list-style: disc; /* 使用实心点符号 */
  // margin-bottom: 5px; /* 每个列表项之间的间距 */
}

.bullet-list li::marker {
  //color: #409eff; /* 单独控制实心点的颜色 */
  font-size: 16px; /* 可以调整实心点的大小 */
}

.edtail-dialog-content{
  width: 99%;
  height: 850px;
  overflow: auto;
}
.edtail-dialog-row{
  margin-bottom: 8px;
  clear: both;
  .label {
    display: inline-block;
    float: left;
    width: 15%; /* 固定标签宽度 */
    text-align: right; /* 右对齐标签部分 */
    margin-right: 10px;
    color: #000; /* 设置文字颜色为黑色 */
    font-weight: bold; /* 如果需要加粗文字 */
  }

  .value {
    width: 83%;
    display: inline-block;
    float: left;
    text-align: left;
    word-wrap: break-word; /* 允许单词被换行 */
    word-break: break-word; /* 支持浏览器对于长单词的自动换行 */
    white-space: pre-wrap; /* 保留空白和换行符，允许自动换行 */
  }
}


.dialog-image {
  width: 250px;
  height: 250px;
  object-fit: contain;
  margin-top: 10px;
}
.bigImage{
  display: block; 
  width: auto;  /* 使宽度自动 */
  height: auto; /* 使高度自动 */
  max-width: 100%; /* 防止图片超出容器 */
  margin: 0px auto;
}
.detail-bigImage{
  display: block; 
  width: auto;  /* 使宽度自动 */
  height: auto; /* 使高度自动 */
  max-width: 100%; /* 防止图片超出容器 */
  margin: 0px auto;
}
.preview-image-container {
  margin-top: 15px;
  display: flex;           /* 使用 flex 布局 */
  justify-content: center; /* 水平居中 */
  align-items: center;     /* 垂直居中 */
  width: 100%;             /* 父容器宽度 */
}

.preview-image {
  width: 100px;
  height: 100px;
  object-fit: contain;
  cursor: pointer;
}

.opreTop{
  height: 50px;
  line-height: 32px;
  .platfromSelect{
    display: inline-block;
    float: left;
    margin-right: 50px;
    .platfromSelect-title{
      display: inline-block;
      float: left;
    }
    /deep/.el-elect{
      width: 150px;
    }
  }
  .el-radio-group{
    float: left;
  }
  .export-btn{
    display: inline-block;
    float: right;

  }
}

.container {
  max-width: 1500px;
  margin: auto;
  padding: 20px;
  text-align: center;

  .publishTop{
    display: flex;
    margin-bottom: 15px;
    height: 55px;
    line-height: 55px;
    .pub-input{
      flex: 1;
      display: inline-block;
      margin-right: 10px;
      margin-left: 10px;
    }
    .pub-btn{
      display: inline-block;
      width: 120px;
      margin-top:10px;
    }
    .sub-btn{
      display: inline-block;
      width: 120px;
      height: 40px;
      line-height: 40px;
      margin-top:5px;
      background-color: #67c23a;
    }
    .sub-btn:hover{
      background: #85ce61 !important;
      color: #ffffff !important;
    }
  }
  .publishMain{
    widows: 100%;
    min-height: 650px;
    padding: 10px;
    margin-top: 15px;
    border: 1px solid #e3e4e5;
    border-radius: 10px;
    .first-li{
      height: 40px;
    }

  }

  .bigImsge{
    width: 800px;
    margin: 0px auto;

    img{
      object-fit: contain;
      width: 800px;
      height: 800px;
    }
  }
}

.table-li{
  display: list-item;
  text-align: left;
  border-bottom: none;
  list-style: disc; 
  padding-left: 5px; 
}
/deep/ .el-table {
  td,
  th {
    img{
      width: 100px;
      height: 100px;
    }
  }
}
.tableimg{
    width: 150px !important;
    height: 150px !important;
}


.cropperWrapper{
  width:80%;
  height: 720px;
  margin:0px auto;
}
/deep/ .el-image-viewer__canvas{
  img{
    width: 100%;
    height: 100%;
    max-height: none;
    max-width: none;
  }

}
</style>
