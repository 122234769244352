export default {
    /**
     * 设我们当前所在的域名为 zhuanlan.zhihu.com， 想要获取二级域名， 得到 zhihu.com，<br/>
     * 如果我们所在的域名已经是 zhihu.com， 获取二级域名， 还是得到 zhihu.com，<br/>
     * 如果当前是 IP 地址，则返回后三个数字组成的IP地址（特殊逻辑，不具有通用性）
     */
    getLevel2domain: function () {
        try {
            let subdomain = ''
            const {domain} = document
            const domainList = domain.split('.')

            const ipAddressReg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/

            /* 若当前域名为 IP 地址、localhost，采用一种特殊的处理
            （只用于我们的测试环境，此部分代码不具有通用性）
             */
            if (ipAddressReg.test(domain) || domain === 'localhost') {
                let level3domain = document.domain;
                let pattern = /^.*?\b\.\b/;
                return level3domain.replace(pattern, '');
            }

            const urlItems = []
            urlItems.unshift(domainList.pop())

            while (urlItems.length < 2) {
                urlItems.unshift(domainList.pop())
                subdomain = urlItems.join('.')
            }

            return subdomain || document.domain
        } catch (e) {
            return document.domain
        }
    }

}