import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,  // 用于存储用户信息
  },
  getters: {
    user: (state) => state.user,  // 获取用户信息
  },
  mutations: {
    setUser(state, user) {
      state.user = user;  // 修改用户信息
    },
    clearUser(state) {
      state.user = null;  // 清除用户信息
    },
  },
  actions: {
    
  },
  modules: {
  }
})
